import React, { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Empty, Row, Space, Spin } from 'antd/es'

import { forEach, groupBy } from 'lodash-es'

import { Category, Subcategory } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Card from '@/atoms/Card'
import InputField from '@/atoms/InputField'
import Label from '@/atoms/Label'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import useLogCreationCategories from '@/hooks/useLogCreationCategories'
import { truncate } from '@/utils/string'

import classes from './LogCategoryModal.module.less'

interface Props {
  open: boolean
  onChooseCategory: (id: number) => void
  onClose: () => void
}

const LogCategoryModal = ({ open, onChooseCategory, onClose }: Props): ReactElement => {
  const { t } = useTranslation('common')
  const firstRender = useRef(true)
  const [internalCategories, setInternalCategories] = useState<
    Partial<Category & { subcategories: Subcategory[] }>[]
  >([])
  const [filterKey, setFilterKey] = useState<'all' | string>('all')
  const [searchTerm, setSearchTerm] = useState('')
  const { logCreationCategories: categories, loading } = useLogCreationCategories()

  /**
   * The search handler used to filter the categories
   * @param inputVal the string to search for
   */
  const onSearchHandler = (inputVal: string): void => {
    let filteredCategories
    if (inputVal.length && categories) {
      // Pattern explanation: https://regex101.com/r/oT8jM4/1
      const pattern = new RegExp(`S*${inputVal}S*`, 'gi')
      filteredCategories = categories.filter(
        (c) =>
          c.name?.match(pattern) ||
          (c.subcategories as Subcategory[])?.some((s) => s.name?.match(pattern)),
      )
      setInternalCategories(filteredCategories ?? categories)
      setSearchTerm(inputVal)
    }
  }

  /**
   * An array containing the categories filtered by the scope
   */
  const groupedCategories = useMemo(() => {
    const sortedCategories =
      categories?.sort((a, b) => a.name?.localeCompare(b.name ?? '') || 0) || []
    const newCategories = groupBy(sortedCategories, 'scope.categoryNumber')
    delete newCategories.undefined
    const groupedCategories = { '1': {}, '2': {}, '3': {} }
    forEach(newCategories, (_value, key) => {
      groupedCategories[key as keyof typeof groupedCategories] = groupBy(
        newCategories[key],
        'scope.title',
      )
    })
    return groupedCategories
  }, [categories])

  /**
   * Choose the category type in the sidebar
   * @param {("all"|string)} typeId the id of the chosen type
   * @returns
   */
  const chooseType = (scopeCategory: string | 'all'): void => {
    setFilterKey(scopeCategory)
    if (scopeCategory !== 'all' && categories) {
      const filteredTypes = categories.filter(
        (category) => `${category.scope?.categoryNumber}` === scopeCategory,
      )
      return setInternalCategories(filteredTypes)
    }
    return setInternalCategories(categories || [])
  }

  /**
   * Set the internal categories to
   * the categories from the props, on the first render
   */
  useEffect(() => {
    if (internalCategories.length <= 0 && firstRender && categories) {
      setInternalCategories(categories)
      firstRender.current = false
    }
  }, [categories])

  /**
   * Map the cards with the category info
   * to an array, if their are categories to show or show an empty image
   * @returns {ReactNode} An array of cards or the empty placeholder
   */
  const renderCategoryCards = (): ReactNode => {
    if (internalCategories?.length > 0) {
      return internalCategories.map(({ id, image, name, subtitle, subcategories, scope }) => (
        <Col key={id} span={12}>
          <Card
            title={name}
            icon={image}
            shadow="sm"
            hoverable
            onClick={() => onChooseCategory(id ? id : 0)}
            data-cy={name}
          >
            <Space direction="vertical" size="middle">
              <Tag type="default">{`${scope?.categoryNumber}${
                scope?.subcategoryNumber ? '.' + scope?.subcategoryNumber : ''
              } - ${scope?.title} `}</Tag>
              <Text size="xl">{subtitle}</Text>
              {subcategories && (
                <div className={classes.subcategories}>
                  <Label>{t('log.category-modal.all')}</Label>
                  <div>
                    {subcategories?.map(({ id, name }) => (
                      <Tooltip title={name as string} key={id}>
                        <Tag>{truncate(name as string, 25)}</Tag>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              )}
            </Space>
          </Card>
        </Col>
      ))
    }
    return (
      <Col span={24}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Col>
    )
  }

  useEffect(() => {
    if (open === false && categories) {
      setInternalCategories(categories)
      setSearchTerm('')
    }
  }, [open, categories])

  useEffect(() => {
    if (searchTerm === '' && categories) {
      setInternalCategories(categories)
    }
  }, [searchTerm, categories])

  return (
    <Modal
      visible={open}
      title={t('log.category-modal.choose')}
      width="70%"
      noBodyPadding
      closable={false}
      onCancel={onClose}
      hideFooter
      data-cy="log-category-modal"
    >
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={6}>
                <aside className={classes.sidebar}>
                  <span
                    className={`${classes.sidebarItem} ${
                      filterKey === 'all' ? classes.active : ''
                    }`}
                    onClick={() => chooseType('all')}
                  >
                    {t('log.category-modal.viewAll')}
                  </span>

                  {Object.entries(groupedCategories).map(([key]) => (
                    <div className={classes.sidebarSection} key={key}>
                      <span
                        onClick={() => chooseType(key)}
                        className={`${classes.sidebarItem} ${
                          filterKey === key ? classes.active : ''
                        }`}
                      >
                        {key !== 'undefined' ? `${t('log.scope')} ${key}` : t('log.other')}
                      </span>
                    </div>
                  ))}
                </aside>
              </Col>
              <Col span={18}>
                <div className={classes.listWrapper}>
                  <Row>
                    <Col span={24}>
                      <div className={classes.listHeader}>
                        <span data-cy="log-categories">
                          {t('log.categories')} ({internalCategories.length})
                        </span>
                        <div>
                          <InputField
                            type="search"
                            placeholder={t('log.search')}
                            onSearch={onSearchHandler}
                            allowClear
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            data-cy="log-categories-search"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className={classes.list}>
                        <Row data-cy="category-cards" gutter={[12, 12]} wrap>
                          {renderCategoryCards()}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export default LogCategoryModal
