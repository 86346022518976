import React, { HTMLProps, ReactElement, ReactNode, useMemo } from 'react'

import classes from './Label.module.less'

export interface LabelProps extends Omit<HTMLProps<HTMLLabelElement>, 'size'> {
  /**
   * The content of the label
   */
  children: ReactNode
  /**
   * The size of the label
   * @defaultValue 'sm'
   */
  size?: 'xs' | 'sm' | 'md'
  /**
   * The text color of the label
   * @defaultValue 'gray'
   */
  color?: 'gray' | 'black'
  /**
   * Custom classnames to add to the label
   */
  className?: string
  /**
   * The title of the label, shown on hover
   */
  title?: string
}

/**
 * A basic text label
 */
const Label = ({
  size = 'sm',
  color = 'gray',
  children,
  className,
  ...rest
}: LabelProps): ReactElement => {
  const classNames = useMemo(
    () => [className, classes.label, classes[size], classes[color]].join(' '),
    [size],
  )
  return (
    <label className={classNames} {...rest}>
      {children}
    </label>
  )
}

export default Label
