import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Menu } from 'antd'

import { ProductLogEntry } from '@cozero/models'

import DotsMenu from '../molecules/DotsMenu'
import { convertTonnesToGrams, formatNumber } from '../utils/number'
import { truncate } from '../utils/string'

import { GenerateColumn } from './useGenerateData'

type ShowFactorsAction = (entry: Partial<ProductLogEntry>) => void
const useProductLogEntriesTableColumns = ({
  onShowFactors,
}: {
  onShowFactors?: ShowFactorsAction
}): GenerateColumn<Partial<ProductLogEntry>>[] => {
  const { t } = useTranslation('common')

  const columnsData: GenerateColumn<Partial<ProductLogEntry>>[] = useMemo(() => {
    const optionsColumn = onShowFactors
      ? [
          {
            title: t('product.lifecycle-steps.quantity.options'),
            dataIndex: 'options',
            key: 'options',
            width: 100,
            fixed: 'right',
            render(_text: string, record: Partial<ProductLogEntry>) {
              return (
                <DotsMenu
                  dotType="vertical"
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => onShowFactors(record)}>
                        {t('factors.title')}
                      </Menu.Item>
                    </Menu>
                  }
                />
              )
            },
          },
        ]
      : []

    return [
      {
        title: t('name'),
        dataIndex: 'title',
        key: 'title',
        width: 300,
        render: (value) => truncate(value as string, 15),
      },
      {
        title: t('cozero.category.title'),
        dataIndex: 'subcategory.category.name',
        key: 'category',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>
                {
                  (record.subcategory as unknown as { category: { name: string } })?.category
                    ?.name as string
                }
              </p>
            </>
          )
        },
      },
      {
        title: t('cozero.subcategory.title'),
        dataIndex: 'subcategory.name',
        key: 'subcategory',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.subcategory?.name as string}</p>
            </>
          )
        },
      },
      {
        title: t('calculation-methods.single'),
        dataIndex: 'calculationMethod.name',
        key: 'calculationMethod',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.calculationMethod?.name as string}</p>
            </>
          )
        },
      },
      {
        title: t('activity-data-sources.single'),
        key: 'activityDataSource',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.activityDataSource?.name as string}</p>
            </>
          )
        },
      },
      {
        title: t('territories.single'),
        key: 'territory',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.territory?.name as string}</p>
            </>
          )
        },
      },

      {
        title: t('log.bulk.columns.dataTypeOne'),
        key: 'dataTypeOne',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(0)?.unit?.type as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.valueOne'),
        key: 'valueOne',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(0)?.value}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.unitOne'),
        key: 'unitOne',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(0)?.unit?.name as string}</p>
            </>
          )
        },
      },
      //---2
      {
        title: t('log.bulk.columns.dataTypeTwo'),
        key: 'dataTypeTwo',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(1)?.unit?.type as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.valueTwo'),
        key: 'valueTwo',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(1)?.value}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.unitTwo'),
        key: 'unitTwo',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(1)?.unit?.name as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.dataTypeThree'),
        key: 'dataTypeThree',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(2)?.unit?.type as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.valueThree'),
        key: 'valueThree',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(2)?.value}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.unitThree'),
        key: 'unitThree',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(2)?.unit?.name as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.dataTypeFour'),
        key: 'dataTypeFour',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(3)?.unit?.type as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.valueFour'),
        key: 'valueFour',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(3)?.value}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.unitFour'),
        key: 'unitFour',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(3)?.unit?.name as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.dataTypeFive'),
        key: 'dataTypeFive',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(3)?.unit?.type as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.valueFive'),
        key: 'valueFive',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(3)?.value}</p>
            </>
          )
        },
      },
      {
        title: t('log.bulk.columns.unitFive'),
        key: 'unitFive',
        width: 350,
        render(_text: string, record: ProductLogEntry) {
          return (
            <>
              <p>{record.inputs?.at(3)?.unit?.name as string}</p>
            </>
          )
        },
      },
      {
        title: t('log.totalCarbon') + ' (gCO₂e)',
        key: 'totalCarbon',
        width: 150,
        fixed: 'right',
        sorter: (a, b) =>
          (a?.productEmissions?.reduce((acc, val) => acc + val.value, 0) as number) -
          (b?.productEmissions?.reduce((acc, val) => acc + val.value, 0) as number),
        render(_text: string, record: ProductLogEntry) {
          const emissions = record.productEmissions.reduce((acc, val) => acc + val.value, 0)
          return (
            <>
              <p>{formatNumber(convertTonnesToGrams(emissions))}</p>
            </>
          )
        },
      },
      ...optionsColumn,
    ] as GenerateColumn<Partial<ProductLogEntry>>[]
  }, [])

  return columnsData
}

export default useProductLogEntriesTableColumns
