import React, { ReactElement, ReactNode, useMemo } from 'react'

import { Col, Tooltip as ITooltip, Row } from 'antd/es'
import { TooltipPlacement, TooltipPropsWithTitle } from 'antd/es/tooltip'

import { CINDER_BLUE_90 } from '@/styles/variables'

import classes from './Tooltip.module.less'

export interface TooltipProps extends TooltipPropsWithTitle {
  /**
   * Content of the label
   */
  title: string | ReactNode
  /**
   * Content of the subtitle
   */
  subTitle?: string | ReactNode
  /**
   * Display an arrow pointing to the target
   */
  showArrow?: boolean
  /**
   * Placement of the arrow
   */
  placement?: TooltipPlacement
  /**
   * Target element
   */
  children: ReactElement | string | React.ReactNode
  /**
   * Color of the Tooltip '
   */
  color?: string
}

/**
 * Tooltip is a component that displays a small popup when hovering over a target.
 */
const Tooltip = ({
  title,
  children,
  showArrow = false,
  placement = 'bottom',
  color = CINDER_BLUE_90,
  subTitle,
  ...rest
}: TooltipProps): ReactElement => {
  const tooltipTitle = useMemo(
    () => (
      <Row>
        <Col span={24} className={classes.tooltipTitle}>
          {title}
        </Col>
        {subTitle && (
          <Col span={24} className={classes.tooltipContent}>
            {subTitle}
          </Col>
        )}
      </Row>
    ),
    [title, subTitle],
  )

  return (
    <ITooltip
      title={tooltipTitle}
      placement={placement}
      showArrow={showArrow}
      color={color}
      {...rest}
    >
      <span>{children}</span>
    </ITooltip>
  )
}

export default Tooltip
