import React, { forwardRef, useImperativeHandle } from 'react'

import { useForm } from 'antd/lib/form/Form'

import { ProductTag } from '@cozero/models'

import { UploadProductConfigurationParams } from '@/hooks/useProducts'
import useTerritories from '@/hooks/useTerritories'

import { useProductOutletContext } from '../index'

import UpsertProduct from './UpsertProduct'

export interface ProductConfigurationValues {
  id?: number
  photo: string | UploadProductConfigurationParams | null
  name: string
  productType: number | string
  functionalUnit: number
  mass: number
  massUnit: number
  productId?: string
  tags?: string[] | ProductTag[]
  responsibleId?: number
  territory?: number
  businessUnitId?: number
}

const ProductConfiguration = (): JSX.Element => {
  const [form] = useForm()

  const {
    product,
    childRef,
    STEPS,
    updateProduct,
    createProductConfiguration,
    onHasFinished,
    supplierProduct,
  } = useProductOutletContext()

  const { territories } = useTerritories()

  useImperativeHandle(childRef, () => ({
    onClearData: () => {
      form.resetFields()
    },
    onNext: async () => {
      try {
        const valid = await form.validateFields()
        if (valid) {
          form.submit()
        }
      } catch (error) {
        return
      }
    },
    onReturn: () => undefined,
  }))

  return (
    <UpsertProduct
      STEPS={STEPS}
      territories={territories || []}
      product={product}
      supplierProduct={supplierProduct}
      form={form}
      onSubmitNewProductData={async (data) => {
        if (product?.id) {
          await updateProduct(product?.id?.toString(), data)
        } else {
          await createProductConfiguration(data)
        }
        onHasFinished()
      }}
    />
  )
}

export default React.memo(forwardRef(ProductConfiguration))
