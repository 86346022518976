import React, { ReactElement, useLayoutEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { kebabCase } from 'lodash-es'

import { MenuItem } from '@/organisms/NavSidebar/MENU_ITEMS'

import NavMenuItem from '@/molecules/NavMenuItem'

import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, selectUser } from '@/redux/auth'

import classes from './NavSubMenu.module.less'

type Props = {
  base: string
  title: string
  menuOpen: boolean
  items: MenuItem[]
  onOpenHandler: (base: string | null) => void
  icon?: ReactElement
}
const NavSubMenu = ({ base, title, items, icon, menuOpen, onOpenHandler }: Props): ReactElement => {
  const { pathname } = useLocation()
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const user = useAppSelector(selectUser)

  const onSubmenuClick = (): void => {
    if (!menuOpen) {
      return onOpenHandler(base)
    }
    return onOpenHandler(null)
  }

  const menuItems = useMemo(
    () =>
      items.map((item: MenuItem) => {
        if (
          featuresAllowed?.includes(item.feature) &&
          !item.excludeRoles?.includes(user?.role?.type || '')
        ) {
          return (
            <NavMenuItem
              key={item.route ?? item.key}
              title={item.title}
              route={item.route}
              icon={item.icon}
              data-cy={kebabCase(item.title)}
              isChild
            />
          )
        }
        return null
      }),

    [items],
  )

  useLayoutEffect(() => {
    const hasActiveChild = items.some((child) => pathname === child.route)
    if (hasActiveChild) {
      onOpenHandler(base)
    }
  }, [pathname])

  return (
    <div
      role="button"
      className={`${classes.navSubMenu} ${menuOpen ? classes.open : ''}`}
      onClick={onSubmenuClick}
    >
      <NavMenuItem
        title={title}
        icon={icon}
        data-cy={kebabCase(title)}
        active={menuOpen}
        isParent
      />
      {items && (
        <ul className={`${classes.itemList} ${menuOpen ? classes.open : ''}`} role="menu">
          {menuItems}
        </ul>
      )}
    </div>
  )
}

export default NavSubMenu
