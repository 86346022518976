import React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Menu, Space, Typography } from 'antd/es'

import moment from 'moment'

import { Quantity, QuantityTag } from '@cozero/models'

import Tag from '../atoms/Tag'
import DotsMenu from '../molecules/DotsMenu'
import { formatNumber } from '../utils/number'

import { GenerateColumn } from './useGenerateData'

type DeleteAction = (id: number) => Promise<void>
type EditAction = (quantity: Quantity) => Promise<void>
const useQuantitiesTableColumns = (
  totalEmissions: number,
  deleteQuantity?: DeleteAction,
  editQuantity?: EditAction,
): GenerateColumn<Partial<Quantity>>[] => {
  const { t } = useTranslation('common')

  const columnsData: GenerateColumn<Partial<Quantity>>[] = useMemo(() => {
    const optionsColumn =
      deleteQuantity && editQuantity
        ? [
            {
              title: t('product.lifecycle-steps.quantity.options'),
              dataIndex: 'options',
              key: 'options',
              width: 300,
              render(_text: string, record: Partial<Quantity>) {
                return (
                  <DotsMenu
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => deleteQuantity(record?.id as number)}>
                          {t('actions.delete')}
                        </Menu.Item>
                        <Menu.Item onClick={() => editQuantity(record as Quantity)}>
                          {t('actions.edit.title')}
                        </Menu.Item>
                      </Menu>
                    }
                  />
                )
              },
            },
          ]
        : []
    return [
      {
        title: t('product.lifecycle-steps.quantity.number-of-units'),
        dataIndex: 'volume',
        key: 'volume',
        width: 300,
        sorter: (a, b) => (a.volume as number) - (b.volume as number),
      },
      {
        title: t('product.lifecycle-steps.quantity.date-interval'),
        dataIndex: 'dateInterval',
        key: 'dateInterval',
        width: 350,
        render(_: string, record) {
          return (
            <Space>
              <Tag>{moment(record.startDate).utc().format('LL')}</Tag>
              <Typography>{t('to')}</Typography>
              <Tag>{moment(record.endDate).utc().format('LL')}</Tag>
            </Space>
          )
        },
      },
      {
        title: t('product.lifecycle-steps.quantity.quantity-tags'),
        dataIndex: 'quantityTags',
        key: 'quantityTags',
        width: 200,
        render(_: string, record) {
          return (
            <Space>
              {record?.tags?.slice(0, 3)?.map((x: QuantityTag) => (
                <Tag key={x.id}>{x.name}</Tag>
              ))}

              {record?.tags?.length && record?.tags?.length > 3 ? (
                <Tag>+{record?.tags?.length - 3}</Tag>
              ) : (
                ''
              )}
            </Space>
          )
        },
      },
      {
        title: t('product.lifecycle-steps.quantity.footprint') + '  (tCO₂e)',
        dataIndex: 'footprint',
        key: 'volume',
        width: 300,
        render(_text: string, record) {
          return (
            <>
              <p>{formatNumber((record.volume ?? 0) * totalEmissions)}</p>
            </>
          )
        },
      },
      ...optionsColumn,
    ] as GenerateColumn<Partial<Quantity>>[]
  }, [])

  return columnsData
}

export default useQuantitiesTableColumns
