import React, { MouseEvent, ReactElement } from 'react'
import { HiDotsHorizontal, HiDotsVertical } from 'react-icons/hi'

import { DropDownProps, Dropdown } from 'antd/es'

import classes from './DotsMenu.module.less'

interface Props extends DropDownProps {
  iconSize?: number
  dotType?: 'vertical' | 'horizontal'
  onClick?: (e: MouseEvent) => void
  iconColor?: string
}

const DotsMenu = ({ dotType, iconSize = 18, onClick, iconColor, ...rest }: Props): ReactElement => {
  const onMenuClick = (e: MouseEvent<SVGElement>): void => {
    e.stopPropagation()
    if (onClick) {
      return onClick(e)
    }
  }
  return (
    <Dropdown {...rest} trigger={['click', 'hover']}>
      <div className={classes.wrapper}>
        {dotType === 'vertical' ? (
          <HiDotsVertical
            color={iconColor}
            size={iconSize}
            className={classes.icon}
            onClick={onMenuClick}
          />
        ) : (
          <HiDotsHorizontal
            color={iconColor}
            size={iconSize}
            className={classes.icon}
            onClick={onMenuClick}
          />
        )}
      </div>
    </Dropdown>
  )
}

export { DotsMenuItem } from './DotsMenuItem'

export default DotsMenu
