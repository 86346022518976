import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Text from '@/atoms/Text'

const TargetList = (): ReactElement => {
  const { t } = useTranslation('common')
  return (
    <div>
      <ul>
        <li>
          <Text size="xl">{t('act.targets.onboarding.commit.1')}</Text>
        </li>
        <li>
          <Text size="xl">{t('act.targets.onboarding.commit.2')}</Text>
        </li>
        <li>
          <Text size="xl">{t('act.targets.onboarding.commit.3')}</Text>
        </li>
      </ul>
      <Text size="xl">{t('act.targets.onboarding.result')}</Text>
    </div>
  )
}

export default TargetList
