import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Space, Spin } from 'antd/es'

import Modal from '@/molecules/Modal'

import Alert from '@/atoms/Alert'
import InputField from '@/atoms/InputField'

import { useAppSelector } from '@/redux'
import { selectUserOrganization } from '@/redux/auth'
import { useUpdateOrganizationMutation } from '@/redux/organizations'

import classes from './classes.module.less'

interface Props {
  handleCancel: () => void
  productId: string
  goToCheckout: (productId: string) => void
}

export function VATModal({ productId, handleCancel, goToCheckout }: Props): JSX.Element {
  const { t } = useTranslation('common')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [updateOrganization] = useUpdateOrganizationMutation()
  const organization = useAppSelector(selectUserOrganization)
  const [form] = Form.useForm()

  async function onSave(): Promise<void> {
    const values = form.getFieldsValue()
    try {
      if (!values.vatId || !values.address || !values.legalEntity || !values.name) {
        setError(t('organization.missing-fields'))
        return
      }
      setLoading(true)
      await updateOrganization(values)
      goToCheckout(productId)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      centered
      visible={!!productId}
      title={t('organization.tax')}
      onCancel={handleCancel}
      className={classes.modal}
      width="50%"
      hideFooter={false}
      okText={t('actions.save')}
      onOk={onSave}
    >
      <Spin spinning={loading} className={classes.modalContainer}>
        {error && <Alert message={error} type="error" />}
        <Space className={classes.sections} direction="horizontal" size="large">
          <Form layout="vertical" initialValues={organization} autoComplete="off" form={form}>
            <Form.Item label={t('organization.name')} name="name" rules={[{ required: true }]}>
              <InputField />
            </Form.Item>
            <Form.Item
              label={t('organization.address')}
              name="address"
              rules={[{ required: true }]}
            >
              <InputField />
            </Form.Item>
            <Form.Item
              label={t('organization.legal-entity')}
              name="legalEntity"
              rules={[{ required: true }]}
            >
              <InputField />
            </Form.Item>
            <Form.Item label={t('organization.vat-id')} name="vatId" rules={[{ required: true }]}>
              <InputField />
            </Form.Item>
          </Form>
        </Space>
      </Spin>
    </Modal>
  )
}
