import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Space } from 'antd'

import Card from '../../../../atoms/Card'
import Tag from '../../../../atoms/Tag'
import Text from '../../../../atoms/Text'

import classes from './ActionCard.module.less'

const ActionCard = ({
  children,
  disabled,
  title,
  text,
  stepNumber,
  status,
}: {
  title?: string
  text?: string
  status?: string
  stepNumber?: number
  disabled?: boolean
  children: JSX.Element
}): JSX.Element => {
  const { t } = useTranslation('common')

  return (
    <div>
      {disabled && <div className={classes.disableLayer}></div>}
      <Card
        bgColor="default"
        shadow="none"
        className={status === t('onboarding.suppliers.todo') ? classes.activeCard : ''}
      >
        <Row>
          <Col span={1}>
            <div className={classes.stepPill}>
              <Text color="blue" fontWeight="semibold" size="lg" mode="text">
                {stepNumber}
              </Text>
            </div>
          </Col>
          <Col span={21}>
            <Text fontWeight="semibold" size="sm" mode="title">
              {title}
            </Text>
          </Col>
          <Col span={2}>
            <Tag
              className={classes.stateTag}
              style={
                status === t('onboarding.suppliers.waiting') ||
                status === t('onboarding.suppliers.in-progress')
                  ? { color: '#3E4051' }
                  : {}
              }
            >
              {status}
            </Tag>
          </Col>
        </Row>
        <Row>
          <Col offset={1}>
            <div className={classes.stepHeader}>
              <Text color="secondary" mode="paragraph" size="xl">
                {text}
              </Text>
            </div>
          </Col>
        </Row>
        {children}
      </Card>
    </div>
  )
}

export default ActionCard
