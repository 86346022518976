import { CreateSupplierUserDto } from '@cozero/dtos'
import { User } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import i18n from '@/i18n'

import apiSlice from '../api'
import { TAG_ORGANIZATIONS } from '../organizations'
import { TAG_ORGANIZATION_USERS } from '../organizations/tags'
import { UPDATE_USER } from '../userProfile'

import {
  CREATE_SUPPLIER_USER,
  CREATE_USER,
  DELETE_USER,
  EXPORT_USERS,
  GET_USER,
  INVITE_USER,
  TAG_USERS,
} from './constants'

const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_USER]: builder.query<User, number>({
      query: (userId) => ({
        url: centralApiGatewayClient.users.GET_ONE.replace(':id', `${userId}`),
        method: 'GET',
      }),
      providesTags: (res) => [{ type: TAG_USERS, id: res?.id }],
    }),
    [CREATE_USER]: builder.mutation<User, Partial<User>>({
      query: (data) => ({
        url: centralApiGatewayClient.users.CREATE_AND_INVITE,
        method: 'POST',
        data,
      }),
      transformErrorResponse: (res) => {
        switch (res?.status) {
          case 409:
            return {
              ...res,
              message: i18n.t(`general-errors.USER_EXISTS_ERROR`, {
                defaultValue: 'User already exits',
              }),
            }
          case 402:
            return {
              ...res,
              message: i18n.t(`general-errors.USER_LIMIT_EXCEEDED`, {
                defaultValue: 'You have reached your user limit',
              }),
            }
          default:
            return res
        }
      },
      invalidatesTags: () => [{ type: TAG_USERS }],
    }),
    [CREATE_SUPPLIER_USER]: builder.mutation<string, CreateSupplierUserDto>({
      query: (data) => ({
        url: centralApiGatewayClient.users.CREATE_SUPPLIER,
        method: 'POST',
        data,
      }),
    }),
    [INVITE_USER]: builder.mutation<User, number>({
      query: (id) => ({
        url: centralApiGatewayClient.users.INVITE.replace(':id', `${id}`),
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: TAG_USERS }],
    }),
    [UPDATE_USER]: builder.mutation<User, Partial<User>>({
      query: (data) => ({
        url: centralApiGatewayClient.users.UPDATE_ONE.replace(':id', `${data.id}`),
        method: 'PUT',
        data,
      }),
      invalidatesTags: (res) => [{ type: TAG_USERS, id: res?.id }],
    }),
    [DELETE_USER]: builder.mutation<User, number>({
      query: (userId) => ({
        url: centralApiGatewayClient.users.DELETE_ONE.replace(':id', `${userId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (res) => [
        { type: TAG_USERS, id: res?.id },
        { type: TAG_ORGANIZATIONS, id: res?.id },
        { type: TAG_ORGANIZATION_USERS, id: res?.id },
      ],
    }),
    [EXPORT_USERS]: builder.query<User[], void>({
      query: () => ({
        url: centralApiGatewayClient.users.EXPORT,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useCreateUserMutation,
  useCreateSupplierUserMutation,
  useInviteUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useExportUsersQuery,
  useLazyExportUsersQuery,
} = usersApiSlice

export default usersApiSlice
