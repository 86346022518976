import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import SuspenseSpinner from '@/templates/SuspenseSpinner'

import Home from '@/pages/Home'

import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'
import { config } from '@/utils/config'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'

const Reports = lazy(() => import('@/pages/Reports'))
const ViewReports = lazy(() => import('@/pages/Reports/ViewBoard'))
const NotFoundPage = lazy(() => import('@/pages/404'))

const { routes } = config

const Wrapper = ({
  feature = 'log',
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <PrivateRoute>
    <LogProvider>
      <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
    </LogProvider>
  </PrivateRoute>
)

const getOverviewRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: config.routes.overview.base,
    props: { preventNavigation: true },
    children: [
      {
        path: config.routes.overview.dashboard,
        element: (
          <Wrapper feature="reports">
            <FiltersProvider saveQueryString={true}>
              <Home />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: 'Home',
      },
      {
        path: routes.overview.reports,
        element: (
          <Wrapper feature="reports">
            <Suspense fallback={<SuspenseSpinner />}>
              <Reports />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('layout.reports'),
      },
      {
        path: routes.overview.viewReport,
        element: (
          <Wrapper feature="reports">
            <FiltersProvider>
              <Suspense fallback={<SuspenseSpinner />}>
                <ViewReports />
              </Suspense>
            </FiltersProvider>
          </Wrapper>
        ),
      },

      {
        path: `${routes.overview.base}/*`,
        element: (
          <Wrapper>
            <Suspense fallback={<SuspenseSpinner />}>
              <NotFoundPage />
            </Suspense>
          </Wrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getOverviewRoutes
