/**
 * Do not edit directly
 * Generated on Tue, 09 May 2023 10:15:52 GMT
 */

export const BLUE_800 = "#0041C5";
export const CINDER_BLUE_10 = "#F5F7FF";
export const CINDER_BLUE_20 = "#EBEFFA";
export const CINDER_BLUE_30 = "#DFE4F3";
export const CINDER_BLUE_40 = "#B0B1C8";
export const CINDER_BLUE_50 = "#8587A1";
export const CINDER_BLUE_60 = "#535675";
export const CINDER_BLUE_70 = "#292c49";
export const CINDER_BLUE_75 = "#2F80ED";
export const CINDER_BLUE_80 = "#272844";
export const CINDER_BLUE_90 = "#1b1c34";
export const CINDER_BLUE_100 = "#0B0C1F";
export const CINDER_BLUE_BASE = "#10111C";
export const COZERO_BLUE_5 = "#F2F6FF";
export const COZERO_BLUE_10 = "#EDF3FF";
export const COZERO_BLUE_20 = "#DCE8FF";
export const COZERO_BLUE_30 = "#CADEFF";
export const COZERO_BLUE_40 = "#AAC9FF";
export const COZERO_BLUE_50 = "#8BB4FF";
export const COZERO_BLUE_60 = "#6C9EFF";
export const COZERO_BLUE_65 = "#538DFF";
export const COZERO_BLUE_70 = "#4D88FF";
export const COZERO_BLUE_80 = "#2871FF";
export const COZERO_BLUE_90 = "#1259E3";
export const COZERO_BLUE_100 = "#1259E3";
export const COZERO_BLUE_BASE = "#1259E3";
export const COZERO_GREEN_5 = "#F5F7F5";
export const COZERO_GREEN_10 = "#ECF5E9";
export const COZERO_GREEN_20 = "#ECF5E9";
export const COZERO_GREEN_30 = "#BAE9A5";
export const COZERO_GREEN_40 = "#93D97E";
export const COZERO_GREEN_50 = "#8fd872";
export const COZERO_GREEN_60 = "#5CC63C";
export const COZERO_GREEN_70 = "#5CC63C";
export const COZERO_GREEN_80 = "#2AA803";
export const COZERO_GREEN_100 = "#2E6B20";
export const COZERO_GREEN_BASE = "#2E6B20";
export const COZERO_ORANGE_5 = "#FFF9F2";
export const COZERO_ORANGE_10 = "#FCF0E2";
export const COZERO_ORANGE_15 = "#FAE3C6";
export const COZERO_ORANGE_20 = "#FFDFB3";
export const COZERO_ORANGE_30 = "#FDD196";
export const COZERO_ORANGE_35 = "#F6C06A";
export const COZERO_ORANGE_40 = "#FAC37A";
export const COZERO_ORANGE_50 = "#F7B45E";
export const COZERO_ORANGE_60 = "#F4A541";
export const COZERO_ORANGE_70 = "#F0961F";
export const COZERO_ORANGE_80 = "#E38509";
export const COZERO_ORANGE_100 = "#B06A0E";
export const COZERO_ORANGE_BASE = "#B06A0E";
export const CYAN_20 = "#D4F2F8";
export const CYAN_BASE = "#06B6D4";
export const GRAY_50 = "#F6F6F6";
export const GRAY_100 = "#E8E8E8";
export const GRAY_200 = "#DDDDDD";
export const GRAY_300 = "#D9D9D9";
export const GRAY_400 = "#A4A4A4";
export const GRAY_500 = "#777777";
export const GRAY_800 = "#3F3F3F";
export const GRAY_900 = "#212121";
export const GRAY_BASE = "#212121";
export const GRAY_DARK = "#3F3F3F";
export const GRAY_MEDIUM = "#777777";
export const GRAY_LIGHT = "#A4A4A4";
export const GRAY_LIGHTER = "#DDDDDD";
export const GRAY_LIGHTEST = "#E8E8E8";
export const GRAY_NEUTRAL = "#D9D9D9";
export const GRAY_ULTRALIGHT = "#F6F6F6";
export const PINK_20 = "#FBE9F9";
export const PINK_BASE = "#E77EDC";
export const PURPLE_20 = "#EBE7FC";
export const PURPLE_BASE = "#8D6FFF";
export const RED_5 = "#FFEFEF";
export const RED_20 = "#FEE2E2";
export const RED_30 = "#FED7D7";
export const RED_40 = "#FECACA";
export const RED_50 = "#FDC4C4";
export const RED_60 = "#FCA5A5";
export const RED_70 = "#FA6B6B";
export const RED_80 = "#EF4444";
export const RED_100 = "#991B1B";
export const RED_BASE = "#991B1B";
export const TEXT_MAIN = "#212121";
export const TEXT_DARK = "#3F3F3F";
export const TEXT_GRAY_LIGHT = "#A4A4A4";
export const TEXT_GRAY_DARK = "#777777";
export const TEXT_WHITE = "#FFF";
export const WHITE_NEUTRAL = "#FFF";
export const WHITE_ALABASTER = "#FCFDFF";
export const WHITE_LILAC = "#FBFCFF";
export const YELLOW_10 = "#FDF7E6";
export const YELLOW_20 = "#FBF1D6";
export const YELLOW_40 = "#F8E7BB";
export const YELLOW_60 = "#F3D78E";
export const YELLOW_80 = "#F0CD72";
export const YELLOW_100 = "#ECC04F";
export const YELLOW_BASE = "#ECC04F";
export const FONT_BRAND_PRIMARY = "Messina Regular";
export const FONT_BRAND_MONO = "Messina Mono";
export const FONT_WEIGHT_BOLD = "700";
export const FONT_WEIGHT_SEMIBOLD = "600";
export const FONT_WEIGHT_MEDIUM = "500";
export const FONT_WEIGHT_REGULAR = "400";
export const FONT_WEIGHT_NORMAL = "400";
export const FONT_WEIGHT_LIGHT = "300";
export const BORDER_RADIUS_XL = "16px";
export const BORDER_RADIUS_LG = "12px";
export const BORDER_RADIUS_BASE = "8px";
export const BORDER_RADIUS_SM = "6px";
export const BORDER_RADIUS_XS = "4px";
export const BORDER_RADIUS_2XS = "2px";
export const BORDER_RADIUS_FULL = "99999px";
export const SHADOW_BASE = "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)";
export const SHADOW_SM = "0 1px 2px 0 rgba(0, 0, 0, 0.05)";
export const SHADOW_MD = "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)";
export const SHADOW_LG = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
export const SHADOW_XL = "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)";
export const SHADOW_2XL = "0 25px 50px -12px rgba(0, 0, 0, 0.25)";
export const SIZE_BREAKPOINT_DESKTOP_MIN = "960px";
export const SIZE_BREAKPOINT_DESKTOP_MAX = "1199px";
export const SIZE_BREAKPOINT_LAPTOP_MIN = "960px";
export const SIZE_BREAKPOINT_LAPTOP_MAX = "1250px";
export const SIZE_BREAKPOINT_TABLET_MIN = "720px";
export const SIZE_BREAKPOINT_TABLET_MAX = "959px";
export const SIZE_BREAKPOINT_MOBILE_MIN = "0px";
export const SIZE_BREAKPOINT_MOBILE_MAX = "719px";
export const SIZE_FONT_2XS = "0.625";
export const SIZE_FONT_XS = "0.75";
export const SIZE_FONT_SM = "0.875";
export const SIZE_FONT_BASE = "1";
export const SIZE_FONT_MD = "1";
export const SIZE_FONT_LG = "1.1";
export const SIZE_FONT_XL = "1.25";
export const SIZE_FONT_2XL = "1.5";
export const SIZE_FONT_4XL = "2.5";
export const SIZE_FONT_5XL = "3";
export const SIZE_FONT_TITLE_XS = "1.25";
export const SIZE_FONT_TITLE_SM = "1.5";
export const SIZE_FONT_TITLE_MD = "1.875";
export const SIZE_FONT_TITLE_LG = "2.25";
export const SIZE_FONT_TITLE_XL = "2.75";
export const SIZE_FONT_TEXT_XL = "1";
export const SIZE_FONT_TEXT_LG = "0.875";
export const SIZE_FONT_TEXT_MD = "0.813";
export const SIZE_FONT_TEXT_SM = "0.75";
export const SIZE_FONT_TEXT_XS = "0.625";
export const SIZE_LINE_HEIGHT_4XS = "0.75";
export const SIZE_LINE_HEIGHT_3XS = "0.875";
export const SIZE_LINE_HEIGHT_2XS = "1";
export const SIZE_LINE_HEIGHT_XS = "1.25";
export const SIZE_LINE_HEIGHT_SM = "1.5";
export const SIZE_LINE_HEIGHT_BASE = "1.75";
export const SIZE_LINE_HEIGHT_MD = "2";
export const SIZE_LINE_HEIGHT_LG = "2.5";
export const SIZE_LINE_HEIGHT_XL = "3";
export const SIZE_LINE_HEIGHT_TITLE_XS = "1.75";
export const SIZE_LINE_HEIGHT_TITLE_SM = "2";
export const SIZE_LINE_HEIGHT_TITLE_MD = "2.25";
export const SIZE_LINE_HEIGHT_TITLE_LG = "2.5";
export const SIZE_LINE_HEIGHT_TITLE_XL = "3";
export const SIZE_LINE_HEIGHT_TEXT_XL = "1.75";
export const SIZE_LINE_HEIGHT_TEXT_LG = "1.5";
export const SIZE_LINE_HEIGHT_TEXT_MD = "1.25";
export const SIZE_LINE_HEIGHT_TEXT_SM = "1";
export const SIZE_LINE_HEIGHT_TEXT_XS = "0.75";
export const MODAL_SIZE_SM = "600px";
export const MODAL_SIZE_MD = "850px";
export const SPACING_BASE = "8px";
export const SPACING_3XS = "1px";
export const SPACING_2XS = "2px";
export const SPACING_XS = "4px";
export const SPACING_SM = "16px";
export const SPACING_MD = "24px";
export const SPACING_LG = "32px";
export const SPACING_XL = "40px";
export const SPACING_2XL = "48px";
export const SPACING_3XL = "64px";
export const SPACING_4XL = "88px";