import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Col, Divider, Row, Spin, Switch, message } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import { ApiOutlined, SnippetsOutlined } from '@ant-design/icons'
import omit from 'lodash/omit'

import { ApiIntegration } from '@cozero/models'

import { ManualImportModal } from '@/organisms/ManualImportModal'

import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useAdminContext } from '@/contexts/admin'
import { selectSelectedBusinessUnitKey } from '@/redux/businessUnits'
import { useGetLocationsQuery } from '@/redux/locations'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'
import { WHITE_NEUTRAL } from '@/styles/variables'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'

function ConfigureIntegration(): JSX.Element {
  const businessUnitKey = useSelector(selectSelectedBusinessUnitKey)
  const [form] = Form.useForm()
  const { t } = useTranslation('common')
  const {
    getApiIntegrations,
    apiIntegrations,
    loading,
    configureApiIntegration,
    error,
    checkConnection,
  } = useAdminContext()
  const { data: users = [] } = useGetOrganizationUsersQuery(
    { businessUnit: businessUnitKey ?? '' },
    { skip: !businessUnitKey },
  )
  const { data: locations } = useGetLocationsQuery(
    { businessUnitKey: businessUnitKey ?? '' },
    { skip: !businessUnitKey },
  )
  const { id } = useParams()
  const [selectedIntegration, setSelectedIntegration] = useState<ApiIntegration>()
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false)

  useEffect(() => {
    async function fetchData(): Promise<void> {
      await getApiIntegrations()
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (apiIntegrations && id) {
      setSelectedIntegration(
        apiIntegrations.find((integration) => integration.id === parseInt(id, 10)),
      )
    }
  }, [apiIntegrations])

  const submitForm = async (fields: Store): Promise<void> => {
    const configuration = omit(fields, ['active', 'interval', 'userId', 'locationId'])
    if (selectedIntegration) {
      await configureApiIntegration(
        selectedIntegration?.id,
        fields.active,
        fields.interval,
        parseInt(fields.locationId),
        parseInt(fields.userId),
        configuration,
      )
      if (error) {
        message.error(t('settings.integrations.configure.error'))
      } else {
        message.success(t('settings.integrations.configure.success'))
        await getApiIntegrations()
      }
    }
  }

  const check = async (): Promise<void> => {
    if (selectedIntegration?.id) {
      const success = await checkConnection(selectedIntegration.id, form.getFieldsValue())
      if (success) {
        message.success(t('settings.integrations.transactions.success'))
      } else {
        message.error(t('settings.integrations.transactions.error'))
      }
    }
  }

  useEffect(() => {
    if (selectedIntegration && selectedIntegration?.configurations[0]) {
      const fieldValues = JSON.parse(
        selectedIntegration?.configurations[0]?.configuration?.toString() ?? '{}',
      )
      form.setFieldsValue({
        active: selectedIntegration?.configurations[0]?.active,
        interval: selectedIntegration?.configurations[0]?.interval,
        locationId: selectedIntegration.configurations[0]?.locationId,
        userId: selectedIntegration.configurations[0]?.userId,
        ...fieldValues,
      })
    }
  }, [selectedIntegration, locations?.length, users?.length])

  return (
    <Row justify="center" className={classes.tabSection}>
      <ManualImportModal
        selectedIntegration={selectedIntegration}
        visible={modalIsVisible}
        handleCancel={() => setModalIsVisible(false)}
      />
      <Col span={20}>
        <Row justify={'center'} gutter={16}>
          <Col span={4} style={{ marginTop: 5 }}>
            <div
              style={{
                background: selectedIntegration?.logoBg ?? WHITE_NEUTRAL,
                borderRadius: 5,
                width: 150,
                margin: '0 auto',
                display: 'block',
              }}
            >
              <img src={selectedIntegration?.logoUrl ?? ''} width={100} style={{ margin: 25 }} />
            </div>
          </Col>
          <Col span={19} style={{ marginLeft: 25 }}>
            <Col span={22}>
              <Title size="sm">{`${selectedIntegration?.name} ${t(
                'settings.integrations.single',
              )}`}</Title>
            </Col>
            <Col span={22}>
              <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                <>{selectedIntegration?.description}</>
              </Text>
            </Col>
          </Col>
        </Row>
      </Col>
      <Divider />
      <Spin spinning={loading}>
        <Col span={24} style={{ marginTop: 20 }}>
          <Form form={form} layout={'vertical'} onFinish={submitForm} category={'api-integrations'}>
            <Row justify={'center'} gutter={16}>
              <Col span={10}>
                <Title as="h5">{t('settings.integrations.headlines.general')}</Title>
                <Form.Item
                  key="active"
                  name={['active']}
                  label={t('settings.integrations.fields.active.title')}
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Switch defaultChecked />
                </Form.Item>
                <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                  {t('settings.integrations.fields.active.description')}
                </Text>
                <Form.Item
                  key="interval"
                  name={['interval']}
                  label={t('settings.integrations.fields.interval.title')}
                  initialValue={'end-of-month'}
                >
                  <Select
                    style={{ width: '50%' }}
                    defaultValue={'end-of-month'}
                    options={[
                      {
                        label: t('settings.integrations.fields.interval.options.end-of-day'),
                        value: 'end-of-day',
                      },
                      {
                        label: t('settings.integrations.fields.interval.options.end-of-week'),
                        value: 'end-of-week',
                      },
                      {
                        label: t('settings.integrations.fields.interval.options.end-of-month'),
                        value: 'end-of-month',
                      },
                    ]}
                  />
                </Form.Item>
                <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                  {t('settings.integrations.fields.interval.description')}
                </Text>
                <Form.Item
                  key="userId"
                  name={['userId']}
                  label={t('settings.integrations.fields.user.title')}
                >
                  <Select
                    style={{ width: '50%' }}
                    showSearch
                    size="middle"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (!option || !option.label) {
                        return false
                      }
                      return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    options={users?.map((user) => ({
                      key: user.id,
                      value: user.id,
                      label: truncate(
                        user.firstName && user.lastName
                          ? `${user.firstName} ${user.lastName}`
                          : user.email,
                        15,
                      ),
                    }))}
                  />
                </Form.Item>
                <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                  {t('settings.integrations.fields.user.description')}
                </Text>
                <Form.Item
                  key="locationId"
                  name={['locationId']}
                  label={t('settings.integrations.fields.location.title')}
                >
                  <Select
                    style={{ width: '50%' }}
                    showSearch
                    size="middle"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (!option || !option.label) {
                        return false
                      }
                      return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    options={locations?.map((location) => ({
                      key: location.id,
                      value: location.id,
                      label: truncate(location.name, 15),
                    }))}
                  />
                </Form.Item>
                <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                  {t('settings.integrations.fields.location.description')}
                </Text>

                <Button
                  prefixIcon={<SnippetsOutlined />}
                  action={'manually-import-data'}
                  onClick={() => setModalIsVisible(true)}
                  category={'api-integrations'}
                  style={{ marginRight: 25 }}
                  disabled={!selectedIntegration?.configurations?.[0]}
                >
                  {t('settings.integrations.actions.import')}
                </Button>
              </Col>
              <Col span={10}>
                <Title as="h5">{t('settings.integrations.headlines.integration')}</Title>
                {(
                  selectedIntegration?.metadata as {
                    fields: {
                      key: string
                      label: string
                      description: string
                      type:
                        | 'number'
                        | 'search'
                        | 'textarea'
                        | 'text'
                        | 'email'
                        | 'password'
                        | 'group'
                        | 'checkbox'
                        | undefined
                      default: string
                    }[]
                  }
                )?.fields?.map((field) => (
                  <>
                    <Form.Item
                      initialValue={field.default}
                      key={field.key}
                      name={[field.key]}
                      label={field.label}
                      valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}
                    >
                      <InputField defaultValue={field.default} type={field.type} />
                    </Form.Item>
                    <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                      {field.description}
                    </Text>
                  </>
                ))}
                <Button
                  prefixIcon={<ApiOutlined />}
                  action={'check-connection'}
                  category={'api-integrations'}
                  onClick={() => check()}
                >
                  {t('settings.integrations.actions.check-connection')}
                </Button>

                <Form.Item>
                  <Button
                    style={{ marginTop: 25 }}
                    type="primary"
                    htmlType="submit"
                    action={'submit'}
                    category={'api-integrations'}
                  >
                    {t('settings.integrations.actions.submit')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Spin>
    </Row>
  )
}

export default ConfigureIntegration
