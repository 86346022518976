import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiExternalLink,
  HiOutlineBookOpen,
  HiOutlineBookmark,
  HiOutlineCurrencyDollar,
  HiOutlineLightBulb,
} from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Col, Divider, Row } from 'antd/es'

import { EmptyStateCard, EmptyStateCardProps } from '@/organisms/Onboarding/EmptyStateCard'

import Button from '@/atoms/Button'

import NoLogsImage from '@/assets/blurred-graph.png'
import EmptyGraphImage from '@/assets/empty-graph-state.png'
import { config } from '@/utils/config'

import classes from './Home.module.less'

export enum EmptyStateStatus {
  NONE = 'none',
  NO_LOGS = 'no-logs',
  EMPTY_BOARD = 'empty-board',
}

const LINKS = [
  {
    textTranslationKey: 'onboarding.home.get-started',
    path: config.routes.onboarding,
    icon: <HiOutlineLightBulb />,
  },
  {
    textTranslationKey: 'onboarding.home.wiki',
    path: 'https://intercom.help/cozero',
    icon: <HiOutlineBookOpen />,
    external: true,
  },
  {
    textTranslationKey: 'onboarding.home.academy',
    path: 'https://cozero-academy.teachable.com/courses',
    icon: <HiOutlineBookmark />,
    external: true,
    active: false,
  },
  {
    textTranslationKey: 'onboarding.home.billing',
    path: config.routes.settings.subscription,
    icon: <HiOutlineCurrencyDollar />,
  },
]

const EmptyState = ({
  status,
  customOnClickAction,
}: {
  status?: EmptyStateStatus
  customOnClickAction?: () => void
}): ReactElement | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const emptyStateCard: EmptyStateCardProps = useMemo((): EmptyStateCardProps => {
    if (status === EmptyStateStatus.EMPTY_BOARD) {
      return {
        title: t('onboarding.home.no-graphs-card.title'),
        description: t('onboarding.home.no-graphs-card.subtitle'),
        buttonText: t('onboarding.home.no-graphs-card.btn-text'),
        type: 'graphs',
        onClick: customOnClickAction ? customOnClickAction : () => null,
      }
    } else {
      return {
        title: t('onboarding.home.card.title'),
        description: t('onboarding.home.card.subtitle'),
        buttonText: t('onboarding.home.card.btn-text'),
        type: 'logs',
        onClick: () => navigate(config.routes.log.carbonFootprint.organization.base),
      }
    }
  }, [status])

  if (!status || status === EmptyStateStatus.NONE) {
    return null
  }

  return (
    <Row className={classes.emptyState}>
      <Col span={24}>
        {status === EmptyStateStatus.NO_LOGS && (
          <Row>
            <Col span={24}>
              <Row>
                {LINKS.map(({ textTranslationKey, path, icon, external, active }, idx) => {
                  const text = t(textTranslationKey)
                  if (active === false) {
                    return null
                  }
                  return (
                    <Col key={path}>
                      <Button
                        type="text"
                        prefixIcon={icon}
                        action={'navigate to ' + text}
                        category={'menu'}
                        onClick={() => {
                          if (external) {
                            return window.open(path, '_blank')
                          } else {
                            navigate(path)
                          }
                          return navigate(path)
                        }}
                        className={idx === 0 ? classes.firstButton : ''}
                        color="blue"
                      >
                        {`${text} `}
                        {external && <HiExternalLink />}
                      </Button>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        )}
        <Divider className={classes.divider} />
        <Row>
          <Col span={24}>
            <EmptyStateCard {...emptyStateCard} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <>
              {status === EmptyStateStatus.EMPTY_BOARD && (
                <img src={EmptyGraphImage} className={classes.blurImage} />
              )}
              {status === EmptyStateStatus.NO_LOGS && (
                <img src={NoLogsImage} className={classes.image} />
              )}
            </>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default EmptyState
