import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineDuplicate } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

import { Col, Row, Space, Tag, Tooltip } from 'antd/es'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { ColumnType } from 'antd/es/table'

import { BusinessUnit, Location } from '@cozero/models'

import DotsMenu from '@/molecules/DotsMenu'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'

import { copyToClipboard } from '@/utils/clipboard'
import { config } from '@/utils/config'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'

interface Props {
  showEditAction: boolean
  businessUnitsAllowed: boolean
}

interface Return {
  columns: ColumnType<Location>[]
}

export const useLocationsColumns = ({ showEditAction, businessUnitsAllowed }: Props): Return => {
  const { t } = useTranslation('common')

  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: Location, b: Location) => a.name.localeCompare(b.name),
      },
      {
        title: t('location.tag'),
        dataIndex: ['metadata', 'tags'],
        key: 'tags',
        render(_: string, record: Location): JSX.Element[] | undefined {
          return record.metadata?.tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)
        },
      },
      ...(businessUnitsAllowed
        ? [
            {
              title: t('business-unit.name'),
              dataIndex: ['businessUnit', 'title'],
              key: 'businessUnit',
              sorter: (a: Location, b: Location) =>
                (a.businessUnit as BusinessUnit)?.title.localeCompare(
                  (b.businessUnit as BusinessUnit)?.title,
                ),
            },
          ]
        : []),
      {
        title: t('location.responsible'),
        dataIndex: ['responsible', 'email'],
        key: 'responsible',
        sorter: (a: Location, b: Location) =>
          a.responsible?.email.localeCompare(b.responsible?.email || '') || 0,
      },
      {
        title: t('location.active'),
        dataIndex: 'active',
        key: 'active',
        render(_: string, record: Location) {
          return record.active ? <Tag color="green">{t('yes')}</Tag> : <Tag>{t('no')}</Tag>
        },
        sorter: (a: Location, b: Location) => Number(a.active) - Number(b.active),
      },
      {
        title: t('location.address'),
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: t('id'),
        dataIndex: 'id',
        key: 'id',
        width: 200,
        render(text: number) {
          const idString = `${text}`
          return (
            <Row align="middle" justify="start">
              <Col>
                <Tooltip title={text}>
                  <Text size="xl" code>
                    {truncate(`${text}`, 10)}
                  </Text>
                </Tooltip>
              </Col>
              <Col className={classes.copyIcon} onClick={() => copyToClipboard(idString, t)}>
                <Tooltip title={t('actions.copy.subtitle')}>
                  <HiOutlineDuplicate />
                </Tooltip>
              </Col>
            </Row>
          )
        },
      },
      {
        title: t('actions.title'),
        key: 'action',
        fixed: 'right' as const,
        width: 120,
        render(_: string, location: Location) {
          const buttons = []

          if (location.active) {
            buttons.push(
              ...[
                <Button
                  key="go-to-logs"
                  className={classes.actionButton}
                  type="secondary"
                  action="go-to-logs"
                  category="locations"
                  onClick={() =>
                    navigate(
                      `${config.routes.organization.locations.logs.replace(
                        ':locationId',
                        location?.id.toString(),
                      )}?location=${location.id}`,
                    )
                  }
                >
                  {t('log.logs')}
                </Button>,
              ],
            )
          }

          if (showEditAction) {
            buttons.push(
              <Button
                key="visit-edit-page"
                className={classes.actionButton}
                action="visit-edit-page"
                type="primary"
                category="locations"
                data-cy="edit-location-btn"
                onClick={() =>
                  navigate(
                    config.routes.organization.editLocation.replace(':id', location.id.toString()),
                  )
                }
              >
                {t('actions.edit.title')}
              </Button>,
            )
          }

          const [firstButton, ...restButtons] = buttons

          return (
            <Space className={classes.actions}>
              {firstButton}

              {restButtons.length > 0 && (
                <DotsMenu
                  dotType="vertical"
                  placement="bottomRight"
                  menu={{
                    items: restButtons.map(
                      (button): ItemType => ({
                        type: 'group',
                        label: button,
                      }),
                    ),
                  }}
                />
              )}
            </Space>
          )
        },
      },
    ],
    [],
  )

  return { columns }
}
