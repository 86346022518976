import { CreateLogDto, UpdateLogDto } from '@cozero/dtos'
import { Log, LogSorter, PageFilter, TableViewLog } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_LOG, TAG_LOGS } from './tags'

export const GET_LOG = 'getLog'
export const CREATE_LOG = 'createLog'
export const EDIT_LOG = 'editLog'
export const GET_LOG_COUNT = 'getLogCount'
export const GET_LOG_ENTRY_COUNT = 'getLogEntryCount'
export const DELETE_LOG = 'deleteLog'
export const GET_PAGINATED_LOGS = 'getPaginatedLogs'

export const GET_FULL_LOG_RANGE = 'getFullLogRange'

const logsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_LOG]: builder.query<Log | undefined, number | string>({
      query: (id) => ({
        url: logApiGatewayClient.logs.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
        params: {
          id,
        },
      }),
      providesTags: (result) => [{ type: TAG_LOG, id: result?.id }],
    }),
    [CREATE_LOG]: builder.mutation<Log, Partial<CreateLogDto>>({
      query: (log) => ({
        url: logApiGatewayClient.logs.CREATE,
        method: 'POST',
        data: log,
      }),
      invalidatesTags: (result) => [{ type: TAG_LOG, id: result?.id }],
    }),
    [GET_LOG_COUNT]: builder.query<number, void>({
      query: () => ({
        url: logApiGatewayClient.logs.COUNT,
        method: 'GET',
      }),
    }),
    [GET_FULL_LOG_RANGE]: builder.query<{ startDate?: Date; endDate?: Date }, void>({
      query: () => ({
        url: logApiGatewayClient.logs.GET_RANGE,
        method: 'GET',
      }),
    }),
    [GET_LOG_ENTRY_COUNT]: builder.query<number, void>({
      query: () => ({
        url: logApiGatewayClient.logEntries.COUNT,
        method: 'GET',
      }),
    }),
    [EDIT_LOG]: builder.mutation<Log, { id: number; log: Partial<UpdateLogDto> }>({
      query: ({ id, log }) => ({
        url: logApiGatewayClient.logs.UPDATE_ONE.replace(':id', id.toString()),
        method: 'PUT',
        data: log,
      }),
      invalidatesTags: (result, error, arg) => [{ type: TAG_LOG, id: arg.id }],
    }),
    [DELETE_LOG]: builder.mutation<{ count: number } | undefined, Log>({
      query: (log) => ({
        url: logApiGatewayClient.logs.DELETE_ONE.replace(':id', log.id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_LOG],
    }),
    [GET_PAGINATED_LOGS]: builder.query<
      { logs: TableViewLog[]; totalLogs: number },
      {
        query: {
          type: string
          page: number
          pageSize: number
          filters?: Omit<PageFilter, 'options'>[]
        }
        businessUnitKey?: string
        sort?: LogSorter[]
      }
    >({
      query: (log) => ({
        url: logApiGatewayClient.logs.SEARCH,
        method: 'POST',
        data: log,
      }),
      providesTags: (result) =>
        providesList<TableViewLog[], typeof TAG_LOGS>(result?.logs, TAG_LOGS),
    }),
  }),
})

export const {
  useGetLogQuery,
  useLazyGetLogQuery,
  useCreateLogMutation,
  useGetLogCountQuery,
  useEditLogMutation,
  useDeleteLogMutation,
  useGetPaginatedLogsQuery,
  useGetLogEntryCountQuery,
  useLazyGetLogCountQuery,
  useGetFullLogRangeQuery,
  useLazyGetFullLogRangeQuery,
} = logsApiSlice

export default logsApiSlice
