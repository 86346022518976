import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineMail } from 'react-icons/hi'

import { Col, Row } from 'antd'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import completeSupplierProductCreation from '@/assets/lifecycle-steps/complete-supplier.svg'
import requestFactorSuccess from '@/assets/request-factor-success.svg'

import classes from './supplierShareModal.module.less'

const SupplierShareModal = ({
  visible,
  closeModal,
}: {
  visible: boolean
  closeModal: () => void
}): ReactElement => {
  const { t } = useTranslation()
  return (
    <Modal
      bodyStyle={{ overflowY: 'unset', padding: '0px' }}
      centered
      visible={visible}
      className={classes.modal}
    >
      <Row justify="center">
        <Col>
          <img src={completeSupplierProductCreation} alt="logo" />
        </Col>
        <Col span={24} className={classes.titleWrapper}>
          <Title size="sm" className={classes.title}>
            {t('product.lifecycle-steps.supplier-complete.description')}
          </Title>
        </Col>
        <Col>
          <Button
            className={classes.button}
            category="products"
            action={'ok'}
            type="primary"
            data-cy="ok-modal-button"
            color={'blue'}
            onClick={closeModal}
          >
            {t('product.lifecycle-steps.supplier-complete.btn-text')}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default SupplierShareModal
