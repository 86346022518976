import { UpsertForecastConfigDto } from '@cozero/dtos'
import { ForecastConfig, ForecastRegion, ForecastScenario } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'
import { TAG_FORECAST } from '../forecast/constants'

import { TAG_FORECAST_CONFIG, TAG_FORECAST_REGIONS, TAG_FORECAST_SCENARIOS } from './tags'

export const GET_FORECAST_CONFIG = 'getForecastConfig'
export const GET_FORECAST_REGIONS = 'getForecastRegions'
export const GET_FORECAST_SCENARIOS = 'getForecastScenarios'
export const CREATE_FORECAST_CONFIG = 'createForecastConfig'

const forecastConfigApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_FORECAST_CONFIG]: builder.query<ForecastConfig, number>({
      query: (organizationId) => ({
        url: actApiGatewayClient.forecastConfigs.GET_CONFIG,
        method: 'GET',
        params: {
          organizationId,
        },
      }),
      providesTags: (res) => [{ type: TAG_FORECAST_CONFIG, id: res?.id }],
    }),
    [CREATE_FORECAST_CONFIG]: builder.mutation<{ id: number }, UpsertForecastConfigDto>({
      query: (data) => ({
        url: actApiGatewayClient.forecastConfigs.CREATE_CONFIG,
        method: 'POST',
        data,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: (result) => [
        {
          type: TAG_FORECAST_CONFIG,
          id: result?.id,
        },
        { type: TAG_FORECAST },
      ],
    }),
    [GET_FORECAST_SCENARIOS]: builder.query<ForecastScenario[], void>({
      query: () => ({
        url: actApiGatewayClient.forecastConfigs.GET_SCENARIOS,
        method: 'GET',
      }),
      providesTags: (res) =>
        providesList<ForecastScenario[], typeof TAG_FORECAST_SCENARIOS>(
          res,
          TAG_FORECAST_SCENARIOS,
        ),
    }),
    [GET_FORECAST_REGIONS]: builder.query<ForecastRegion[], void>({
      query: () => ({
        url: actApiGatewayClient.forecastConfigs.GET_REGIONS,
        method: 'GET',
      }),
      providesTags: (res) =>
        providesList<ForecastRegion[], typeof TAG_FORECAST_REGIONS>(res, TAG_FORECAST_REGIONS),
    }),
  }),
})

export const {
  useGetForecastConfigQuery,
  useGetForecastRegionsQuery,
  useGetForecastScenariosQuery,
  useLazyGetForecastConfigQuery,
  useLazyGetForecastRegionsQuery,
  useLazyGetForecastScenariosQuery,
  useCreateForecastConfigMutation,
  usePrefetch,
} = forecastConfigApiSlice

export default forecastConfigApiSlice
