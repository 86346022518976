import React, { useMemo, useState } from 'react'
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps'
import Tooltip from 'react-tooltip'

import { scaleLinear } from 'd3-scale'
import { maxBy, minBy } from 'lodash-es'

import { Report } from '@cozero/models'

import { formatNumber } from '@/utils/number'

const MapChart = React.memo(({ report }: { report?: Report }) => {
  const [content, setContent] = useState('')
  const data = useMemo(() => report?.data.tablePivot || [], [report])
  const max =
    useMemo(
      () =>
        maxBy(data, 'CompanyCalculations.carbonValue')?.[
          'CompanyCalculations.carbonValue'
        ] as number,
      [],
    ) || 0
  const min =
    useMemo(
      () =>
        minBy(data, 'CompanyCalculations.carbonValue')?.[
          'CompanyCalculations.carbonValue'
        ] as number,
      [],
    ) || 0
  const colorScale = scaleLinear([min, max], ['#EDF3FF', '#1259E3'])

  return (
    <div>
      <ComposableMap
        id="tooltip"
        data-tip=""
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 200,
        }}
      >
        <ZoomableGroup>
          {data.length > 0 && (
            <Geographies
              geography={
                'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json'
              }
            >
              {({ geographies }) =>
                geographies.map((geo) => {
                  const d = data.find(
                    (s) =>
                      s['LocationTerritory.iso3'] === geo.id ||
                      s['LogEntryTerritory.iso3'] === geo.id,
                  )
                  const emissions =
                    d?.['CompanyCalculations.carbonValue'] || 0
                      ? formatNumber(parseFloat(`${d?.['CompanyCalculations.carbonValue']}`))
                      : 0

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={
                        d ? colorScale(d['CompanyCalculations.carbonValue'] as number) : '#F5F4F6'
                      }
                      onMouseEnter={() => {
                        setContent(`${geo.properties.name}: ${emissions} tCO2e`)
                      }}
                      onMouseLeave={() => {
                        setContent('')
                      }}
                    />
                  )
                })
              }
            </Geographies>
          )}
        </ZoomableGroup>
      </ComposableMap>
      <Tooltip>{content}</Tooltip>
    </div>
  )
})

MapChart.displayName = 'MapChart'

export default MapChart
