import React, { ReactElement, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiChevronDown } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Divider } from 'antd/es'

import { omit } from 'lodash-es'

import { Limit } from '@cozero/models'
import { Usage } from '@cozero/models'

import ProgressBar from '@/molecules/ProgressBar'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Title from '@/atoms/Title'

import { config } from '@/utils/config'

import classes from './classes.module.less'

const StatCard = ({
  progressMax,
  feature,
  breakdown,
  usage,
}: {
  progressMax: number
  feature: Limit['feature']
  breakdown: { [key: string]: number } | null
  usage: Usage | null
}): ReactElement | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(true)
  const [value, setValue] = useState(0)
  const [userBreakdown, setUserBreakdown] = useState<Record<string, number> | null>(null)
  const [managementLink, setManagementLink] = useState<string>('')

  const navigateToManagement = useCallback(() => {
    return navigate(managementLink)
  }, [managementLink])

  useEffect(() => {
    if (usage) {
      switch (feature.key) {
        case 'users':
          setValue(usage?.users?.total ?? 0)
          setUserBreakdown(omit(usage.users, 'total'))
          setManagementLink(config.routes.settings.users)
          break
        case 'business-units':
          setValue(usage?.businessUnits ?? 0)
          setManagementLink(config.routes.organization.businessUnits)
          break
        case 'locations':
          setValue(usage?.locations ?? 0)
          setManagementLink(config.routes.organization.locations.base)
          break
        case 'products':
          setValue(usage?.products ?? 0)
          setManagementLink(config.routes.log.carbonFootprint.products.base)
          break
        case 'api':
          setValue(usage?.api ?? 0)
          setManagementLink('https://cozero.readme.io/')
          break
      }
    }
  }, [usage])

  const toggleCollapse = useCallback((): void => setCollapsed(!collapsed), [collapsed])

  if (!feature) {
    return null
  }

  return (
    <Card shadow="none" className={`${classes.statCard} ${classes.active}`}>
      <div className={classes.statCardTitle} onClick={toggleCollapse}>
        <Title as="h5">{t(`settings.subscription.${feature.key}.title_other`)}</Title>
        <HiChevronDown onClick={toggleCollapse} />
      </div>
      <ProgressBar
        value={value}
        max={progressMax}
        label={t(`settings.subscription.${feature.key}.title_other`)}
      />
      <div className={`${classes.statCardPanel} ${!collapsed && classes.active}`}>
        <Divider />
        {userBreakdown && breakdown && (
          <div className={classes.userBreakdown}>
            {Object.entries(breakdown).map(([role, count]) => (
              <div key={role} className={classes.role}>
                <span>{t(`settings.subscription.users.${role}`)}</span>
                <span>
                  {userBreakdown[role]}/{count}
                </span>
              </div>
            ))}
          </div>
        )}
        <Button
          action={`manage-${feature}`}
          category={'subscriptions'}
          onClick={navigateToManagement}
        >
          {t(`settings.subscription.manage.${feature?.key}`)}
        </Button>
      </div>
    </Card>
  )
}

export default memo(StatCard)
