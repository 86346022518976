import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Col from 'antd/es/col'

import { Board, Report, ReportType } from '@cozero/models'

import Text from '@/atoms/Text'

import { useFiltersContext } from '@/contexts/filters'
import useBoards from '@/hooks/useBoards'
import i18n from '@/i18n'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

import OverviewCard from '../OverviewCard'

import classes from './GraphStatisticsCard.module.less'
import { UnitMeasureText } from './UnitMeasureText'

interface GraphStatisticsCardProps {
  report: Report | null
}

export const GraphStatisticsCard = ({ report }: GraphStatisticsCardProps): JSX.Element | null => {
  const { filters } = useFiltersContext()
  const [statsLoading, setStatsLoading] = useState<boolean>(true)
  const [statsError, setStatsError] = useState<boolean>(false)
  const [currentStatData, setCurrentStatData] = useState<Report | null>(report)
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const { getReportWithData } = useBoards()
  const abortControllerRef = useRef<AbortController>()
  const location = useLocation()

  useEffect(() => {
    if (currentStatData?.type === ReportType.STATISTIC && selectedBusinessUnit) {
      fetchGraphData()
    }
  }, [filters, selectedBusinessUnit?.key])

  useEffect(() => {
    return function cleanup() {
      abortControllerRef.current?.abort()
    }
  }, [location.pathname])

  useEffect(() => {
    return function cleanup() {
      abortControllerRef.current?.abort()
    }
  }, [location.pathname])
  if (!currentStatData) {
    return <></>
  }

  const fetchGraphData = async (): Promise<void> => {
    setStatsLoading(true)

    // Check if previous request was executed and trigger abort
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    // Assign new controller to the current ref for every new graph request
    abortControllerRef.current = new AbortController()

    /** Using try & catch here so that when AbortController is triggered
     * for the previous request, we set Loading false only when the report is fetched
     * or throws an actual error and not when the request has been cancelled
     **/
    if (!currentStatData?.key) {
      return
    }

    try {
      const reportWithData = await getReportWithData(
        currentStatData?.key,
        filters,
        undefined,
        abortControllerRef.current?.signal,
      )
      if (reportWithData) {
        setCurrentStatData(reportWithData)
        setStatsError(false)
        setStatsLoading(false)
      }
    } catch (e) {
      if (e.message !== 'canceled') {
        setStatsError(true)
        setStatsLoading(false)
      }
    }
  }

  if (!currentStatData) {
    return null
  }

  const { type, data, cubeJSQuery, title } = currentStatData

  return (
    <>
      {type === ReportType.STATISTIC && !statsError && (
        <Col flex="auto" style={{ maxWidth: '50%' }}>
          <div className={classes.statisticContainer}>
            <OverviewCard
              style={{ height: '100%' }}
              loading={statsLoading}
              content={
                <>
                  {data && data.totalRow && data.seriesNames && data.seriesNames[0] && (
                    <Text
                      mode="title"
                      fontWeight="medium"
                      size="lg"
                      className={classes.emissionsValue}
                    >
                      {data.totalRow
                        ? `${data.totalRow[data.seriesNames[0].key]?.toLocaleString(i18n.language, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : ''}
                    </Text>
                  )}

                  <UnitMeasureText cubeJSQuery={cubeJSQuery} />
                </>
              }
              headerTitle={title}
            />
          </div>
        </Col>
      )}
    </>
  )
}
