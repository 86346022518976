import React, { ReactNode } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import PageNotFound from '@/pages/404'
import AdminSettingsBase from '@/pages/AdminSettings'
import UpsertUser from '@/pages/Organization/UpsertUser'
import UsersSettings from '@/pages/Organization/Users'
import ApiKeysSettings from '@/pages/Settings/Organization/ApiKeys'
import ConfigureIntegration from '@/pages/Settings/Organization/ConfigureIntegration'
import Data from '@/pages/Settings/Organization/Data'
import Integrations from '@/pages/Settings/Organization/Integrations'
import OrganizationInfo from '@/pages/Settings/Organization/OrganizationInfo'
import PaymentCancelled from '@/pages/Settings/Organization/Payment/Cancel'
import PaymentSuccess from '@/pages/Settings/Organization/Payment/Success'
import Plans from '@/pages/Settings/Organization/Plans'
import SubscriptionSettings from '@/pages/Settings/Organization/Subscription'
import UserFunctions from '@/pages/Settings/Organization/UserFunctions'
import UserProfile from '@/pages/Settings/UserProfile'

import UserCrumb from '@/molecules/Breadcrumbs/UserCrumb'

import AppProvider from '@/contexts/app'
import LogProvider from '@/contexts/log'
import { config } from '@/utils/config'

import AdminSettingsRoute from '../AdminSettingsRoute'
import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'

const { routes } = config

export const AdminSettingsWrapper = ({
  feature,
  children,
  level,
}: {
  feature?: string
  children: ReactNode
  level?: 'admin' | 'manager'
}): ReactElement => (
  <AppProvider>
    <LogProvider>
      <AdminSettingsRoute level={level}>
        <PrivateRoute>
          <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
        </PrivateRoute>
      </AdminSettingsRoute>
    </LogProvider>
  </AppProvider>
)

const SettingsWrapper = ({
  feature,
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const settingsRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.settings.base,
    children: [
      {
        path: routes.settings.profile,
        breadcrumb: t('layout.profile'),
        element: (
          <SettingsWrapper>
            <UserProfile />
          </SettingsWrapper>
        ),
      },
      {
        path: routes.settings.addUser,
        element: (
          <AdminSettingsWrapper level="manager">
            <UpsertUser />
          </AdminSettingsWrapper>
        ),
      },

      {
        path: routes.settings.editUser,
        element: (
          <AdminSettingsWrapper level="manager">
            <UpsertUser />
          </AdminSettingsWrapper>
        ),
        breadcrumb: UserCrumb,
      },

      {
        path: routes.settings.base,
        element: <AdminSettingsBase />,
        breadcrumb: t('layout.settings'),
        children: [
          {
            index: true,
            breadcrumb: t('layout.organization'),
            element: (
              <AdminSettingsWrapper>
                <OrganizationInfo />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.organization,
            breadcrumb: t('layout.organization'),
            element: (
              <AdminSettingsWrapper>
                <OrganizationInfo />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.users,
            breadcrumb: t('layout.users'),
            element: (
              <AdminSettingsWrapper level="manager">
                <UsersSettings />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.data,
            breadcrumb: t('layout.data'),
            element: (
              <AdminSettingsWrapper level="admin">
                <Data />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.api,
            element: (
              <AdminSettingsWrapper>
                <ApiKeysSettings />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.subscription,
            breadcrumb: t('layout.subscription'),
            element: (
              <AdminSettingsWrapper level="admin">
                <SubscriptionSettings />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.plans,
            element: (
              <AdminSettingsWrapper level="admin">
                <Plans />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.plans,
            element: (
              <AdminSettingsWrapper level="admin">
                <Data />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.integrations,
            breadcrumb: t('layout.integrations'),
            element: (
              <AdminSettingsWrapper level="admin">
                <Integrations />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.installIntegration,
            element: (
              <AdminSettingsWrapper level="admin">
                <ConfigureIntegration />
              </AdminSettingsWrapper>
            ),
          },
          {
            path: routes.settings.functions,
            breadcrumb: t('layout.tabs.functions'),
            element: (
              <AdminSettingsWrapper>
                <FeatureRoute requiredFeature="user-functions">
                  <UserFunctions />
                </FeatureRoute>
              </AdminSettingsWrapper>
            ),
          },
        ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
      },
      {
        path: `${routes.settings.base}/*`,
        element: (
          <SettingsWrapper>
            <PageNotFound />
          </SettingsWrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
  {
    path: config.routes.checkout.paymentCancel,
    element: (
      <AdminSettingsWrapper level="admin">
        <PaymentCancelled />
      </AdminSettingsWrapper>
    ),
  },
  {
    path: config.routes.checkout.paymentSuccess,
    element: (
      <AdminSettingsWrapper level="admin">
        <PaymentSuccess />
      </AdminSettingsWrapper>
    ),
  },
]

export default settingsRoutes
