import React from 'react'

import { Col, Divider, Image, Row } from 'antd/es'

import classes from './classes.module.less'

interface LifecycleModalTitleProps {
  title: string
  description: string
  image?: string
}

const LifecycleModalTitle = ({
  title,
  description,
  image,
}: LifecycleModalTitleProps): JSX.Element => {
  return (
    <>
      {image && (
        <Col span={1}>
          <Image preview={false} className={classes.image} src={image} />
        </Col>
      )}
      <Col className={image && classes.textContainer} span={22}>
        <Row className={classes.title}>
          <Col>{title}</Col>
        </Row>
        <Row className={classes.description}>
          <Col>{description}</Col>
        </Row>
      </Col>
      <Divider />
    </>
  )
}

export default LifecycleModalTitle
