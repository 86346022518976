import { Chart } from '@antv/g2/lib/chart'

export const SVGToDataURL = (chart: Chart): string => {
  const canvas = chart.getCanvas()
  const canvasDom = canvas.get('el')
  const clone = canvasDom.cloneNode(true)
  const svgDocType = document.implementation.createDocumentType(
    'svg',
    '-//W3C//DTD SVG 1.1//EN',
    'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd',
  )
  const svgDoc = document.implementation.createDocument(
    'http://www.w3.org/2000/svg',
    'svg',
    svgDocType,
  )
  svgDoc.replaceChild(clone, svgDoc.documentElement)
  const svgData = new XMLSerializer().serializeToString(svgDoc)
  return 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(svgData)
}

export const downloadImage = (chart: Chart | undefined, name = 'G2Chart'): void => {
  if (!chart) {
    return
  }
  const link = document.createElement('a')
  const renderer = chart.renderer
  const filename = `${name}${renderer === 'svg' ? '.svg' : '.png'}`
  const canvas = chart.getCanvas()
  canvas.get('timeline')?.stopAllAnimations()

  setTimeout(() => {
    if (window.Blob && window.URL && renderer !== 'svg') {
      const canvasDom = canvas.get('el')
      const arr = canvasDom.toDataURL('image/png').split(',')
      const mime = arr[0]?.match(/:(.*?);/)?.[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      const blobObj = new Blob([u8arr], { type: mime })
      link.addEventListener('click', () => {
        link.download = filename
        link.href = window.URL.createObjectURL(blobObj)
      })
    } else {
      link.addEventListener('click', () => {
        link.download = filename
        link.href = SVGToDataURL(chart)
      })
    }
    const e = document.createEvent('MouseEvents')
    e.initEvent('click', false, false)
    link.dispatchEvent(e)
  }, 16)
}
