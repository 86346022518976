import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Tabs, message } from 'antd/es'

import {
  EmissionFactorWithIncludes,
  LogEntryWithPartialIncludes,
  Page,
  ProductLogEntryWithPartialIncludes,
} from '@cozero/models'

import Modal from '@/molecules/Modal'

import Alert from '@/atoms/Alert'

import { useFactorContext } from '@/contexts/factor'

import FactorsTab from '../FactorsTab'

import classes from './classes.module.less'

const { TabPane } = Tabs

interface FactorProps {
  children?: React.ReactNode
  logEntry?: LogEntryWithPartialIncludes | ProductLogEntryWithPartialIncludes
  isVisible: boolean
  isPeriodClosed: boolean
  type?: 'product' | 'location'
  onClose: () => void
}

function FactorsModal({
  logEntry,
  isVisible,
  isPeriodClosed,
  type,
  onClose,
}: FactorProps): ReactElement | null {
  const { t } = useTranslation('common')
  const { getFactors } = useFactorContext()
  const [selectedFactor, setSelectedFactor] = useState<EmissionFactorWithIncludes | undefined>()
  const [upsertingFactor, setUpsertingFactor] =
    useState<Partial<EmissionFactorWithIncludes> | null>()
  const [factors, setFactors] = useState<Page<EmissionFactorWithIncludes> | undefined>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<'all' | 'used'>('used')

  async function getAndSetFactors({
    page = 1,
    pageSize = 10,
    activityDataSourceId,
    used,
    logId,
    type,
    logEntryId,
  }: {
    page?: number
    pageSize?: number
    activityDataSourceId?: number
    used?: boolean
    logId?: number
    type?: 'product' | 'location'
    logEntryId?: number
  }): Promise<void> {
    try {
      setFetching(true)
      const fetchedFactors = await getFactors({
        page,
        pageSize,
        activityDataSourceId,
        used,
        logId,
        type,
        logEntryId,
      })
      if (fetchedFactors) {
        setFactors(fetchedFactors)
      }
    } catch (err) {
      message.error(t('factors.error'))
    } finally {
      setFetching(false)
    }
  }

  const getFactorsByActivityDataSource = async ({
    page,
    pageSize,
  }: {
    page: number
    pageSize: number
  }): Promise<void | Page<EmissionFactorWithIncludes>> => {
    if (!logEntry?.activityDataSourceId) {
      return
    }
    await getAndSetFactors({
      activityDataSourceId: logEntry?.activityDataSourceId,
      page,
      pageSize,
    })
  }

  const getUsedFactors = async ({
    page,
    pageSize,
  }: {
    page: number
    pageSize: number
  }): Promise<void | Page<EmissionFactorWithIncludes>> => {
    await getAndSetFactors({
      used: true,
      logEntryId: logEntry?.id,
      page,
      type,
      pageSize,
    })
  }

  useEffect(() => {
    setSelectedFactor(undefined)
    setUpsertingFactor(null)
  }, [isVisible])

  return (
    <Modal width={860} title={t('factors.modal-title')} visible={isVisible} onCancel={onClose}>
      <Tabs onChange={(e) => setSelectedTab(e as 'used' | 'all')}>
        <TabPane tab={t('factors.tabs.used')} key="used">
          <Alert message={t('factors.used')} type="info" className={classes.alert} />
        </TabPane>
        <TabPane tab={t('factors.tabs.all')} key="all">
          <Alert message={t('factors.all')} type="info" className={classes.alert} />
        </TabPane>
      </Tabs>
      <FactorsTab
        selectedTab={selectedTab}
        getFactors={selectedTab === 'used' ? getUsedFactors : getFactorsByActivityDataSource}
        isPeriodClosed={isPeriodClosed}
        setSelectedFactor={setSelectedFactor}
        upsertingFactor={upsertingFactor}
        loading={fetching}
        selectedFactor={selectedFactor}
        factors={factors}
        logEntryId={logEntry?.id}
        closeParentModal={onClose}
      />
    </Modal>
  )
}

export default FactorsModal
