import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { HiPencilAlt } from 'react-icons/hi'

import { Skeleton } from 'antd/es'

import InputField from '../InputField'

import classes from './Title.module.less'

type EditableTitleProps =
  | { editable?: false; onChange?: never; value?: never }
  | { editable: true; onChange: (value: string) => void; value?: string }

type CommonProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  fontWeight?: 'default' | 'semibold' | 'bold' | 'medium'
  loading?: boolean
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  spaceSize?: 'base' | 'small' | 'medium' | 'large'
  className?: string
  children: ReactNode
}

export type TitleProps = CommonProps & EditableTitleProps

const Title = ({
  onChange,
  size = 'md',
  className = '',
  children,
  loading = false,
  fontWeight = 'default',
  as = 'h2',
  editable = false,
  spaceSize = 'base',
  value,
}: TitleProps): ReactElement => {
  const lastKeyCode = React.useRef<string>()
  const [editing, setEditing] = useState(false)
  const [internalValue, setInternalValue] = useState<string | ReactNode>(value ?? children)
  const classNames = useMemo(() => {
    const classArr = [
      classes.title,
      fontWeight !== 'default' && classes[fontWeight],
      classes[size],
      classes[spaceSize],
      className,
    ]
    return classArr.join(' ')
  }, [size, fontWeight, spaceSize, className])

  const Tag = useMemo(() => {
    switch (as) {
      case 'h1':
        return 'h1'
      case 'h2':
        return 'h2'
      case 'h3':
        return 'h3'
      case 'h4':
        return 'h4'
      case 'h5':
        return 'h5'
      default:
        return 'h2'
    }
  }, [as])

  const onFinishEditing = useCallback((): void => {
    onChange && onChange(internalValue as string)
    return setEditing(false)
  }, [onChange, value, internalValue])

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setInternalValue(e.target.value)
    },
    [setInternalValue],
  )

  const onBlur = useCallback((): void => {
    onFinishEditing()
  }, [onFinishEditing])

  const onCancelEditing = useCallback(() => {
    setInternalValue(value ?? (children as string))
    setEditing(false)
  }, [children, value])

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    lastKeyCode.current = key
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
    ctrlKey,
    altKey,
    metaKey,
    shiftKey,
  }) => {
    // Check if it's a real key

    if (lastKeyCode.current === key && !editing && !ctrlKey && !altKey && !metaKey && !shiftKey) {
      if (key === 'Enter') {
        onFinishEditing()
      } else if (key === 'Escape') {
        onCancelEditing()
      }
    }
  }

  const onEditableClick = (): void => setEditing(true)

  useEffect(() => {
    if (value) {
      setInternalValue(value)
    } else {
      setInternalValue(children)
    }
  }, [value, children])

  if (loading) {
    return <Skeleton.Input className={classes.skeleton} active={loading} size="large" />
  }

  if (!editing && !loading && editable) {
    return (
      <div onClick={onEditableClick} className={classes.editableTitle}>
        <Tag className={classNames}>{children}</Tag>
        <HiPencilAlt />
      </div>
    )
  }

  if (editable && editing && onChange) {
    return (
      <InputField
        onChange={handleInputChange}
        value={internalValue as string}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        type="text"
        defaultValue={value ?? (children as string)}
      />
    )
  }

  return <Tag className={classNames}>{children}</Tag>
}

export default memo(Title)
