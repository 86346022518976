import React, { PropsWithChildren, ReactElement } from 'react'

import { MenuItemProps } from 'antd'
import { Menu } from 'antd/es'

import mixpanel from 'mixpanel-browser'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'

import classes from './DotsMenuItem.module.less'

interface DotsMenuProps {
  action?: string
  category?: string
}

export const DotsMenuItem = ({
  action,
  category,
  onClick,
  children,
  ...rest
}: PropsWithChildren<DotsMenuProps & MenuItemProps>): ReactElement => {
  const handleClick: MenuClickEventHandler = (event) => {
    if (mixpanel.track && action && category) {
      mixpanel.track(`${category} - ${action}`)
    }

    if (onClick) {
      return onClick(event)
    }
  }

  return (
    <Menu.Item {...rest} onClick={handleClick} className={classes.dotsMenuItem}>
      {children}
    </Menu.Item>
  )
}
