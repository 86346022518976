import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Input, Spin, message } from 'antd/es'

import { Organization } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Alert from '@/atoms/Alert'
import InputField from '@/atoms/InputField'

import { useSubscriptionContext } from '@/contexts/subscription'
import { useAppSelector } from '@/redux'
import { selectUserOrganization } from '@/redux/auth'

import classes from './classes.module.less'

interface Props {
  handleCancel: () => void
  selectedOrganization?: Organization
}

export function ContactModal({ handleCancel, selectedOrganization }: Props): JSX.Element {
  const { t } = useTranslation('common')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const organization = useAppSelector(selectUserOrganization)
  const { createHubspotLead } = useSubscriptionContext()
  const [form] = Form.useForm()

  async function onSave(): Promise<void> {
    const values = form.getFieldsValue()
    try {
      if (!values.firstname || !values.lastname || !values.company || !values.email) {
        setError(t('organization.missing-fields'))
        return
      }
      setLoading(true)
      await createHubspotLead(values)
      message.success(t('actions.contact.success'))
      handleCancel()
    } catch (error) {
      message.error(t('general-errors.try-again'))
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      centered
      visible={!!selectedOrganization}
      title={t('plans.sales')}
      onCancel={handleCancel}
      className={classes.modal}
      width="50%"
      hideFooter={false}
      okText={t('actions.save')}
      onOk={onSave}
    >
      <Spin spinning={loading} className={classes.modalContainer}>
        {error && <Alert message={error} type="error" />}
        <Form layout="vertical" initialValues={organization} autoComplete="off" form={form}>
          <Form.Item label={t('user.first-name')} name="firstname" rules={[{ required: true }]}>
            <InputField placeholder={t('user.placeholder.firstName')} />
          </Form.Item>
          <Form.Item label={t('user.last-name')} name="lastname" rules={[{ required: true }]}>
            <InputField placeholder={t('user.placeholder.lastName')} />
          </Form.Item>
          <Form.Item
            label={t('user.company')}
            name="company"
            rules={[{ required: true }]}
            initialValue={selectedOrganization?.name}
          >
            <InputField placeholder={t('user.placeholder.company')} />
          </Form.Item>
          <Form.Item label={t('user.email')} name="email" rules={[{ required: true }]}>
            <InputField placeholder={t('user.placeholder.email')} />
          </Form.Item>
          <Form.Item label={t('user.phone')} name="phone">
            <InputField placeholder={t('user.placeholder.phone')} />
          </Form.Item>
          <Form.Item label={t('user.contactReason')} name="contactReason">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}
