import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineDuplicate, HiOutlineTrash } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Image, Popconfirm, Space, Typography } from 'antd/es'

import moment from 'moment'

import { LogSorter, Product, TableViewProductLog } from '@cozero/models'

import DotsMenu, { DotsMenuItem } from '@/molecules/DotsMenu'

import Tag from '@/atoms/Tag'

import useGenerateData, { GenerateColumn } from '@/hooks/useGenerateData'
import useProducts from '@/hooks/useProducts'
import { config } from '@/utils/config'
import { convertTonnesToGrams, formatNumber } from '@/utils/number'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'

const useProductTableColumns = ({
  tableSorter,
}: {
  tableSorter: LogSorter[]
  businessUnitsAllowed: boolean
}): { columnsData: GenerateColumn<TableViewProductLog>[]; deletedProduct: Product | undefined } => {
  const { t } = useTranslation('common')
  const { getInitialOrder } = useGenerateData()
  const [deletedProduct, setDeletedProduct] = useState<Product | undefined>()
  const { deleteProduct: deleteProductApi, duplicateProduct: duplicateProductApi } = useProducts()
  const navigate = useNavigate()

  const deleteProduct = async (productId: number): Promise<void> => {
    const product = await deleteProductApi(productId)
    setDeletedProduct(product)
  }

  const duplicateProduct = async (productId: number): Promise<void> => {
    const product = await duplicateProductApi(productId)

    if (product) {
      const url = config.routes.log.carbonFootprint.products.productDetails.replace(
        ':productId',
        product.id.toString(),
      )

      navigate(url)
    }
  }

  const columnsData: GenerateColumn<TableViewProductLog>[] = useMemo(
    () => [
      {
        title: () => <span className={classes.columnTitle}>{t('product.productName')}</span>,
        dataIndex: 'product.name',
        key: 'product',
        sorter: false,
        sortOrder: getInitialOrder('product.name', tableSorter),
        width: 200,
        render(_, product) {
          return (
            <span className={classes.row}>
              {product.image?.url && (
                <Image
                  width={30}
                  height={30}
                  src={product.image?.url}
                  preview={false}
                  className={classes.icon}
                />
              )}
              <Typography.Text className={classes.tableContent}>
                {truncate(product.name as string, 20)}
              </Typography.Text>
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('product.product-code')}</span>,
        dataIndex: 'product.externalId',
        key: 'productCode',
        sorter: false,
        sortOrder: getInitialOrder('product.externalId', tableSorter),
        width: 150,

        render(_, product) {
          return (
            <span className={classes.row}>
              {product.externalId && (
                <Tag truncateText size="sm" className={classes.tableContent}>
                  {product.externalId as string}
                </Tag>
              )}
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('product.productType')}</span>,
        dataIndex: 'product.productType',
        key: 'productType',
        sorter: false,
        sortOrder: getInitialOrder('product.productType.name', tableSorter),
        width: 150,
        render(_, product) {
          return (
            <span className={classes.row}>
              {product.productType?.name && (
                <Tag className={classes.tableContent} truncateText size="sm">
                  {product.productType?.name as string}
                </Tag>
              )}
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('product.productTag')}</span>,
        dataIndex: 'tags',
        key: 'productTag',
        sorter: false,
        sortOrder: getInitialOrder('product.productTag.name', tableSorter),
        width: 300,

        render(_, product) {
          if (!product.tags?.length) {
            return <></>
          }
          return (
            <span className={classes.row}>
              {product.tags.slice(0, 3).map((name: string) => (
                <Tag className={classes.tag} truncateText size="xs" key={name}>
                  {truncate(name, 40)}
                </Tag>
              ))}
              {product.tags.length > 3 && (
                <Tag className={classes.tag} truncateText size="xs">
                  +{product.tags.length - 3}
                </Tag>
              )}
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('log.period')}</span>,
        dataIndex: 'period',
        key: 'endDate',
        sorter: true,
        sortOrder: getInitialOrder('endDate', tableSorter),
        width: 320,
        render(_, product) {
          return product?.startDate && product?.endDate ? (
            <Space className={classes.row}>
              <Tag className={classes.tableContent} size="xs">
                {moment(product?.startDate).utc().format('LL')}
              </Tag>
              <Typography className={classes.periodDivisor}>{t('to')}</Typography>
              <Tag className={classes.tableContent} size="xs">
                {moment(product?.endDate).utc().format('LL')}
              </Tag>
            </Space>
          ) : (
            <></>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('log.emissions-per-unit')}</span>,
        dataIndex: 'emissionsPerUnit',
        sorter: true,
        sortOrder: getInitialOrder('emissionsPerUnit', tableSorter),
        key: 'emissionsPerUnit',
        width: 150,
        render(value) {
          return (
            <span>
              <Typography.Text className={classes.emissions}>
                {formatNumber(convertTonnesToGrams((value as number) ?? 0))}
              </Typography.Text>
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('log.total-emissions')}</span>,
        dataIndex: 'totalEmissions',
        sorter: true,
        sortOrder: getInitialOrder('totalEmissions', tableSorter),
        fixed: 'right',
        width: 150,
        key: 'totalEmissions',
        render(value) {
          return <span className={classes.emissions}>{formatNumber((value as number) ?? 0)}</span>
        },
      },
      {
        title: t('log.options-column'),
        key: 'action',
        fixed: 'right',
        width: 100,
        render(_, product) {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <DotsMenu
                dotType="vertical"
                placement="bottomRight"
                menu={{
                  className: classes.menu,
                  items: [
                    {
                      className: classes.menuGroup,
                      key: 'duplicate',
                      label: (
                        <DotsMenuItem
                          category="product-logs"
                          action="duplicate"
                          icon={<HiOutlineDuplicate size={16} />}
                          onClick={() => duplicateProduct(product.id)}
                        >
                          {t('product.duplicate.title')}
                        </DotsMenuItem>
                      ),
                    },
                    {
                      className: classes.menuGroup,
                      key: 'delete',
                      label: (
                        <Popconfirm
                          placement="bottom"
                          title={t('product.delete.warning')}
                          onConfirm={() => deleteProduct(product.id)}
                          okText={t('yes')}
                          cancelText={t('no')}
                        >
                          <DotsMenuItem
                            category="product-logs"
                            action="delete"
                            icon={<HiOutlineTrash size={16} />}
                            danger
                          >
                            {t('product.delete.title')}
                          </DotsMenuItem>
                        </Popconfirm>
                      ),
                    },
                  ],
                }}
              />
            </div>
          )
        },
      },
    ],
    [JSON.stringify(tableSorter)],
  )

  return {
    columnsData,
    deletedProduct,
  }
}

export default useProductTableColumns
