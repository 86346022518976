import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { Col, Input, Radio, Row, Select, Switch, message } from 'antd/es'
import { useForm } from 'antd/es/form/Form'
import { Store } from 'antd/es/form/interface'

import moment from 'moment'

import { ClosedPeriodStatus } from '@cozero/constants/log'
import { CreatedClosedPeriod } from '@cozero/models'

import OverviewCard, { OverviewCardProps } from '@/molecules/OverviewCard'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import DateRangePicker from '@/atoms/DateRangePicker'
import Form from '@/atoms/Form'
import HighlightValue from '@/atoms/HighlightValue'
import Pill from '@/atoms/Pill'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { ClosedPeriodForm, useClosedPeriod } from '@/hooks/useClosedPeriod'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getIsAdmin } from '@/redux/auth'
import {
  selectSelectedBusinessUnitKey,
  useGetActiveBusinessUnitsQuery,
} from '@/redux/businessUnits'
import { useGetActiveLocationsQuery } from '@/redux/locations'
import { config } from '@/utils/config'

import classes from './ClosedPeriod.module.less'

interface FormSelect {
  label: string
  value: number
}

const ClosedPeriod = (): ReactElement => {
  const { t } = useTranslation('')
  const businessUnitKey = useAppSelector(selectSelectedBusinessUnitKey)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const isAdmin = useAppSelector(getIsAdmin)
  const { data: businessUnits } = useGetActiveBusinessUnitsQuery(
    { businessUnitKey: businessUnitKey ?? '' },
    { skip: !businessUnitKey },
  )
  const { data: locations } = useGetActiveLocationsQuery(
    { businessUnitKey: businessUnitKey ?? '' },
    { skip: !businessUnitKey },
  )
  const {
    createClosedPeriod,
    previewKeyStats,
    loadingPreview,
    keyStats,
    resetKeyStats,
    minimumDataToPreview,
    parseForm,
    getClosedPeriod,
    updateClosedPeriod,
    closedPeriod,
  } = useClosedPeriod()
  const [form] = useForm<ClosedPeriodForm>()
  const [disabledSave, setDisabledSave] = useState<boolean>(true)
  const [locationsSelect, setLocationsSelect] = useState<FormSelect[]>([])
  const [selectedOrganizationLevel, setSelectedOrganizationLevel] = useState<string>('organization')
  const [businessUnitsSelect, setBusinessUnitsSelect] = useState<FormSelect[]>([])

  const { id } = useParams()
  const navigate = useNavigate()

  const handleFormChange = (): void => {
    const values = form.getFieldsValue()
    delete values.id
    const hasErrors =
      Object.values(values).some((item) =>
        Array.isArray(item) ? item.length === 0 : item === undefined,
      ) || form.getFieldsError().some(({ errors }) => errors.length)
    setDisabledSave(hasErrors)
  }

  const handleValuesChange = (changedValue: Partial<ClosedPeriodForm>): void => {
    if (changedValue.organizationLevel) {
      resetDynamicFields(changedValue.organizationLevel)
      setSelectedOrganizationLevel(changedValue.organizationLevel)
    }
    const changedKey = Object.keys(changedValue)[0] as keyof ClosedPeriodForm
    if (changedKey !== 'name' && changedKey !== 'description' && changedKey !== 'status') {
      calculatePreview(changedKey)
    }
  }

  const resetDynamicFields = (organizationLevel: string): void => {
    resetKeyStats()
    form.setFields([
      { name: 'locations', value: undefined },
      { name: 'businessUnit', value: undefined },
    ])
    form.validateFields([organizationLevel, 'locations', 'businessUnit'])
  }

  const calculatePreview = (changedKey?: keyof ClosedPeriodForm): void => {
    const formValues = form.getFieldsValue()
    const organizationLevelCondition =
      (formValues.organizationLevel === 'location' && !!formValues.locations?.length) ||
      formValues.organizationLevel === 'organization' ||
      (formValues.organizationLevel === 'businessUnit' && !!formValues.businessUnit)
    if (!changedKey) {
      previewKeyStats(parseForm(form.getFieldsValue()))
    } else {
      if (
        minimumDataToPreview.every((el) =>
          Array.isArray(formValues[el]) ? (formValues[el] as []).length : !!formValues[el],
        )
      ) {
        if (!organizationLevelCondition) {
          resetKeyStats()
        } else {
          previewKeyStats(parseForm(form.getFieldsValue()))
        }
      } else {
        resetKeyStats()
      }
    }
  }

  const onFinish = (values: ClosedPeriodForm): void => {
    const changedFields = form.getFieldsValue(true, (meta) => meta.touched)
    id ? updateClosedPeriod(id, parseForm(changedFields)) : createClosedPeriod(parseForm(values))
    setDisabledSave(true)
  }

  const populateForm = (
    data:
      | (CreatedClosedPeriod & {
          locations?:
            | {
                id: number
              }[]
            | undefined
        })
      | undefined,
  ): Store => {
    return {
      name: data?.name,
      timePeriod:
        data?.startDate && data.endDate ? [moment(data.startDate), moment(data.endDate)] : [],
      description: data?.description ?? '',
      organizationLevel: data?.organizationLevel ?? !isAdmin ? 'businessUnit' : 'organization',
      locations: data?.locations,
      businessUnit: data?.businessUnitId,
      status: data?.status === ClosedPeriodStatus.CLOSED ? false : true,
    }
  }

  useEffect(() => {
    if (closedPeriod) {
      form.resetFields()
      calculatePreview()
    }
  }, [closedPeriod])

  const fetchData = async (): Promise<void> => {
    try {
      if (id !== undefined) {
        getClosedPeriod(id)
      }

      if (id) {
        getClosedPeriod(id)
      }
    } catch (error) {
      message.error(t('log.closed-period.initial-data-error'))
    }
  }

  const overviewCardTemplate = (content: ReactNode): ReactNode => {
    if (!keyStats) {
      return <Row className={classes.emptyStats}>{t('log.closed-period.preview')}</Row>
    } else {
      return content
    }
  }

  const overviewData: OverviewCardProps[] = [
    {
      content: overviewCardTemplate(
        <div className={classes.alignRow}>
          <HighlightValue
            value={keyStats?.emissionsDataValue.toString() ?? ''}
            unit={t('log.co2-unit')}
          />
          <Row>
            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {t('log.closed-period.closed-period-logs')}
              <Pill className={classes.marginPill} color="cinder-blue">
                {keyStats?.emissionsDataCount.toString()}
              </Pill>
            </span>
          </Row>
        </div>,
      ),

      headerTitle: t('log.overview-stats.organization-footprint'),
    },
  ]

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (locations) {
      setLocationsSelect(
        locations.map((location) => ({ label: location.name, value: location.id })),
      )
    }
    if (businessUnits) {
      setBusinessUnitsSelect(
        businessUnits.map((businessUnit) => ({
          label: businessUnit.title,
          value: businessUnit.id,
        })),
      )
    }
  }, [locations, businessUnits])

  return (
    <Row>
      <Col span={24}>
        <Row className={classes.section} gutter={30}>
          <Col span={24}>
            <Title size="xl">{t('log.closed-period.title')}</Title>
          </Col>
          <Col span={24}>
            <Text className={classes.subtitle}>{t('log.closed-period.subtitle')}</Text>
          </Col>
          <hr></hr>
        </Row>
        <Row gutter={60}>
          <Col span={12}>
            <Alert message={t('log.closed-period.alert')} type="info" />
            <Form
              layout="vertical"
              hideRequiredMark
              category="carbon-footprint"
              initialValues={populateForm(closedPeriod)}
              form={form}
              onFieldsChange={handleFormChange}
              onValuesChange={handleValuesChange}
              onFinish={onFinish}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="id" name="id" hidden />
                  <Form.Item
                    label={t('log.closed-period.report-name')}
                    name={'name'}
                    rules={[{ required: true, message: t('log.closed-period.report-name-error') }]}
                  >
                    <Input placeholder={t('log.closed-period.report-name')} />
                  </Form.Item>
                  <Form.Item
                    label={t('log.closed-period.time-period')}
                    name={'timePeriod'}
                    rules={[{ required: true, message: t('log.closed-period.time-period-error') }]}
                  >
                    <DateRangePicker />
                  </Form.Item>
                  <Form.Item
                    label={t('log.closed-period.draft-mode')}
                    name="status"
                    valuePropName="checked"
                    className={classes.draftModeItem}
                  >
                    <Switch />
                  </Form.Item>
                  <Text color="disabled">{t('log.closed-period.draft-mode-description')}</Text>
                  <Form.Item label={t('log.closed-period.description')} name="description">
                    <Input.TextArea
                      placeholder={t('log.closed-period.description')}
                      minLength={2}
                      maxLength={100}
                      allowClear
                    />
                  </Form.Item>
                  <hr></hr>
                  <Form.Item
                    name="organizationLevel"
                    label={t('log.closed-period.organization-levels.title')}
                  >
                    <Radio.Group>
                      <Radio disabled={!isAdmin} value="organization">
                        {t('log.closed-period.organization-levels.entire-organization')}
                      </Radio>
                      <Radio value="businessUnit">
                        {t('log.closed-period.organization-levels.business-unit')}
                      </Radio>
                      <Radio value="location">
                        {t('log.closed-period.organization-levels.location')}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {selectedOrganizationLevel === 'location' && (
                    <Form.Item
                      label={t('log.closed-period.locations')}
                      name="locations"
                      rules={[{ required: true, message: t('log.closed-period.locations-error') }]}
                    >
                      <Select
                        mode="multiple"
                        size="large"
                        placeholder={t('log.closed-period.locations')}
                        fieldNames={{ label: 'label', value: 'value' }}
                        filterOption={(input, option) =>
                          (option?.label as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={locationsSelect}
                      ></Select>
                    </Form.Item>
                  )}
                  {selectedOrganizationLevel === 'businessUnit' &&
                    featuresAllowed?.includes('business-units') && (
                      <Form.Item
                        label={t('log.closed-period.business-units')}
                        name="businessUnit"
                        rules={[
                          {
                            required: true,
                            message: t('log.closed-period.business-units-error'),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          size="large"
                          placeholder={t('log.closed-period.business-units')}
                          fieldNames={{ label: 'label', value: 'value' }}
                          filterOption={(input, option) =>
                            (option?.label as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={businessUnitsSelect}
                        ></Select>
                      </Form.Item>
                    )}
                  <Form.Item>
                    <Row gutter={16}>
                      <Col>
                        <Button
                          action="Cancel"
                          category="carbon-footprint"
                          onClick={() =>
                            navigate(config.routes.log.carbonFootprint.organization.base)
                          }
                        >
                          <>{t('log.closed-period.cancel-btn')}</>
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          disabled={disabledSave}
                          action="Save"
                          type="primary"
                          category="carbon-footprint"
                          htmlType="submit"
                        >
                          <>
                            {id ? t('log.closed-period.edit-btn') : t('log.closed-period.save-btn')}
                          </>
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={12}>
            <Row className={classes.previewStats}>
              <Col span={24} className={classes.title}>
                <>{t('log.closed-period.key-stats.title')}</>
              </Col>
              <Col span={24}>
                <>{t('log.closed-period.key-stats.subtitle')}</>
              </Col>
              {overviewData.map((el, i) => (
                <Col key={i} span={24} className={classes.statsContainer}>
                  <OverviewCard
                    loading={loadingPreview}
                    headerTitle={el.headerTitle}
                    content={el.content}
                    isVisible={el.isVisible}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ClosedPeriod
