import React, { ReactNode, memo, useMemo } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import classNames from 'classnames'

import Text from '@/atoms/Text'

import classes from './Pill.module.less'

interface Props {
  children: ReactNode
  color?: 'blue' | 'cinder-blue' | 'red' | 'cinder-blue-80' | 'green' | 'orange'
  size?: 'sm' | 'md'
  className?: string
  icon?: JSX.Element
  textClasses?: string
}

const Pill = ({
  children,
  color = 'blue',
  size = 'md',
  className,
  icon,
  textClasses,
}: Props): ReactElement => {
  const wrapperClassNames = classNames(
    classes.pillWrapper,
    classes[color],
    { [classes.withIcon]: icon },
    className,
  )

  const textClassNames = classNames(classes.pill, classes[color], classes[size], textClasses)

  return (
    <div className={wrapperClassNames}>
      {icon}
      <Text className={textClassNames}>{children}</Text>
    </div>
  )
}

export default memo(Pill)
