import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { message } from 'antd/es'
import { useForm } from 'antd/lib/form/Form'

import moment from 'moment'

import { EmissionFactor, EmissionFactorWithIncludes } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Alert from '@/atoms/Alert'
import DatePicker from '@/atoms/DatePicker'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'

import { useAppContext } from '@/contexts/app'

interface FactorProps {
  selectedFactor?: EmissionFactorWithIncludes
  updateCustomFactor: (id: number, values: Partial<EmissionFactorWithIncludes>) => void
  createCustomFactor: (factor: EmissionFactor, values: Partial<EmissionFactorWithIncludes>) => void
  isVisible: boolean
  onClose: () => void
}

function CustomFactorModal({
  selectedFactor,
  updateCustomFactor,
  isVisible,
  onClose,
  createCustomFactor,
}: FactorProps): JSX.Element {
  const { t } = useTranslation('common')
  const [form] = useForm()
  const { getSuppliers, suppliers } = useAppContext()

  useEffect(() => {
    if (isVisible) {
      getSuppliers()
    }
    form.resetFields()
  }, [isVisible])

  useEffect(() => {
    form.setFieldsValue({
      ...selectedFactor,
      ...(selectedFactor?.validityStartDate
        ? { validityStartDate: moment(selectedFactor?.validityStartDate) }
        : moment()),
      ...(selectedFactor?.usageEndDate
        ? { usageEndDate: moment(selectedFactor?.usageEndDate) }
        : {}),
    })
  }, [selectedFactor])

  function submitModal(values: Partial<EmissionFactor>): void {
    try {
      if (selectedFactor?.organizationId) {
        updateCustomFactor(selectedFactor.id, values)
      } else if (selectedFactor) {
        createCustomFactor(selectedFactor, values)
      }
      message.warning(t('factors.save-factor.success'))
    } catch (e) {
      message.error(t('factors.save-factor.error'))
    }
  }

  return (
    <Modal
      width={860}
      title={t('factors.modal-title')}
      visible={isVisible}
      onCancel={() => {
        form.resetFields()
        onClose()
      }}
      onOk={() => submitModal(form.getFieldsValue())}
    >
      <Alert>{t('factors.update-info')}</Alert>
      <Form
        form={form}
        category="factors"
        layout={'vertical'}
        initialValues={{
          ...selectedFactor,
          ...(selectedFactor?.validityStartDate
            ? { validityStartDate: moment(selectedFactor?.validityStartDate) }
            : moment()),
          ...(selectedFactor?.usageEndDate
            ? { usageEndDate: moment(selectedFactor?.usageEndDate) }
            : {}),
        }}
        onFinish={async (values) => submitModal(values)}
      >
        <Form.Item label={t('factors.description')} name={'description'}>
          <InputField />
        </Form.Item>
        <Form.Item label={t('factors.value')} name={'value'}>
          <InputField type={'number'} />
        </Form.Item>
        <Form.Item label={t('factors.endDate')} name="usageEndDate">
          <DatePicker
            picker="year"
            defaultValue={
              (selectedFactor?.usageEndDate && moment(selectedFactor.usageEndDate)) || undefined
            }
          />
        </Form.Item>
        <Form.Item label={t('factors.startDate')} name="validityStartDate">
          <DatePicker format="YYYY" picker="year" defaultValue={moment()} />
        </Form.Item>
        <Form.Item name="supplierId" label={t('layout.suppliers')}>
          <Select
            style={{ width: '100%' }}
            showSearch
            dropdownMatchSelectWidth={false}
            size="middle"
            filterOption={(input, option) =>
              (option?.label as string)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            options={[
              {
                label: t('none'),
                value: '',
              },
              ...suppliers.map((supplier) => ({
                label: supplier.name,
                value: supplier.id,
              })),
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CustomFactorModal
