import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Col, Divider, Row, message } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import debounce from 'lodash/debounce'

import {
  Board as IBoard,
  Organization,
  PageFilter,
  ReportCategory,
  ReportToBoard,
} from '@cozero/models'

import CustomBoard from '@/organisms/CustomBoard'
import DashboardHeader from '@/organisms/DashboardHeader'

import FiltersDrawer, { PageType } from '@/molecules/FiltersDrawer'

import LoadingSpinner from '@/atoms/LoadingSpinner'

import { useAppContext } from '@/contexts/app'
import { useBoardsContext } from '@/contexts/boards'
import { useFiltersContext } from '@/contexts/filters'
import { useLogContext } from '@/contexts/log'
import useTerritories from '@/hooks/useTerritories'
import { useAppDispatch, useAppSelector } from '@/redux'
import { getFeaturesAllowed, selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import { useGetCategoriesQuery } from '@/redux/categories'
import { useGetActiveLocationsQuery } from '@/redux/locations'
import { useGetFullLogRangeQuery } from '@/redux/logs'
import { selectSteps, setSteps } from '@/redux/onboarding'
import { useGetOrganizationUsersFilteredByBusinessUnitQuery } from '@/redux/organizations'
import { config } from '@/utils/config'
import { createFilterOptions } from '@/utils/filters'

import ReportsModal from '../Share/Modals/ReportsModal'

import EmptyState, { EmptyStateStatus } from './EmptyState'
import classes from './Home.module.less'

enum FilterKey {
  CATEGORY = 'categoryId',
  LOCATION = 'locationId',
  BUSINESS_UNIT = 'businessUnit',
  RESPONSIBLE = 'ownerId',
}

function Home(): JSX.Element {
  const { t } = useTranslation('common')
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const steps = useAppSelector(selectSteps)
  const { loading, products, getProducts, suppliers, getSuppliers } = useAppContext()
  const { getTags, getCustomers, customers, tags } = useLogContext()
  const { filters, saveFilters } = useFiltersContext()
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    { businessUnitKey: selectedBusinessUnit?.key ?? ' ' },
    { skip: !selectedBusinessUnit?.key },
  )
  const { data: locations } = useGetActiveLocationsQuery(
    { businessUnitKey: selectedBusinessUnit?.key ?? '' },
    { skip: !selectedBusinessUnit },
  )
  const { data: logRange } = useGetFullLogRangeQuery()
  const { data: categories } = useGetCategoriesQuery()
  const { data: users } = useGetOrganizationUsersFilteredByBusinessUnitQuery(
    {
      businessUnit: selectedBusinessUnit?.key ?? '',
    },
    {
      skip: !selectedBusinessUnit?.key,
    },
  )
  const { territories } = useTerritories()
  const [filterOptions, setFilterOptions] = useState<PageFilter[]>(
    createFilterOptions({
      type: 'location',
      categories,
      users,
      businessUnits,
      locations,
      products,
      customers,
      suppliers,
      tags,
      t,
      featuresAllowed,
      territories,
      shownOptions: [
        'locations',
        'businessUnits',
        'owners',
        'categories',
        'descriptions',
        'customers',
        'suppliers',
        'tags',
        'territories',
      ],
      page: 'reports',
    }) ?? [],
  )

  const {
    loadingCalculations,
    boards,
    loadingMainBoard,
    loadingBoards,
    getBoards,
    getBoard,
    searchReportCategories,
    updateReportToBoard,
    editReportsOfBoard,
    selectedBoard,
    setSelectedBoard,
    getBoardData,
  } = useBoardsContext()
  const navigate = useNavigate()
  const [openReportMenu, setOpenReportMenu] = useState<boolean>(false)
  const [reportCategories, setReportCategories] = useState<ReportCategory[]>([])
  const [emptyStatus, setEmptyStatus] = useState<EmptyStateStatus>(EmptyStateStatus.NONE)
  const [drawerOpened, setDrawerOpened] = useState(false)

  const hasFinishedStep = useMemo(
    () =>
      steps?.length === 0 ||
      steps?.filter((step) => step.onboardingStep.key === 'logs' && step.completed)?.length === 1,
    [steps],
  )

  async function onSearch(filters: PageFilter[]): Promise<void> {
    saveFilters(filters)
  }

  useEffect(() => {
    setFilterOptions(
      createFilterOptions({
        type: 'location',
        categories,
        users,
        businessUnits,
        locations,
        products,
        tags,
        customers,
        suppliers,
        t,
        territories,
        featuresAllowed,
        shownOptions: [
          'locations',
          'businessUnits',
          'owners',
          'categories',
          'descriptions',
          'products',
          'customers',
          'suppliers',
          'tags',
          'territories',
        ],
        page: 'reports',
      }) ?? [],
    )
  }, [
    categories,
    users,
    businessUnits,
    locations,
    selectedBusinessUnit?.key,
    products,
    tags,
    customers,
    suppliers,
    territories,
  ])

  const updateBoard = (board: IBoard): void => {
    setSelectedBoard(board)
  }

  const editReports = useCallback(
    async (reports: Store, boardId: number): Promise<void> => {
      await editReportsOfBoard(reports, boardId)
      if (selectedBoard) {
        await getBoard({ boardId })
        await fetchBoardData(selectedBoard, filters)
      } else {
        message.error(t('share.reports.errors.add'))
      }
    },
    [selectedBoard, filters],
  )

  const editReportToBoard = async (
    board: IBoard,
    reportId: number,
    values: Partial<ReportToBoard>,
  ): Promise<void> => {
    if (board) {
      await updateReportToBoard(board, reportId, values)
      await fetchBoardData(board, filters)
    } else {
      message.error(t('share.reports.errors.no-board'))
    }
  }

  const fetchBoardData = useCallback(async (board: IBoard, filters?: PageFilter[]) => {
    const boardData = await getBoardData({
      board,
      user,
      filters,
    })
    if (boardData) {
      setSelectedBoard(boardData)
    }
  }, [])

  const fetchReportCategories = async (search: string): Promise<void> => {
    const data = await searchReportCategories(search)
    setReportCategories(data)
  }

  async function fetchData(): Promise<void> {
    await getProducts(false)
    await getSuppliers()
    await getTags()
    await getCustomers()
    await fetchReportCategories('')
  }

  useEffect(() => {
    const pricing = (user?.organization as Organization)?.pricing?.key
    if (pricing === 'supplier') {
      navigate(config.routes.log['customer-requests'])
    }
  }, [])

  useEffect(() => {
    if (steps && steps.length > 0 && !hasFinishedStep) {
      setEmptyStatus(EmptyStateStatus.NO_LOGS)
    } else if (
      !selectedBoard ||
      !selectedBoard.reportToBoards ||
      (selectedBoard.reportToBoards.length <= 0 && !loadingMainBoard)
    ) {
      setEmptyStatus(EmptyStateStatus.EMPTY_BOARD)
    } else {
      setEmptyStatus(EmptyStateStatus.NONE)
    }
  }, [steps, hasFinishedStep, selectedBoard, loadingMainBoard])

  useEffect(() => {
    if (user?.onboardingSteps) {
      dispatch(setSteps(user.onboardingSteps))
    }
  }, [user?.onboardingSteps])

  useEffect(() => {
    if (boards.length <= 0) {
      getBoards()
    }
  }, [boards])

  useEffect(() => {
    if (boards.length > 0) {
      const board = boards.find((board) => board.type === 'dashboard')
      if (board && selectedBusinessUnit) {
        getBoard({ boardId: board.id })
      }
    }
  }, [boards, selectedBusinessUnit?.key])

  useEffect(() => {
    if (selectedBusinessUnit?.key) {
      fetchData()
    }
  }, [selectedBusinessUnit?.key])

  return (
    <>
      <>
        <DashboardHeader
          disableAddReport={user?.role?.type === 'viewer'}
          filters={filters}
          selectedBoard={selectedBoard}
          loading={!reportCategories}
          openDrawer={() => setDrawerOpened(true)}
          openReportMenu={() => setOpenReportMenu(true)}
          onFilterSearch={onSearch}
          logRange={logRange}
          featuresAllowed={featuresAllowed}
        />
      </>
      <>
        {emptyStatus === EmptyStateStatus.NONE && selectedBoard?.type === 'dashboard' ? (
          <>
            <Divider type="horizontal" className={classes.divider} />
            <Row>
              <Col span={24}>
                <CustomBoard
                  updateBoard={updateBoard}
                  updateReportToBoard={editReportToBoard}
                  editable
                />
              </Col>
            </Row>

            <FiltersDrawer
              search={debounce(onSearch, 500)}
              pageType={PageType.LOCATION}
              filters={[...filters] as (PageFilter & { key: FilterKey })[]}
              visible={drawerOpened}
              filterOptions={
                [...filterOptions] as (PageFilter & {
                  options: {
                    key?: string
                    value: string
                    label: string
                  }[]
                })[]
              }
              onClose={() => setDrawerOpened(false)}
              featuresAllowed={featuresAllowed}
            />
          </>
        ) : (
          <>
            {loading || loadingCalculations || loadingBoards || loadingMainBoard ? (
              <LoadingSpinner
                className={classes.spinner}
                title={t('share.reports.loading-plural')}
              />
            ) : (
              <EmptyState
                status={emptyStatus}
                customOnClickAction={() => setOpenReportMenu(true)}
              />
            )}
          </>
        )}
        <ReportsModal
          open={openReportMenu}
          onOk={() => setOpenReportMenu(false)}
          onCancel={() => setOpenReportMenu(false)}
          editReportsOfBoard={editReports}
          reportCategories={reportCategories}
        />
      </>
    </>
  )
}

export default Home
