import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Col, Progress, Row } from 'antd/es'

import { CalendarOutlined } from '@ant-design/icons'
import moment from 'moment'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useAppSelector } from '@/redux'
import { selectUserOrganization } from '@/redux/auth'
import { COZERO_ORANGE_60 } from '@/styles/variables'
import { config } from '@/utils/config'

import classes from './ProgressCard.module.less'

const ProgressCard = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const organization = useAppSelector(selectUserOrganization)
  const trialdaysLeft = useMemo(() => {
    return moment(organization?.createdAt)
      .clone()
      .add(organization?.pricing?.daysOfTrialPeriod, 'days')
      .diff(moment(), 'days')
  }, [])

  const progressPercentage = useMemo(() => {
    if (organization?.pricing?.daysOfTrialPeriod) {
      return 100 - (trialdaysLeft / organization?.pricing?.daysOfTrialPeriod) * 100
    }
    return 0
  }, [organization])

  return (
    <Card shadow="none">
      <Row>
        <Col span={24}>
          <Title as="h5" size="sm" fontWeight="semibold">
            {t('onboarding.demo.upgrade.title', { planName: organization?.pricing?.name })}
          </Title>
        </Col>
      </Row>
      <Row gutter={[8, 16]} justify="space-between">
        <Col span={16}>
          <Progress
            percent={progressPercentage}
            showInfo={false}
            className={classes.progressBar}
            strokeColor={COZERO_ORANGE_60}
          />
          <Text size="xl" color="secondary" className={classes.progressLabel}>
            {t('onboarding.demo.upgrade.progress-days', { days: trialdaysLeft })}
          </Text>
        </Col>
        <Col>
          <Button
            type="secondary"
            category="onboarding"
            action={'upgrade'}
            onClick={() => navigate(config.routes.settings.subscription)}
            prefixIcon={<CalendarOutlined className={classes.icon} />}
          >
            {t('onboarding.demo.upgrade.btn-text')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default ProgressCard
