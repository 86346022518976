import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from 'antd/es/form/Form'

import _ from 'lodash'

import { ActivityDataSource, CalculationMethodWithIncludes } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Select, { SelectOptionsProperties } from '@/atoms/Select'

import useLog from '@/hooks/useLog'

import ActivityDataSourceLazyLoadedCascader from '../ActivityDataSourceLazyLoadedCascader'
import { CreateProductFormValues, ParsedProductFormValues } from '../FactorRequestForm/types'

interface AddProductProps {
  isVisible: boolean
  onClose: (activityDataSource?: ActivityDataSource) => void
  activityDataSources: ActivityDataSource[]
}

const AddProductModal = ({
  isVisible,
  onClose,
  activityDataSources,
}: AddProductProps): JSX.Element => {
  const { t } = useTranslation('common')
  const { getSubcategories, subcategories, getCalculationMethods, createScopedActivityDataSource } =
    useLog()
  const [form] = useForm()
  const [calculationMethods, setCalculationMethods] = useState<CalculationMethodWithIncludes[]>([])
  const [isFormValid, setIsFormValid] = useState(false)

  const parseFormValues = (values: CreateProductFormValues): ParsedProductFormValues => {
    return {
      ..._.cloneDeep(values),
      activityDataSourceId: values.activityDataSourceId.at(-1) as number,
    }
  }

  const onSubmit = async (values: CreateProductFormValues): Promise<void> => {
    const createdActivityDataSource = await createScopedActivityDataSource(parseFormValues(values))
    form.resetFields()
    onClose(createdActivityDataSource)
  }

  const fetchCalculationMethods = async (
    subcategoryId: number,
    activityDataSourceId: number,
  ): Promise<void> => {
    const apiCalculationMethods = await getCalculationMethods(subcategoryId, activityDataSourceId)
    if (apiCalculationMethods) {
      setCalculationMethods(apiCalculationMethods)
    }
  }

  const formValueChanges = (
    changedValues: Partial<CreateProductFormValues>,
    allValues: CreateProductFormValues,
  ): void => {
    const { activityDataSourceId, subcategoryId } = changedValues
    if (activityDataSourceId && activityDataSourceId.length) {
      getSubcategories(undefined, undefined, undefined, [
        activityDataSourceId[activityDataSourceId.length - 1],
      ])
      form.resetFields(['subcategoryId', 'calculationMethodId'])
    }

    if (subcategoryId && allValues.activityDataSourceId) {
      fetchCalculationMethods(
        subcategoryId,
        allValues.activityDataSourceId[allValues.activityDataSourceId.length - 1],
      )
      form.resetFields(['calculationMethodId'])
    }
    const formKeys = Object.keys(allValues)
    const areFieldsTouched = formKeys.every((key) => form.isFieldTouched(key))
    const isValid = areFieldsTouched && Object.values(allValues).every((value) => !!value)
    setIsFormValid(isValid)
  }

  const subcategoriesOptions = useCallback((): SelectOptionsProperties[] => {
    if (!subcategories || !subcategories.length) {
      return []
    }
    return subcategories.map(({ id: value, key: label }) => ({ value, label }))
  }, [subcategories])

  const calculationMethodsOptions = useCallback((): SelectOptionsProperties[] => {
    if (!calculationMethods.length) {
      return []
    }
    return calculationMethods.map(({ id: value, name: label }) => ({ value, label }))
  }, [calculationMethods])

  return (
    <Modal
      width={600}
      title={t('product.create-btn')}
      visible={isVisible}
      onOk={() => form.submit()}
      onCancel={() => onClose()}
      okText={t('actions.save')}
      okButtonProps={{ disabled: !isFormValid }}
    >
      <Form
        category="factor-requests"
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onValuesChange={formValueChanges}
      >
        <Form.Item
          label={t('product.lifecycle-steps.configuration.product-name')}
          name="name"
          rules={[{ required: true, message: t('product.validations.required-name') }]}
        >
          <InputField />
        </Form.Item>
        <Form.Item
          label={t('product.parent')}
          name="activityDataSourceId"
          rules={[{ required: true, message: t('product.validations.required-parent') }]}
        >
          <ActivityDataSourceLazyLoadedCascader
            activityDataSources={activityDataSources}
            placeholder={t('suppliers.factor.option-placeholder')}
          />
        </Form.Item>
        <Form.Item
          label={t('subcategories')}
          name="subcategoryId"
          rules={[{ required: true, message: t('product.validations.required-subcategory') }]}
        >
          <Select
            placeholder={t('suppliers.factor.option-placeholder')}
            options={subcategoriesOptions()}
            disabled={!form.getFieldValue('activityDataSourceId')}
          />
        </Form.Item>
        <Form.Item
          label={t('calculation-methods.all')}
          name="calculationMethodId"
          rules={[
            { required: true, message: t('product.validations.required-calculation-method') },
          ]}
        >
          <Select
            placeholder={t('calculation-methods.select')}
            options={calculationMethodsOptions()}
            disabled={!form.getFieldValue('subcategoryId')}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddProductModal
