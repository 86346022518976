import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { SLICE_NAME_FORECAST_CONFIG } from './constants'

type forecastConfigState = {
  forecastDrawerOpen: boolean
}

const initialState: forecastConfigState = {
  forecastDrawerOpen: false,
}

const forecastConfigSlice = createSlice({
  name: SLICE_NAME_FORECAST_CONFIG,
  initialState,
  reducers: {
    setForecastDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.forecastDrawerOpen = payload
    },
  },
})

export const { setForecastDrawerOpen } = forecastConfigSlice.actions
export default forecastConfigSlice
