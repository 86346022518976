import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'

import DatePicker from '@/atoms/DatePicker'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Label from '@/atoms/Label'
import Text from '@/atoms/Text'

import classes from './SupplierExtraInformation.module.less'

const SupplierExtraInformation = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Row gutter={[8, 24]} className={classes.container}>
      <Col span={24}>
        <Text fontWeight="semibold" mode="title" size="xs">
          {t('suppliers.extra-information')}
        </Text>
      </Col>
      <Col span={7}>
        <Form.Item label={<Label>{t('suppliers.factor.due_date')}</Label>} name="dueDate">
          <DatePicker
            format="YYYY-MM-DD"
            picker="date"
            placeholder={t('customer-requests.due-date-placeholder')}
            className={classes.datePicker}
          />
        </Form.Item>
      </Col>
      <Col offset={1} span={16}>
        <Form.Item label={<Label>{t('suppliers.additional-information')}</Label>} name="note">
          <InputField
            type="textarea"
            autoSize
            placeholder={t('suppliers.additional-information-placeholder')}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default SupplierExtraInformation
