import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ClimateAction } from '@cozero/models'

import { ClimateActionTab } from '@/organisms/ClimateActionsTable'

import { SLICE_NAME_CLIMATE_ACTIONS } from './constants'

type ClimateActionsState = {
  selectedActions: ClimateAction['id'][]
  selectedTab: ClimateActionTab
}

const initialState: ClimateActionsState = {
  selectedActions: [],
  selectedTab: 'active',
}

const climateActionsSlice = createSlice({
  name: SLICE_NAME_CLIMATE_ACTIONS,
  initialState,
  reducers: {
    selectClimateAction: (state, { payload }: PayloadAction<ClimateAction['id'][]>) => {
      state.selectedActions = payload
    },
    deselectClimateAction: (state, { payload }: PayloadAction<number>) => {
      state.selectedActions = state.selectedActions.filter((id) => id === payload)
    },
    selectClimateActionTab: (state, { payload }: PayloadAction<ClimateActionTab>) => {
      state.selectedTab = payload
    },
  },
})

export const { selectClimateAction, deselectClimateAction, selectClimateActionTab } =
  climateActionsSlice.actions
export default climateActionsSlice
