import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router'

import { Tabs } from 'antd/es'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { useGetProductQuery } from '@/redux/products'

import { LifecycleStepsID, LifecycleStepsKey } from '../../Organization/NewProduct/steps'

import ProductDetailsHeader from './ProductDetailsHeader'
import ProductLifecycleStepDetails from './ProductLifecycleStepDetails'
import ProductOverview from './ProductOverview'
import ProductQuantities from './ProductQuantities'

const { TabPane } = Tabs

function ProductDetails(): JSX.Element {
  const { t } = useTranslation('common')
  const { productId } = useParams()
  const { data: product } = useGetProductQuery(productId ?? skipToken)
  const location = useLocation()
  const topRef = useRef<HTMLDivElement>(null)

  const items = useMemo(() => {
    return [
      {
        key: LifecycleStepsKey.PRODUCT_CONFIGURATION,
        title: t('product.lifecycle-steps.configuration.overview'),
        component: (
          <>
            <ProductOverview />
          </>
        ),
        cy: 'footprint-overview',
        hidden: false,
      },
      {
        key: LifecycleStepsKey.PRODUCT_COMPOSITION,
        title: t('product.lifecycle-steps.composition.title'),
        component: (
          <>
            <ProductLifecycleStepDetails stepId={LifecycleStepsID['product-composition']} />
          </>
        ),
        cy: 'footprint-product-composition',
        hidden: true,
      },
      {
        key: LifecycleStepsKey.PRODUCTION,
        title: t('product.lifecycle-steps.production.title'),
        component: (
          <>
            <ProductLifecycleStepDetails stepId={LifecycleStepsID.production} />
          </>
        ),
        cy: 'footprint-production',
        hidden: true,
      },
      {
        key: LifecycleStepsKey.PACKAGING,
        title: t('product.lifecycle-steps.packaging.title'),
        component: (
          <>
            <ProductLifecycleStepDetails stepId={LifecycleStepsID.packaging} />
          </>
        ),
        cy: 'footprint-packaging',
        hidden: true,
      },
      {
        key: LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
        title: t('product.lifecycle-steps.distribution-and-storage.title'),
        component: (
          <>
            <ProductLifecycleStepDetails stepId={LifecycleStepsID['distribution-and-storage']} />
          </>
        ),
        cy: 'footprint-distribution-storage',
        hidden: true,
      },
      {
        key: LifecycleStepsKey.USAGE,
        title: t('product.lifecycle-steps.usage.title'),
        component: (
          <>
            <ProductLifecycleStepDetails stepId={LifecycleStepsID.usage} />
          </>
        ),
        cy: 'footprint-usage',
        hidden: true,
      },
      {
        key: LifecycleStepsKey.END_OF_LIFE,
        title: t('product.lifecycle-steps.end-of-life.title'),
        component: (
          <>
            <ProductLifecycleStepDetails stepId={LifecycleStepsID['end-of-life']} />
          </>
        ),
        cy: 'footprint-end-of-life',
        hidden: true,
      },
      {
        key: LifecycleStepsKey.QUANTITIES,
        title: t('product.lifecycle-steps.quantity.title'),
        component: (
          <>
            <ProductQuantities />
          </>
        ),
        cy: 'footprint-quantity',
        hidden: false,
      },
    ]
  }, [product])

  const TabsItems = useMemo(() => {
    return items
      .filter(({ key, hidden }) => product?.selectedSteps.includes(key) || !hidden)
      .map(({ title, cy, component }, i) => (
        <TabPane key={i} tab={<span data-cy={cy}>{title}</span>}>
          {component}
        </TabPane>
      ))
  }, [items, product])

  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }, [location])

  return (
    <div ref={topRef}>
      <ProductDetailsHeader />
      <Tabs>{TabsItems}</Tabs>
    </div>
  )
}

export default ProductDetails
