import React, { ReactElement, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { NavLink } from 'react-router-dom'

import Pill from '@/atoms/Pill'

import classes from './NavMenuItem.module.less'

interface NavMenuItemProps {
  title: string
  icon?: ReactElement
  route?: string
  showFocusDot?: boolean
  isChild?: boolean
  isParent?: boolean
  active?: boolean
  newFeature?: boolean
}

const NavMenuItem = ({
  title,
  icon,
  route,
  isChild,
  isParent,
  active,
  showFocusDot,
  newFeature,
  ...rest
}: NavMenuItemProps): JSX.Element => {
  const { t } = useTranslation()

  const classNames = useMemo(
    () =>
      [
        classes.menuItem,
        route || isParent ? '' : classes.disabled,
        isChild ? classes.isChild : '',
        isParent ? classes.isParent : '',
      ].join(' '),
    [route, isChild, isParent],
  )

  if (route) {
    return (
      <NavLink
        to={route}
        className={({ isActive }) => `${classNames} ${isActive && classes.active}`}
        {...rest}
      >
        <div className={classes.innerWrapper}>
          {icon && <div className={classes.icon}>{icon}</div>}
          <span className={classes.title}>{title}</span>
          {showFocusDot && (
            <div className={classes.focusDot}>
              <div className={classes.dot} />
            </div>
          )}
          {newFeature && <Pill>New</Pill>}
        </div>
      </NavLink>
    )
  }
  return (
    <div className={classNames} {...rest}>
      <div className={classes.innerWrapper}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <span className={classes.title}>{title}</span>
        {!isParent && (
          <Pill size="sm" className={classes.badge}>
            {t('coming-soon')}
          </Pill>
        )}
      </div>
      {isParent && (
        <div className={classes.chevron}>{active ? <HiChevronUp /> : <HiChevronDown />}</div>
      )}
    </div>
  )
}

export type { NavMenuItemProps }
export default memo(NavMenuItem)
