import React, { ReactElement, ReactNode, memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineLightBulb } from 'react-icons/hi'

import { kebabCase } from 'lodash-es'

import NavMenuItem from '@/molecules/NavMenuItem'
import NavSubMenu from '@/molecules/NavSubMenu'

import Divider from '@/atoms/Divider'

import i18n from '@/i18n'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getIsManagerOrAdmin, selectUser } from '@/redux/auth'
import { config } from '@/utils/config'

import { selectIsFinished } from '../../redux/onboarding'
import NavCompanyHeader from '../NavCompanyHeader'
import NavUserMenu from '../NavUserMenu'

import { getMenuItems } from './MENU_ITEMS'
import classes from './NavSidebar.module.less'

const NavSidebar = ({
  trialNotification,
  supplierOnboarding,
  hideUserMenu,
}: {
  trialNotification: ReactNode
  supplierOnboarding?: boolean
  hideUserMenu?: boolean
}): ReactElement => {
  const { t } = useTranslation()
  const isFinished = useAppSelector(selectIsFinished)
  const user = useAppSelector(selectUser)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const MENU_ITEMS = useMemo(() => getMenuItems(t), [i18n.language])
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null)

  const MenuItems = useMemo(
    (): ReactNode =>
      Object.entries(MENU_ITEMS).map(([itemKey, item]) => {
        if (
          featuresAllowed?.includes(item?.feature) &&
          !item.excludeRoles?.includes(user?.role?.type || '')
        ) {
          if (item.children) {
            return (
              <NavSubMenu
                key={item.key}
                base={item.key}
                title={item.title}
                icon={item.icon}
                menuOpen={openSubMenu === item.key}
                onOpenHandler={(base: string | null) => setOpenSubMenu(base)}
                items={Object.values(item.children)}
              />
            )
          }
          return (
            <NavMenuItem
              key={item.route ?? itemKey}
              title={item.title}
              route={item.route}
              icon={item.icon}
              data-cy={kebabCase(item.title)}
            />
          )
        }
      }),
    [openSubMenu, MENU_ITEMS],
  )

  return (
    <aside className={classes.sider}>
      <div className={classes.siderInnerContainer}>
        <NavCompanyHeader />
        <div className={classes.siderOnboardingSection}>
          <NavMenuItem
            title={t('onboarding.demo.get-started')}
            showFocusDot={!isFinished}
            route={
              supplierOnboarding ? config.routes.onboardingSupplierStep2 : config.routes.onboarding
            }
            icon={<HiOutlineLightBulb />}
          />
          <Divider />
        </div>
        <nav>{MenuItems}</nav>
      </div>
      <div>
        {isManagerOrAdmin && <div>{trialNotification}</div>}
        {!hideUserMenu && <NavUserMenu />}
      </div>
    </aside>
  )
}

export default memo(NavSidebar)
