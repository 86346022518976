import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { Store, combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

// defaults to localStorage for web
import apiSlice from './api'
import authSlice from './auth'
import basketSlice from './basket'
import businessUnitSlice from './businessUnits'
import climateActionsSlice from './climateActions'
import factorRequestSlice from './factors-requests'
import filterSlice from './filters'
import forecastConfigSlice from './forecastConfig'
import onboardingSlice from './onboarding'
import targetsSlice from './targets'

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [basketSlice.name]: basketSlice.reducer,
  [businessUnitSlice.name]: businessUnitSlice.reducer,
  [targetsSlice.name]: targetsSlice.reducer,
  [climateActionsSlice.name]: climateActionsSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
  [forecastConfigSlice.name]: forecastConfigSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
  [factorRequestSlice.name]: factorRequestSlice.reducer,
})

export const REDUX_STORAGE_KEY = 'COZERO_APP_SETTINGS'

// Configure the persistence of our reducers
const _persistedReducer = persistReducer(
  {
    key: REDUX_STORAGE_KEY,
    keyPrefix: '',
    storage,
    whitelist: [
      businessUnitSlice.name,
      authSlice.name,
      climateActionsSlice.name,
      onboardingSlice.name,
    ],
  },
  rootReducer,
)

// Configure our store
const store: Store<RootState> = configureStore({
  // Pass our reducer object
  reducer: _persistedReducer,
  // Add all the regular middleware needed for our store, as
  // Thunk, Immutability check middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // As we are passing around some pretty big objects,
      // which will make this check take >30ms, redux will throw a warning
      // It catches non-serializable values, and is not turned on in production
      serializableCheck: false,
    }).concat(apiSlice.middleware),
})

// A type  store
type AppDispatch = typeof store.dispatch
// Declare the RootState` types from the store itself
type RootState = ReturnType<typeof rootReducer>

// A typesafe version of `useDispatch`
const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types

// A typesafe version of useSelector
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const persistedStore = persistStore(store)

export type { AppDispatch, RootState }
export { persistedStore, useAppSelector, useAppDispatch }
export default store
