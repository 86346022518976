import { useState } from 'react'

import { cloneDeep, omit } from 'lodash-es'

import { ConfigurationView, LogSorter, PageFilter } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { FiltersContextInterface } from '../contexts/filters'
import { SearchFilters } from '../types/general'
import axios from '../utils/axios'

const defaultFilters: PageFilter[] = []
const defaultSorters: LogSorter[] = [
  {
    id: '3uzmyzm291',
    key: 'createdAt',
    label: 'createdAt',
    selectedSort: 'desc',
    logType: 'location',
  },
  {
    id: '3uzmyzm294',
    key: 'id',
    label: 'id',
    selectedSort: 'desc',
    logType: 'product',
  },
]

function createDefaultFilters(initialState: SearchFilters): SearchFilters {
  const searchState = cloneDeep(initialState || {})
  if (!searchState.filters?.length) {
    searchState.filters = defaultFilters
  }
  if (!searchState.sorters?.length) {
    searchState.sorters = defaultSorters
  }

  return searchState
}

const useFiltersContext = (initialState: SearchFilters): FiltersContextInterface => {
  const [search, setSearchState] = useState<SearchFilters>(createDefaultFilters(initialState))

  const saveSearch = (newSearch: SearchFilters): void => {
    setSearchState(newSearch)
  }

  const savePageNumber = (pageNumber: number): void => {
    saveSearch({ ...search, pageNumber })
  }

  const saveFilters = (filters: PageFilter[]): void => {
    saveSearch({
      ...search,
      filters: filters?.map(
        (x) =>
          ({
            ...omit(x, ['options', 'conditions']),
            options: [],
            conditions: [],
          } as PageFilter),
      ),
      pageNumber: 1,
    })
  }

  const saveSorters = (sorters: LogSorter[]): void => {
    saveSearch({ ...search, sorters, pageNumber: 1 })
  }

  const resetSearch = (): void => {
    saveSearch({ sorters: [], filters: [], pageNumber: 1 })
  }

  const saveConfig = ({
    filters,
    sorters,
  }: {
    filters?: PageFilter[]
    sorters?: LogSorter[]
  }): void => {
    saveSearch({ ...search, filters, sorters })
  }

  const saveAsView = async (view: Partial<ConfigurationView>): Promise<ConfigurationView> => {
    try {
      const { data } = await axios.post<ConfigurationView>(
        centralApiGatewayClient.configViews.CREATE,
        view,
      )
      return data
    } catch (error) {
      throw new Error(error || 'Failed to save view')
    }
  }

  const deleteView = async (id: number): Promise<void> => {
    try {
      await axios.delete(
        centralApiGatewayClient.configViews.DELETE_ONE.replace(':id', id.toString()),
      )
    } catch (error) {
      throw new Error(error || 'Failed to delete view')
    }
  }
  const updateView = async (
    view: Partial<ConfigurationView>,
  ): Promise<ConfigurationView | void> => {
    try {
      if (view.id) {
        const { data } = await axios.put<ConfigurationView>(
          centralApiGatewayClient.configViews.UPDATE_ONE.replace(':id', view.id.toString()),
          view,
        )
        return data
      }
    } catch (error) {
      throw new Error(error || 'Failed to delete view')
    }
  }

  return {
    filters: search.filters || [],
    sorters: search.sorters || [],
    pageNumber: search.pageNumber,
    saveFilters,
    saveSorters,
    savePageNumber,
    resetSearch,
    saveAsView,
    deleteView,
    updateView,
    saveConfig,
  }
}

export default useFiltersContext
