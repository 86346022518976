import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { config } from './config'

if (config.VITE_SENTRY_DSN && ['staging', 'production'].includes(config.VITE_NODE_ENV)) {
  Sentry.init({
    environment: config.VITE_NODE_ENV,
    dsn: config.VITE_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
  })
}

export { Sentry }
