export enum CalculationMethodType {
  //deprecated
  Consumption = 'consumption',
  ConsumptionGridMix = 'consumption_grid_mix',
  ConsumptionFugitives = 'consumption_fugitives',
  ConsumptionHomeOffice = 'consumption_home_office',
  Direct = 'direct',
  Distance = 'distance',
  DistanceRouting = 'distance_routing',
  DistanceLogisticsRouting = 'distance_logistics_routing',
  DistanceLogistics = 'distance_logistics',
  DistancePerJourney = 'distance_per_journey',
  DistancePerJourneyRouting = 'distance_per_journey_routing',
  GridMix = 'grid_mix',
  Journeys = 'number_of_journeys',
  Flights = 'number_of_flights',
  LogisticsArea = 'logistics_area',
  MarketingSize = 'marketing_size',
  MarketingPaper = 'marketing_paper',
  MarketingTime = 'marketing_time',
  QuantityOfGoods = 'quantity_of_goods',
  QuantityOfGoodsCapitalGoods = 'quantity_of_goods_capital_goods',
  QuantityOfGoodsTransported = 'quantity_of_goods_transported',
  QuantityOfGoodsRouting = 'quantity_of_goods_transported_routing',
  DigitalProductWebsiteSize = 'products_digital_website_size',
  DigitalProductVideoSize = 'products_digital_video_size',
  MediaAudiovisual = 'media_audiovisual',
  DoohArea = 'dooh_area',
  DoohDimensions = 'dooh_dimensions',
  PaperDimensions = 'paper_dimensions',
  PaperStandardSize = 'paper_standard_size',
  PaperWeight = 'paper_weight',
  Spend = 'spend',
  Duration = 'duration',
  UsesProduct = 'uses_product',
  VehicleUse = 'vehicle_use',
  PercentageProductQuantity = 'percentage_product_quantity',
  IroningClothes = 'ironing_clothes',
  WarehouseStorage = 'warehouse_storage',
  CleaningClothes = 'cleaning_clothes',
  DryingClothes = 'drying_clothes',
  ConsumerTransport = 'consumer_transport',
  RetailStorage = 'retail_storage',
}

export enum StandardUnit {
  CO2Tonnes = 'CO2e tonne',
  Tonnes = 'tonne',
  Kilometers = 'km',
  Days = 'day',
  Years = 'year',
  Kilograms = 'kg',
  TonneKm = 'tonne km',
  KilowattHours = 'kWh',
  Hours = 'hour',
  Journeys = 'journey',
  Units = 'unit',
  EUR = 'EUR',
  Meters = 'm',
  CubicMeters = 'm3',
  SquaredMeters = 'm2',
  Seconds = 'second',
  Months = 'month',
  Times = 'time',
  Percentage = '%',
  Density = 't/m2',
}

export enum Scope {
  FuelAndEnergy = 3,
  Direct = 16,
  OutOfScope = 18,
}

export enum ActivityDataSource {
  defaultOther = 'default-other',
}

export enum Countries {
  Germany = 'DEU',
  France = 'FRA',
  UnitedKingdom = 'GBR',
  USA = 'USA',
}

export enum Continents {
  Global = 'GLO',
  Europe = 'EEU',
  Asia = 'ABB',
  Africa = 'FFF',
  NorthAmerica = 'NNN',
  SouthAmerica = 'SRR',
  Oceania = 'UUU',
}

export enum Origins {
  Average = 'calculated-average',
}

export enum ClosedPeriodStatus {
  DRAFT = 'draft',
  CLOSED = 'closed',
}

export enum CalculationExternalValues {
  FILE_SIZE_FOR_BUFFERING = 5.625,
  INK_PRINTING = 0.001892,
}
