import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Image, Space, Tag, Tooltip, Typography } from 'antd/es'
import { SortOrder } from 'antd/lib/table/interface'

import moment from 'moment'

import { Log, LogSorter, LogType, TableViewLog, User } from '@cozero/models'

import Avatar from '@/atoms/Avatar'
import Text from '@/atoms/Text'

import useGenerateData, { GenerateColumn } from '@/hooks/useGenerateData'
import { formatNumber } from '@/utils/number'
import { truncate } from '@/utils/string'

import classes from './LogsTable.module.less'

const useLogsTableColumns = ({
  tableSorter,
  type,
  businessUnitsAllowed,
}: {
  tableSorter: LogSorter[] | undefined
  type: LogType
  businessUnitsAllowed: boolean
}): GenerateColumn<Log>[] => {
  const { t } = useTranslation('common')
  const { getInitialOrder } = useGenerateData()

  const columnsData: GenerateColumn<Log>[] = useMemo(
    () =>
      [
        {
          title: t('log.module'),
          dataIndex: 'module',
          key: 'category',
          width: 300,
          sorter: false,
          sortOrder: getInitialOrder('category', tableSorter),
          render(_, record) {
            return (
              <span className={classes.row}>
                {record.category.image?.url && (
                  <Image
                    width={30}
                    height={30}
                    src={record.category.image?.url}
                    preview={false}
                    className={classes.icon}
                  />
                )}
                <Text size="xl" className={classes.subcategoryName}>
                  {record.category.name as string}
                </Text>
              </span>
            )
          },
        },
        {
          title: t('log.description'),
          dataIndex: 'description',
          key: 'description',
          sorter: true,
          sortOrder: getInitialOrder('description', tableSorter),
          width: 200,
        },
        ...(type === 'location'
          ? ([
              {
                title: t('log.location'),
                dataIndex: ['location', 'name'],
                key: 'location',
                width: 200,
                render(_, record) {
                  const tags = [
                    <Tag
                      key={record.location?.name}
                      className={classes.tag}
                      title={record.location?.name}
                    >
                      {truncate(record.location?.name || '', 25)}
                    </Tag>,
                  ]

                  if (record.location?.tags?.length) {
                    record.location.tags.forEach((tag) =>
                      tags.push(
                        <Tag key={tag} className={classes.tag} title={tag}>
                          {truncate(tag, 25)}
                        </Tag>,
                      ),
                    )
                  }
                  return <>{tags}</>
                },
              },
              businessUnitsAllowed
                ? {
                    title: t('log.business-unit'),
                    dataIndex: ['location', 'businessUnit', 'title'],
                    key: 'businessUnit',
                    width: 200,
                    sorter: true,
                    sortOrder: getInitialOrder('businessUnit', tableSorter),
                    render(_, record) {
                      const tags = [
                        <Tag
                          key={record.businessUnit?.title}
                          className={classes.tag}
                          title={record.businessUnit?.title}
                        >
                          {truncate(record.businessUnit?.title ?? '', 25)}
                        </Tag>,
                      ]

                      if (record.businessUnit?.tags?.length) {
                        record.businessUnit.tags.forEach((tag) =>
                          tags.push(
                            <Tag key={tag} className={classes.tag} title={tag}>
                              {truncate(tag, 25)}
                            </Tag>,
                          ),
                        )
                      }
                      return <>{tags}</>
                    },
                  }
                : null,
              {
                title: t('log.period'),
                dataIndex: 'period',
                key: 'endDate',
                sorter: true,
                sortOrder: getInitialOrder('endDate', tableSorter),
                width: 320,
                render(_: string, record) {
                  return (
                    <Space className={classes.row}>
                      <Tag className={classes.tag}>
                        {moment(record.startDate).utc().format('LL')}
                      </Tag>
                      <Typography className={classes.periodDivisor}>{t('to')}</Typography>
                      <Tag className={classes.tag}>{moment(record.endDate).utc().format('LL')}</Tag>
                    </Space>
                  )
                },
              },
              {
                title: t('log.files.count'),
                dataIndex: 'files',
                key: 'files',
                width: 100,
                sorter: true,
                sortOrder: getInitialOrder('files', tableSorter),
                render(_: string, record: Log) {
                  return <>{record.files || 0}</>
                },
              },
            ] as GenerateColumn<TableViewLog>[])
          : ([
              {
                title: t('log.product'),
                dataIndex: ['product', 'name'],
                key: 'product',
                width: 200,
                sorter: (a, b) => a.product?.name?.localeCompare(b.product?.name || ''),
                sortDirections: ['ascend', 'descend'] as SortOrder[],
                render(_, record) {
                  const tags = [
                    <Tag
                      key={record.product?.name}
                      className={classes.tag}
                      title={record.product?.name}
                    >
                      {truncate(record.product?.name || '', 25)}
                    </Tag>,
                  ]

                  if (record.product?.tags?.length) {
                    record.product.tags.forEach((tag) =>
                      tags.push(
                        <Tag key={tag} className={classes.tag} title={tag}>
                          {truncate(tag, 25)}
                        </Tag>,
                      ),
                    )
                  }
                  return <>{tags}</>
                },
              },
              businessUnitsAllowed
                ? {
                    title: t('log.business-unit'),
                    dataIndex: ['product', 'businessUnit', 'title'],
                    key: 'businessUnit',
                    sorter: true,
                    sortOrder: getInitialOrder('businessUnit', tableSorter),
                    width: 200,
                    render(_, record) {
                      return <Tag className={classes.tag}>{record.businessUnit?.title}</Tag>
                    },
                  }
                : null,
            ] as GenerateColumn<TableViewLog>[] | null[])),
        {
          title: t('log.owner'),
          dataIndex: ['owner', 'email'],
          key: 'owner',
          width: 200,
          sorter: true,
          sortOrder: getInitialOrder('owner', tableSorter),

          render(_, record) {
            const user = record.owner as User
            if (!user) {
              return <Typography>N/A</Typography>
            }
            return (
              <Space className={classes.row} align="center" direction="horizontal">
                <Avatar
                  firstName={user.firstName}
                  lastName={user.lastName}
                  image={user.photo}
                  className={classes.userPhoto}
                  showTooltip={false}
                />
                <Typography>{user?.firstName || user?.email}</Typography>
              </Space>
            )
          },
        },
        {
          title: t('log.created_at'),
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: 300,
          sorter: true,
          sortOrder: getInitialOrder('createdAt', tableSorter),
          render(_, record) {
            return <Tag className={classes.tag}>{moment(record.createdAt).format('LLL')}</Tag>
          },
        },
        type === 'product'
          ? {
              title: t('log.calculation-unit'),
              dataIndex: 'total',
              key: 'total',
              fixed: 'right',
              width: 120,
              sorter: true,
              sortOrder: getInitialOrder('total', tableSorter),
              render(_, record) {
                if (record.totalCarbon !== 0 && !record.totalCarbon) {
                  return t('log.calculating')
                }
                const value = formatNumber(record.totalCarbon)
                if (value === '< 0.01') {
                  return (
                    <Tooltip title={record.totalCarbon}>
                      <span className={classes.calculationValue}>{value}</span>
                    </Tooltip>
                  )
                }
                return <span className={classes.calculationValue}>{value}</span>
              },
            }
          : {
              title: t('log.calculationTotal'),
              dataIndex: 'total',
              key: 'total',
              fixed: 'right',
              width: 130,
              sorter: true,
              sortOrder: getInitialOrder('total', tableSorter),
              render(_, record) {
                const num = record?.totalCarbon ?? 0
                if (record.calculating) {
                  return t('log.calculating')
                }
                const value = formatNumber(num)
                if (value === '< 0.01') {
                  return (
                    <Tooltip title={num}>
                      <span className={classes.calculationValue}>{value}</span>
                    </Tooltip>
                  )
                }
                return <span className={classes.calculationValue}>{value}</span>
              },
            },
      ] as GenerateColumn<Log>[],
    [JSON.stringify(tableSorter)],
  )

  return columnsData
}

export default useLogsTableColumns
