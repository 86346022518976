import React, { useContext } from 'react'
import { Layout } from 'react-grid-layout'

import { Store } from 'antd/es/form/interface'

import {
  Board,
  IGraphData,
  PageFilter,
  Product,
  Report,
  ReportCategory,
  ReportSetting,
  ReportToBoard,
  User,
} from '@cozero/models'

import useBoardsHandler from '../hooks/useBoards'

export interface BoardsContextInterface {
  error: string
  loading: boolean
  loadingBoards: boolean
  loadingMainBoard: boolean
  boards: Board[]
  getBoards: () => Promise<void>
  getBoard: ({ boardId }: { boardId: number }) => Promise<void>
  getAvailableReports: () => Promise<void>
  setSelectedBoard: React.Dispatch<React.SetStateAction<Board | undefined>>
  selectedBoard?: Board
  getBoardData: ({
    board,
    filters,
    user,
    sharePageId,
    closedPeriodId,
  }: {
    board?: Board
    filters?: PageFilter[]
    user?: User
    sharePageId?: number
    closedPeriodId?: number
  }) => Promise<Board | undefined>
  selectedProduct?: Product
  setSelectedProduct: (product?: Product) => void
  setLoading: (loading: boolean) => void
  setLoadingCalculations: (loading: boolean) => void
  loadingCalculations: boolean
  reports?: Report[]
  updateReportToBoard: (
    board: Board,
    id: Report['id'],
    newValues: Partial<ReportToBoard>,
  ) => Promise<void>
  editReportsOfBoard: (
    reports: Store,
    boardId: number,
    sharePageId?: number,
    closedPeriodId?: number,
  ) => Promise<void>
  removeReportFromSelectedBoard: (
    id: number,
    userId?: number,
    sharePageId?: number,
  ) => Promise<void>
  editingBoard: boolean
  setEditingBoard: React.Dispatch<React.SetStateAction<boolean>>
  clearUpdatedLayout: () => void
  updateBoardLayout: (board: Board, layout: Layout[], sharePageId?: number) => void
  updateBoardWidth: (board: Board, boardId: number) => void
  reportSettings: ReportSetting[]
  setReportSettings: (reportSettings: ReportSetting[]) => void
  getGraphData: (
    graph: { key?: string; id?: number },
    filters?: PageFilter[],
    forecastConfigId?: number,
  ) => Promise<IGraphData | undefined>
  searchReportCategories: (searchText: string) => Promise<ReportCategory[]>
  getReportWithData: (
    key: string,
    filters?: PageFilter[],
    setting?: ReportSetting,
    abortSignal?: AbortSignal,
  ) => Promise<Report | undefined>
  lastDeletedReportId: number | undefined
}

export const boardsContext = React.createContext<BoardsContextInterface | undefined>(undefined)

const { Provider } = boardsContext

export function useBoardsContext(): BoardsContextInterface {
  const contextValue = useContext(boardsContext)
  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
}

const BoardsProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const boardAccessors = useBoardsHandler()
  return <Provider value={boardAccessors}>{children}</Provider>
}

export default BoardsProvider
