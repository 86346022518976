import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Col, Row, Space } from 'antd'
import { Form } from 'antd/es'
import { useForm } from 'antd/es/form/Form'

import _ from 'lodash'
import moment from 'moment'

import { FactorRequest } from '@cozero/models'

import CompleteProductModal from '@/organisms/CompleteProductModal'
import SupplierShareModal from '@/organisms/SupplierShareModal'

import Avatar from '@/atoms/Avatar'

import placeholderImage from '@/assets/placeholder-image.png'
import { useFactorContext } from '@/contexts/factor'
import { useSubscriptionContext } from '@/contexts/subscription'
import useAppContext from '@/hooks/useApp'
import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnitKey } from '@/redux/businessUnits'
import { useFindFactorRequestSupplierMutation } from '@/redux/factors-requests/api'
import { setFactorRequest as setFactor } from '@/redux/factors-requests/slice'
import { useGetProductsQuery } from '@/redux/products'

import completeIcon from '../../../assets/circle-complete.svg'
import Alert from '../../../atoms/Alert'
import Button from '../../../atoms/Button'
import InputField from '../../../atoms/InputField'
import Tag from '../../../atoms/Tag'
import Text from '../../../atoms/Text'
import { selectUser, selectUserOrganization } from '../../../redux/auth'
import { config } from '../../../utils/config'

import ActionCard from './ActionCard'
import FaqSection from './FaqSection'
import classes from './SupplierOnboarding.module.less'

const SupplierOnboarding = ({ step }: { step: 'STEP_1' | 'STEP_2' }): JSX.Element => {
  const { t } = useTranslation('common')
  const [form] = useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [factorRequest, setFactorRequest] = useState<FactorRequest>()
  const [endedOnboarding, setEndedOnboarding] = useState<boolean>(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showProductCreatedModal, setShowProductCreatedModal] = useState(false)
  const [findSupplierFactorRequest] = useFindFactorRequestSupplierMutation()
  const businessUnitKey = useAppSelector(selectSelectedBusinessUnitKey)
  const organization = useAppSelector(selectUserOrganization)
  const { data: products } = useGetProductsQuery({
    businessUnitKey: businessUnitKey?.toString() as string,
  })

  const user = useSelector(selectUser)
  const location = useLocation()
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { getProductsCount } = useAppContext()

  const { saveFactorRequest } = useFactorContext()

  const onSubmit = async (): Promise<void> => {
    const value = form.getFieldValue('value')
    if (factorRequest && value) {
      const data = await saveFactorRequest(factorRequest.id, {
        ...factorRequest,
        value: parseFloat(`${value}`),
        status: 'SUBMITTED',
      })
      if (data) {
        setEndedOnboarding(true)
        setShowShareModal(true)
      }
    }
  }

  useEffect(() => {
    const fetchFactorRequest = async (): Promise<void> => {
      if (user?.email) {
        const request = (await findSupplierFactorRequest(user?.email)) as {
          data: FactorRequest[]
        }
        dispatch(setFactor(request.data))
        form.setFieldValue('unit', request.data?.at(0)?.unit?.name?.toString())
        setFactorRequest(request.data?.at(0))
      }
    }
    fetchFactorRequest()
  }, [user])

  useEffect(() => {
    if (location.state && (location.state as { showCompleteModal: boolean }).showCompleteModal) {
      window.history.replaceState({}, document.title)
      setShowProductCreatedModal(
        (location.state as { showCompleteModal: boolean }).showCompleteModal,
      )
    }
  }, [location.state])

  const reasons = useMemo(
    () => [
      {
        title: t('onboarding.suppliers.reasons.0.title'),
        subtitle: t('onboarding.suppliers.reasons.0.subtitle'),
      },
      {
        title: t('onboarding.suppliers.reasons.1.title'),
        subtitle: t('onboarding.suppliers.reasons.1.subtitle'),
      },
      {
        title: t('onboarding.suppliers.reasons.2.title'),
        subtitle: t('onboarding.suppliers.reasons.2.subtitle'),
      },
    ],
    [t],
  )

  const closeModal = (): void => {
    setShowShareModal(false)
  }

  const welcomeTitle = (firstName?: string | null): string => {
    if (firstName) {
      return `${firstName}, ${t('onboarding.suppliers.welcome-message')}`
    }
    return _.capitalize(t('onboarding.suppliers.welcome-message'))
  }

  const goToCreatePage = async (): Promise<void> => {
    const navigationState = {
      from: `${location.pathname}${location.search}`,
      selectedSteps: factorRequest?.productLifecycleSteps?.map(
        (productLifecycleStep) => productLifecycleStep.key,
      ),
      unitId: factorRequest?.unit.id,
      productName: factorRequest?.activityDataSource?.name,
    }
    if (products?.length) {
      navigate(
        config.routes.log.carbonFootprint.productsUpsert.stepsEdit.replace(
          ':id',
          products.at(0)?.id?.toString() ?? '',
        ),
        {
          state: { ...navigationState, from: `${location.pathname}${location.search}` },
        },
      )
    } else {
      const limit = getLimit(organization, 'products')
      if (limit) {
        const productsCount = await getProductsCount()
        if (productsCount && productsCount >= limit.max) {
          setSubscribeModalSettings({
            closable: true,
            title: t('subscription.upgrade-modal.title-limit', {
              limit: limit.max,
              item: t('products.title'),
            }),
            visible: true,
          })
          return
        }
      }
      navigate(config.routes.log.carbonFootprint.productsUpsert.stepsCreate, {
        state: navigationState,
      })
    }
  }

  return (
    <div className={classes.mainWrapper}>
      <Row gutter={[0, 10]}>
        <Col md={24}>
          <Text mode="title" size="xs" fontWeight="medium">
            {welcomeTitle(user?.firstName)}
          </Text>
        </Col>
        <Col md={24}>
          <Text className={classes.suppliersTitle} mode="title" size="sm" fontWeight="medium">
            <span>{t('onboarding.suppliers.titlePt1')}</span>
            <span className={classes.var}>{` ${
              factorRequest?.requester?.firstName
                ? factorRequest?.requester?.firstName
                : factorRequest?.requester.email
            } `}</span>
            <span>{t('onboarding.suppliers.titlePt2')}</span>
            <span className={classes.var}>{` ${factorRequest?.organization.name} `}</span>
            <span>{t('onboarding.suppliers.titlePt3')}</span>
          </Text>
        </Col>
        <Col md={24}>
          <Alert
            type="default"
            title={t('onboarding.suppliers.alert.title')}
            message={t('onboarding.suppliers.alert.text', {
              organization: factorRequest?.organization.name,
            })}
          />
        </Col>
      </Row>

      <div className={classes.container}>
        <Row gutter={[24, 14]}>
          <Col span={24}>
            <Row className={classes.reasonsSection}>
              <Col md={24}>
                <Text mode="title" size="xs" fontWeight="medium">
                  {t('onboarding.suppliers.reasons.title')}
                </Text>
              </Col>
              {reasons.map((step) => (
                <Col lg={8} md={24} key={step.title}>
                  <Space className={classes.stepWrapper}>
                    <img src={completeIcon} alt="alt" />
                    <div className={classes.stepTitleWrapper}>
                      <Text mode="title" size="xs" fontWeight="medium" color="default">
                        {step.title}
                      </Text>
                      <Text size="xl" color="secondary">
                        {step.subtitle}
                      </Text>
                    </div>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={24}>
                <ActionCard
                  disabled={step !== 'STEP_1' || endedOnboarding}
                  title={t('onboarding.suppliers.steps.request.title')}
                  text={t('onboarding.suppliers.steps.request.text')}
                  status={
                    endedOnboarding
                      ? t('onboarding.suppliers.done')
                      : step !== 'STEP_1'
                      ? t('onboarding.suppliers.in-progress')
                      : t('onboarding.suppliers.todo')
                  }
                  stepNumber={1}
                >
                  <>
                    <Row className={classes.generalInfo}>
                      <Col offset={1} span={7}>
                        <Row className={classes.propertyWrapper}>
                          <Col span={6}>
                            <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                              {t('onboarding.suppliers.steps.request.fields.product')}
                            </Text>
                          </Col>
                          <Col offset={1} span={17}>
                            <Tag
                              truncateText
                              size="sm"
                              style={
                                factorRequest?.activityDataSource?.name ? { color: '#3E4051' } : {}
                              }
                              type={factorRequest?.activityDataSource?.name ? 'info' : 'danger'}
                            >
                              {factorRequest?.activityDataSource?.name?.toString() ??
                                t('onboarding.suppliers.missing')}
                            </Tag>
                          </Col>
                        </Row>
                        <Row className={classes.propertyWrapper}>
                          <Col span={6}>
                            <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                              {t('onboarding.suppliers.steps.request.fields.emissions')}
                            </Text>
                          </Col>
                          <Col offset={1} span={17}>
                            <Tag truncateText size="sm" type="danger">
                              {t('onboarding.suppliers.missing')}
                            </Tag>
                          </Col>
                        </Row>
                        <Row className={classes.propertyWrapper}>
                          <Col span={6}>
                            <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                              {t('onboarding.suppliers.steps.request.fields.dueDate')}
                            </Text>
                          </Col>
                          <Col offset={1} span={17}>
                            <Tag type="danger" truncateText size="sm">
                              {moment(factorRequest?.dueDate).format('MMM DD, YYYY') !==
                              'Invalid date'
                                ? moment(factorRequest?.dueDate).format('MMM DD, YYYY')
                                : '--'}
                            </Tag>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={16}>
                        <Row className={classes.propertyWrapper}>
                          <Col>
                            <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                              {t('onboarding.suppliers.steps.request.fields.customer')}
                            </Text>
                          </Col>
                          <Col offset={1} span={20}>
                            <Row>
                              <Col>
                                <Avatar
                                  image={
                                    factorRequest?.requester?.photo?.url
                                      ? { url: factorRequest?.requester?.photo?.url }
                                      : placeholderImage
                                  }
                                  size="xs"
                                />
                              </Col>
                              <Col span={22}>
                                <Text className={classes.customerInfo}>
                                  {`${
                                    factorRequest?.requester?.firstName
                                      ? factorRequest?.requester?.firstName
                                      : factorRequest?.requester.email
                                  } ${factorRequest?.requester?.lastName ?? ''}`}{' '}
                                  by {factorRequest?.organization.name}
                                </Text>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className={classes.propertyWrapper}>
                          <Col>
                            <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                              {t('onboarding.suppliers.steps.request.fields.message')}
                            </Text>
                          </Col>
                          <Col offset={1} span={20}>
                            <Text>{factorRequest?.note}</Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row justify="end" align="middle">
                      <Col>
                        {step === 'STEP_1' && (
                          <Text mode="paragraph" size="lg">
                            {t('onboarding.suppliers.steps.request.question')}{' '}
                          </Text>
                        )}
                      </Col>
                      <Col>
                        {step === 'STEP_1' && (
                          <Button type="link" key="shareFactor">
                            <Link to={config.routes.onboardingSupplierStep2}>
                              {t('layout.share')}
                            </Link>
                          </Button>
                        )}
                      </Col>
                      <Col>
                        <Button
                          category="supplier"
                          action="supplierToPcf"
                          type={!endedOnboarding && step !== 'STEP_1' ? 'secondary' : 'primary'}
                          key="createPcf"
                          disabled={endedOnboarding}
                          onClick={() => {
                            goToCreatePage()
                          }}
                          className={classes.calculateBtn}
                        >
                          {t('layout.calculate-emissions')}
                        </Button>
                      </Col>
                    </Row>
                  </>
                </ActionCard>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <ActionCard
                  disabled={step !== 'STEP_2' || endedOnboarding}
                  title={t('onboarding.suppliers.steps.share.title')}
                  text={t('onboarding.suppliers.steps.share.text')}
                  status={
                    step !== 'STEP_2' || endedOnboarding
                      ? t('onboarding.suppliers.waiting')
                      : t('onboarding.suppliers.todo')
                  }
                  stepNumber={2}
                >
                  <>
                    <Row>
                      <Col offset={1} span={23}>
                        <Form className={classes.form} layout="vertical" form={form}>
                          <Row gutter={[10, 0]}>
                            <Col span={6}>
                              <Form.Item
                                name="value"
                                label={t('onboarding.suppliers.steps.share.fields.emissions')}
                              >
                                <InputField
                                  disabled={step !== 'STEP_2' || endedOnboarding}
                                  defaultValue={factorRequest?.emissionFactor?.value}
                                  type="number"
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name="unit" label={' '}>
                                <InputField type="text" disabled />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                      <Col offset={1}>
                        {step === 'STEP_2' && (
                          <Text>
                            {t('onboarding.suppliers.steps.share.moreInfo')}{' '}
                            <Link
                              to={config.routes.log.carbonFootprint.products.productDetails.replace(
                                ':productId',
                                factorRequest?.productId?.toString() ?? '',
                              )}
                              className={classes.loginHintLink}
                            >
                              {t('products.single')} {t('products.footprint.overview')}
                            </Link>
                          </Text>
                        )}
                      </Col>
                    </Row>

                    <Row justify="end">
                      <Col>
                        <Button
                          category="supplier"
                          action="supplierToPcf"
                          type="primary"
                          key="createPcf"
                          disabled={step !== 'STEP_2' || endedOnboarding}
                          onClick={onSubmit}
                        >
                          {t('layout.continue')}
                        </Button>
                      </Col>
                    </Row>
                  </>
                </ActionCard>
              </Col>
            </Row>
          </Col>
        </Row>
        <SupplierShareModal visible={showShareModal} closeModal={closeModal} />
        <CompleteProductModal
          showModal={showProductCreatedModal}
          text={t('onboarding.suppliers.create-product-modal.text')}
          buttonText={t('onboarding.suppliers.create-product-modal.buttonText')}
          onClick={() => setShowProductCreatedModal(false)}
          onCancel={() => setShowProductCreatedModal(false)}
        />
        <FaqSection />
      </div>
    </div>
  )
}

export default SupplierOnboarding
