import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { HiDotsVertical, HiPencilAlt, HiTrash } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import {
  Col,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd/es'
import { SorterResult } from 'antd/es/table/interface'

import { WarningOutlined } from '@ant-design/icons'
import moment from 'moment'

import { ClosedPeriodStatus } from '@cozero/constants/log'
import { BusinessUnit, ClosedPeriod, User } from '@cozero/models'

import Table from '@/molecules/Table'

import Avatar from '@/atoms/Avatar'
import Button from '@/atoms/Button'
import Pill from '@/atoms/Pill'

import { useClosedPeriod } from '@/hooks/useClosedPeriod'
import { useAppSelector } from '@/redux'
import { getIsManager, getIsManagerOrAdmin, selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { CINDER_BLUE_60 } from '@/styles/variables'
import { config } from '@/utils/config'

import classes from './CustomReports.module.less'
import OutdatedEmissionFactorsModal from './Modals/OutdatedEmissionFactorsModal'

const PAGE_SIZE = 5

const CustomReports = (): JSX.Element => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const isManager = useAppSelector(getIsManager)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const [closedPeriods, setClosedPeriods] = useState<ClosedPeriod[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [totalRecords, setTotalRecords] = useState(1)
  const [openOutdatedEmissionFactorsModal, setOpenOutdatedEmissionFactorsModal] = useState(false)
  const [selectedClosedPeriod, setSelectedClosedPeriod] = useState<ClosedPeriod>()
  const { getManyClosedPeriods, deleteClosedPeriod } = useClosedPeriod()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const getClosedPeriods = async (
    page: number,
    pageSize: number,
    sorter?: { [field: string]: 'desc' | 'asc' },
  ): Promise<void> => {
    setLoading(true)
    const { docs, totalRecords: total } = await getManyClosedPeriods(page, pageSize, sorter)
    setClosedPeriods(docs)
    setTotalRecords(total)
    setLoading(false)
  }
  const onTableChange = (page: number, sorter: SorterResult<ClosedPeriod>): void => {
    getClosedPeriods(
      page,
      PAGE_SIZE,
      sorter && Object.keys(sorter).length
        ? {
            [sorter.field as string]: sorter.order === 'descend' ? 'desc' : 'asc',
          }
        : undefined,
    )
  }

  const openModal = (closedPeriod: ClosedPeriod): void => {
    setSelectedClosedPeriod(closedPeriod)
    return setOpenOutdatedEmissionFactorsModal(true)
  }

  useEffect(() => {
    if (!openOutdatedEmissionFactorsModal) {
      getClosedPeriods(1, PAGE_SIZE)
    }
  }, [openOutdatedEmissionFactorsModal, selectedBusinessUnit?.id])

  const menu = (id: number): JSX.Element => (
    <Menu style={{ width: 256 }} mode="vertical">
      <Menu.ItemGroup>
        <Menu.Item key="1">
          <Button
            category="closed-periods"
            action="Edit closed period"
            type="text"
            prefixIcon={<HiPencilAlt />}
            onClick={() => {
              navigate(
                config.routes.log.carbonFootprint.editClosedPeriod.replace(':id', id.toString()),
              )
            }}
          >
            {t('log.closed-period.edit')}
          </Button>
        </Menu.Item>

        <Menu.Item key="2">
          <Popconfirm
            title={t('log.closed-period.delete-confirmation')}
            onConfirm={() => {
              deleteClosedPeriod(id.toString())
            }}
          >
            <Button
              category="closed-periods"
              action="Delete closed period"
              type="text"
              prefixIcon={<HiTrash />}
              color="danger"
            >
              {t('log.closed-period.delete')}
            </Button>
          </Popconfirm>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  const columns = [
    {
      title: t('custom-reports.column-name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('custom-reports.column-status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render(_: string, record: ClosedPeriod) {
        return (
          <Pill color={record.status === ClosedPeriodStatus.DRAFT ? 'cinder-blue' : 'blue'}>
            {t(`custom-reports.${record.status}`)}
          </Pill>
        )
      },
    },
    {
      title: t('custom-reports.column-description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: t('custom-reports.column-date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render(_: string, record: ClosedPeriod) {
        return (
          <Space className={classes.row}>
            <Tag className={classes.tag}>
              {moment(record.startDate)
                .utc()
                .locale(user?.locale ?? 'en')
                .format('LL')}
            </Tag>
            <Typography className={classes.periodDivisor}>{t('to')}</Typography>
            <Tag className={classes.tag}>
              {moment(record.endDate)
                .utc()
                .locale(user?.locale ?? 'en')
                .format('LL')}
            </Tag>
          </Space>
        )
      },
    },
    {
      title: t('custom-reports.column-organization-level'),
      dataIndex: 'organizationLevel',
      key: 'organizationLevel',
      sorter: true,
      render(_: string, record: ClosedPeriod) {
        switch (record.organizationLevel) {
          case 'location':
            return <div>{record.locations.map((location) => location.name).join(', ')}</div>
          case 'businessUnit':
            return <div>{record.businessUnit.title}</div>

          default:
            return <div>{t('log.closed-period.organization-levels.entire-organization')}</div>
        }
      },
    },
    {
      title: t('custom-reports.column-created-by'),
      dataIndex: ['owner', 'email'],
      key: 'owner',
      width: 200,
      sorter: true,
      render(_: string, record: ClosedPeriod) {
        const user = record.responsible as User
        if (!user) {
          return <Typography>N/A</Typography>
        }
        return (
          <Space className={classes.row} align="center" direction="horizontal">
            <Avatar
              firstName={user.firstName}
              lastName={user.lastName}
              image={user.photo}
              className={classes.userPhoto}
              showTooltip={false}
            />
            <Typography>{user?.firstName || user?.email}</Typography>
          </Space>
        )
      },
    },
    {
      title: t('business-unit.name'),
      dataIndex: ['businessUnit', 'title'],
      key: 'businessUnit',
      sorter: (a: ClosedPeriod, b: ClosedPeriod) =>
        (a.businessUnit as BusinessUnit)?.title.localeCompare(
          (b.businessUnit as BusinessUnit)?.title,
        ),
    },
    {
      title: t('actions.title-plural'),
      key: 'action',
      fixed: 'right',
      width: 300,
      render(_text: string, record: ClosedPeriod) {
        if (!isManagerOrAdmin || (isManager && record.organizationLevel === 'organization')) {
          return
        }
        return (
          <Row justify="space-between">
            {record.outdatedEmissionFactors?.length ? (
              <Col span={18}>
                <Space className={classes.actions}>
                  <Button
                    prefixIcon={<WarningOutlined />}
                    category="closed-periods"
                    action="open-modal"
                    onClick={(e) => {
                      e?.stopPropagation()
                      openModal(record)
                    }}
                    type="primary"
                  >
                    {t('custom-reports.column-action')}
                  </Button>
                </Space>
              </Col>
            ) : null}
            <Col span={6}>
              <Dropdown overlay={() => menu(record.id)} className={classes.dropdown}>
                <Button
                  category="closed-periods"
                  action="Show options menu"
                  type="text"
                  size="lg"
                  onClick={(e) => e.stopPropagation()}
                >
                  <HiDotsVertical color={CINDER_BLUE_60} />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        )
      },
    },
  ]

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Typography.Text className={classes.headline}>
                {t('layout.closed-periods')}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text className={classes.subtitle}>
                {t('custom-reports.description')}
              </Typography.Text>
            </Col>
          </Row>
          <div className={classes.header} />
        </Col>
        <Divider />
        <Col span={24}>
          <Row className={classes.createBtn} justify="end">
            {isManagerOrAdmin && (
              <Button
                prefixIcon={<AiOutlineClockCircle />}
                type="primary"
                action="Closed period"
                category="locations"
                onClick={() => navigate(config.routes.log.carbonFootprint.addClosedPeriod)}
              >
                {t('log.carbon-footprint-tabs.closed-period')}
              </Button>
            )}
          </Row>
          <Row className={classes.section} gutter={16}>
            <Col span={24}>
              <Table
                onChange={(pagination, sorters) => {
                  return onTableChange(
                    pagination.current ?? 1,
                    sorters as SorterResult<ClosedPeriod>,
                  )
                }}
                pagination={{
                  total: totalRecords,
                  pageSize: PAGE_SIZE,
                  defaultCurrent: 1,
                }}
                className={classes.customReportsTable}
                rowClassName={classes.tableRow}
                dataSource={closedPeriods}
                rowKey="id"
                // Types not working properly for 'fixed'
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                columns={columns as any}
                onRow={(record) => ({
                  onClick: () => {
                    navigate(
                      `${config.routes.log.customReport.replace(':id', record.id.toString())}`,
                    )
                  },
                })}
                showWrapper={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {selectedClosedPeriod && (
        <OutdatedEmissionFactorsModal
          closedPeriod={selectedClosedPeriod}
          open={true}
          visibilityHandler={(open: boolean) => {
            return setOpenOutdatedEmissionFactorsModal(open)
          }}
          onClose={() => setOpenOutdatedEmissionFactorsModal(false)}
        />
      )}
    </Spin>
  )
}

export default CustomReports
