import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiDotsVertical, HiInformationCircle, HiPencilAlt, HiTrash } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { Col, Divider, Dropdown, Menu, Popconfirm, Row, Spin, message } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import { debounce } from 'lodash-es'

import { Board as IBoard, LogSorter, PageFilter, ReportCategory } from '@cozero/models'

import ReportsModal from '@/pages/Share/Modals/ReportsModal'

import CustomBoard from '@/organisms/CustomBoard'
import LogsTable from '@/organisms/LogsTable'
import OverviewRow from '@/organisms/OverviewRow'

import { OverviewCardProps } from '@/molecules/OverviewCard'

import Button from '@/atoms/Button'
import HighlightValue from '@/atoms/HighlightValue'
import LoadingSpinner from '@/atoms/LoadingSpinner'
import Pill from '@/atoms/Pill'

import { useBoardsContext } from '@/contexts/boards'
import { useFiltersContext } from '@/contexts/filters'
import { useClosedPeriod } from '@/hooks/useClosedPeriod'
import useOverviewStats from '@/hooks/useOverviewStats'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getIsManager, getIsManagerOrAdmin, selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { CINDER_BLUE_60, COZERO_BLUE_80 } from '@/styles/variables'
import { PersistedBoardSettings } from '@/types/general'
import { config } from '@/utils/config'

import classes from './CustomReport.module.less'

const DEFAULT_PAGE_SIZE = 15

const CustomReport = (): ReactElement => {
  const { id } = useParams()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const isManager = useAppSelector(getIsManager)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const user = useAppSelector(selectUser)
  const {
    filters,
    saveFilters,
    sorters,
    pageNumber: currentPage,
    savePageNumber: setCurrentPage,
    saveSorters,
  } = useFiltersContext()
  const [customReportFilters, setCustomReportFilters] = useState(() =>
    filters?.filter((filter) => filter.logType === 'location' || !filter.logType),
  )
  const [customReportSorters, setCustomReportSorters] = useState(
    sorters?.filter((sorter) => sorter.logType === 'location' || !sorter.logType),
  )
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [openReportMenu, setOpenReportMenu] = useState<boolean>(false)
  const [reportCategories, setReportCategories] = useState<ReportCategory[]>([])
  const {
    getClosedPeriod,
    closedPeriod,
    previewKeyStats,
    deleteClosedPeriod,
    keyStats,
    loadingPreview,
  } = useClosedPeriod()
  const [closedPeriodBoardSettings, setClosedPeriodBoardSettings] =
    useState<PersistedBoardSettings>()
  const {
    getBoards,
    selectedBoard,
    boards,
    getBoardData,
    editReportsOfBoard,
    searchReportCategories,
    setSelectedBoard,
  } = useBoardsContext()

  const editReports = async (reports: Store, boardId: number): Promise<void> => {
    await editReportsOfBoard(reports, boardId, undefined, Number(id))
    if (selectedBoard) {
      await fetchBoardData(selectedBoard)
    } else {
      message.error(t('share.reports.errors.add'))
    }
  }

  async function fetchBoardData(board: IBoard): Promise<void> {
    if (closedPeriod) {
      const filtersTemp: PageFilter[] = [
        {
          key: 'closedPeriodId',
          value: id,
          type: 'relationship',
          conditions: [],
          selectedCondition: {
            key: 'in',
            label: 'Is',
          },
          label: 'closedPeriodId',
        },
      ]
      saveFilters(filtersTemp)
      const boardData = await getBoardData({
        board,
        user,
        filters: filtersTemp,
        closedPeriodId: closedPeriod.id,
      })
      if (boardData) {
        setSelectedBoard(boardData)
      }
      setClosedPeriodBoardSettings({
        board,
        filters: filtersTemp,
        closedPeriodId: closedPeriod.id,
      })
    }
  }

  async function fetchBoards(): Promise<void> {
    await getBoards()
  }

  async function changeSorting(sorters: LogSorter[]): Promise<void> {
    saveSorters(sorters)
    setCustomReportSorters(sorters)
  }

  const fetchReportCategories = async (search: string): Promise<void> => {
    const data = await searchReportCategories(search)
    setReportCategories(data)
  }

  useEffect(() => {
    setCustomReportFilters(filters.filter((x) => x.key !== 'businessUnit') as PageFilter[])
  }, [filters])

  useEffect(() => {
    if (id) {
      getClosedPeriod(id)
    }
  }, [id])

  useEffect(() => {
    if (closedPeriod) {
      previewKeyStats({
        id: closedPeriod.id,
        startDate: closedPeriod?.startDate,
        endDate: closedPeriod?.endDate,
        locations: closedPeriod?.locations?.map((x) => x.id),
        organizationLevel: closedPeriod?.organizationLevel,
      } as never)
    }
  }, [closedPeriod])

  useEffect(() => {
    if (boards.length <= 0) {
      fetchBoards()
      fetchReportCategories('')
    }
  }, [boards])

  useEffect(() => {
    if (selectedBusinessUnit?.key) {
      fetchReportCategories('')
    }
  }, [selectedBusinessUnit?.key])

  useEffect(() => {
    if (boards && boards.length > 0) {
      const board = boards.find((board) => board.type === 'custom-report')
      if (board && closedPeriod) {
        fetchBoardData(board)
      }
    }
  }, [boards, closedPeriod])

  const menu = (
    <Menu style={{ width: 256 }} mode="vertical">
      <Menu.ItemGroup>
        <Menu.Item key="1">
          <Button
            category="closed-periods"
            action="Edit closed period"
            type="text"
            prefixIcon={<HiPencilAlt />}
            onClick={() => {
              if (id) {
                navigate(config.routes.log.carbonFootprint.editClosedPeriod.replace(':id', id))
              }
            }}
          >
            {t('log.closed-period.edit')}
          </Button>
        </Menu.Item>
        <Menu.Item key="2">
          <Popconfirm
            title={t('log.closed-period.delete-confirmation')}
            onConfirm={() => {
              if (id) {
                deleteClosedPeriod(id)
                navigate(config.routes.log['closed-periods'])
              }
            }}
          >
            <Button
              category="closed-periods"
              action="Delete closed period"
              type="text"
              prefixIcon={<HiTrash />}
              color="danger"
            >
              {t('log.closed-period.delete')}
            </Button>
          </Popconfirm>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  const Wrapper = React.memo(({ children }: { children: ReactNode }): ReactElement | null => {
    const { data: overviewStats } = useOverviewStats({
      type: 'location',
      businessUnitId: closedPeriod?.businessUnitId,
      filters,
    })
    if (!keyStats) {
      return null
    }
    const overviewDataLocationType: OverviewCardProps[] = [
      {
        content: (
          <div>
            <HighlightValue
              value={keyStats?.emissionsDataValue.toString() ?? ''}
              unit={t('log.co2-unit')}
            />
            <Row>
              <Pill color="cinder-blue">
                <span className={classes.totalLogsPill}>{keyStats?.emissionsDataCount} logs</span>
              </Pill>
            </Row>
          </div>
        ),
        headerTitle: t('log.overview-stats.organization-footprint'),
        tooltip: {
          triggerElement: <HiInformationCircle color={COZERO_BLUE_80} />,
          subtitle: t('log.overview-stats.organization-footprint-info'),
        },
      },
      {
        content: (
          <div>
            <Row>
              <HighlightValue value={overviewStats?.numberOfQuantities.toString() ?? ''} />
            </Row>
            <Row>----</Row>
          </div>
        ),
        headerTitle: t('log.overview-stats.number-of-quantities'),
        tooltip: {
          triggerElement: <HiInformationCircle color={COZERO_BLUE_80} />,
          subtitle: t('log.overview-stats.number-of-quantities-info'),
        },
      },
    ]
    return (
      <Row>
        <Row className={classes.header}>
          <Col span={8}>
            <h3 className={classes.title}>{closedPeriod?.name}</h3>
          </Col>
          <Col span={8} offset={8} className={classes.optionsButton}>
            <Dropdown overlay={menu}>
              <Button category="closed-periods" action="Show options menu" type="text" size="lg">
                <HiDotsVertical color={CINDER_BLUE_60} />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <OverviewRow
                loadingOverview={false}
                marginBetween={14}
                overviewData={overviewDataLocationType}
              />
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  })
  Wrapper.displayName = 'Wrapper'

  return (
    <div className={classes.onboardingContainer} data-cy="custom-report-page">
      <Spin spinning={!closedPeriod || loadingPreview}>
        {closedPeriod && (
          <Wrapper>
            <Row>
              <Col span={24}>
                <Col md={{ span: 5 }} xxl={{ span: 3 }}>
                  <Row justify="end" gutter={[16, 16]}>
                    <Col span={24}>
                      <Button
                        disabled={
                          !isManagerOrAdmin ||
                          (isManager && closedPeriod?.organizationLevel === 'organization')
                        }
                        category={'closed-periods'}
                        action={'add-report'}
                        className={classes.editBtn}
                        onClick={() => {
                          setOpenReportMenu(true)
                        }}
                      >
                        {t('share.reports.add-board')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {selectedBoard?.type === 'custom-report' && (
                  <CustomBoard
                    persistedBoardSettings={closedPeriodBoardSettings}
                    editable
                    canUpdateWidth={false}
                  />
                )}
                {!selectedBoard ||
                  (selectedBoard?.type !== 'custom-report' && (
                    <LoadingSpinner
                      className={classes.spinner}
                      title={t('share.reports.loading-plural')}
                    />
                  ))}
              </Col>
            </Row>
            <Row>
              <Col>
                <Divider />
                {customReportFilters.length && (
                  <LogsTable
                    type={'location'}
                    businessUnitsAllowed={featuresAllowed?.includes('business-units') || false}
                    filters={customReportFilters}
                    sorters={customReportSorters}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    defaultPageSize={DEFAULT_PAGE_SIZE}
                    currentPage={currentPage || 1}
                    setCurrentPage={setCurrentPage}
                    selectedBusinessUnit={selectedBusinessUnit ?? undefined}
                    loading={false}
                    setSort={debounce(changeSorting, 800)}
                  />
                )}
              </Col>
            </Row>
          </Wrapper>
        )}
        <ReportsModal
          open={openReportMenu}
          onOk={() => setOpenReportMenu(false)}
          onCancel={() => setOpenReportMenu(false)}
          reportCategories={reportCategories}
          editReportsOfBoard={editReports}
        />
      </Spin>
    </div>
  )
}

export default CustomReport
