import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AntTableColumn, Report } from '@cozero/models'

import { formatColumns } from '@/utils/config'
import { isNumeric } from '@/utils/number'
import { renderColumn, renderRow } from '@/utils/table'

import Table from '../Table'

import classes from './GraphTable.module.less'

interface Props {
  report: Report
  showColumns: boolean
}

const createSummary = (data: Map<string, string | number>[]): Map<string, string | number> => {
  return data.reduce((accumulator, newValue) => {
    const sum = new Map(accumulator)
    for (const [key, value] of Object.entries(newValue)) {
      const columnKey = key.split(',').length > 1 ? key.split(',')[1] : key
      const dateKey = key.split(',').length > 1 ? key.split(',')[0] : key
      if (formatColumns.number.includes(columnKey)) {
        if (sum.has(dateKey)) {
          const oldValue = sum.get(dateKey)
          sum.set(dateKey, oldValue + value)
        } else {
          sum.set(dateKey, value)
        }
      } else {
        sum.set(dateKey, '')
      }
    }
    return sum
  }, new Map())
}

export const GraphTable = ({ report, showColumns }: Props): JSX.Element => {
  const { t, i18n } = useTranslation('common')
  const [tableColumns, setTableColumns] = useState<AntTableColumn[]>([])

  useEffect(() => {
    setTableColumns(
      report.data?.tableColumns
        ?.map((column) => renderColumn({ column, t, i18n }))
        .sort((a, b) => parseInt(a.title) - parseInt(b.title)) ?? [],
    )
  }, [report.data?.tableColumns])

  return (
    <div style={{ overflow: 'scroll' }}>
      <Table
        scroll={{ x: true }}
        showWrapper={false}
        pagination={false}
        showHeader={showColumns}
        className={classes.table}
        columns={tableColumns}
        rowKey={(row) => `${report.id}_${JSON.stringify(row)}`}
        dataSource={report.data.tablePivot.map((row) => renderRow({ row, t, i18n }))}
        summary={(pageData) => {
          if (pageData.length < 2) {
            return null
          }
          const summary = createSummary(pageData as unknown as Map<string, string | number>[])
          const columnOrder = tableColumns?.map((obj) => obj.key) || []
          const orderedSummary = Array.from(summary)
            .map(([name, value]) => ({ name, value }))
            .sort((a, b) => {
              return columnOrder.indexOf(a.name) - columnOrder.indexOf(b.name)
            })

          return (
            <Table.Summary.Row className={classes.summaryRow}>
              {orderedSummary.map((sum, index) => (
                <Table.Summary.Cell key={`${index}_${sum}`} index={index}>
                  {isNumeric(sum.value)
                    ? Number(sum.value).toLocaleString(i18n.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ''}
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          )
        }}
      />
    </div>
  )
}
