import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Target } from '@cozero/models'

import { SLICE_NAME_TARGETS } from './constants'

// Define a type for the slice state
export interface SelectedTargetsState {
  selectedTargets: Target[]
}

// Define the initial state using that type
const initialState: SelectedTargetsState = {
  selectedTargets: [],
}

const targetsSlice = createSlice({
  name: SLICE_NAME_TARGETS,
  initialState: initialState,
  reducers: {
    setSelectedTargets: (state, action: PayloadAction<Target[]>) => {
      state.selectedTargets = action.payload
    },
  },
})

export const { setSelectedTargets } = targetsSlice.actions

export default targetsSlice
