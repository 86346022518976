import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiInformationCircle, HiOutlineFilter, HiOutlinePlus } from 'react-icons/hi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Col, Divider, Row, message } from 'antd/es'

import debounce from 'lodash/debounce'
import omit from 'lodash/omit'

import { CreateLogDto } from '@cozero/dtos'
import { LogSorter, Organization, PageFilter } from '@cozero/models'

import CarbonFootprintWrapper from '@/organisms/CarbonFootprintWrapper'
import LogCategoryModal from '@/organisms/LogCategoryModal'
import LogViewsMenu from '@/organisms/LogViewsMenu'
import LogsTable from '@/organisms/LogsTable'

import FiltersDrawer, { PageType } from '@/molecules/FiltersDrawer'
import { OverviewCardProps } from '@/molecules/OverviewCard'

import Button from '@/atoms/Button'
import HighlightValue from '@/atoms/HighlightValue'
import Pill from '@/atoms/Pill'
import Text from '@/atoms/Text'

import { useAppContext } from '@/contexts/app'
import { useFiltersContext } from '@/contexts/filters'
import { useLogContext } from '@/contexts/log'
import { useSubscriptionContext } from '@/contexts/subscription'
import useOverviewStats from '@/hooks/useOverviewStats'
import useTerritories from '@/hooks/useTerritories'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getIsUserReadOnly, selectUserOrganization } from '@/redux/auth'
import { isAxiosError } from '@/redux/axiosBaseQuery'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetCategoriesQuery } from '@/redux/categories'
import { useCreateLogMutation } from '@/redux/logs'
import { selectSteps } from '@/redux/onboarding'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'
import { COZERO_BLUE_80 } from '@/styles/variables'
import { config } from '@/utils/config'
import { createFilterOptions, createQueryFilter } from '@/utils/filters'
import { formatNumber } from '@/utils/number'

import classes from './Log.module.less'

enum FilterKey {
  CATEGORY = 'categoryId',
  LOCATION = 'locationId',
  BUSINESS_UNIT = 'businessUnit',
  RESPONSIBLE = 'ownerId',
}

const DEFAULT_PAGE_SIZE = 15

function LocationLogs(): JSX.Element {
  const { t } = useTranslation('common')
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const userIsReadOnly = useAppSelector(getIsUserReadOnly)
  const organization = useAppSelector(selectUserOrganization) as Organization
  const steps = useAppSelector(selectSteps)
  const { getSuppliers, suppliers } = useAppContext()
  // const dispatch = useAppDispatch()
  const [noLogs, setNoLogs] = useState(false)
  const [drawerOpened, setDrawerOpened] = useState(false)
  const {
    filters,
    sorters,
    pageNumber: currentPage,
    saveFilters,
    saveSorters,
    savePageNumber: setCurrentPage,
  } = useFiltersContext()
  const { data: overviewStats, loading: overviewLoading } = useOverviewStats({
    type: 'location',
    businessUnitId: selectedBusinessUnit?.id,
    filters: filters?.map((obj) => omit(obj, 'options')) || [],
  })
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [locationFilters, setLocationFilters] = useState(
    filters?.filter((filter) => filter.logType === 'location' || !filter.logType),
  )
  const [locationSorters, setLocationSorters] = useState(
    sorters?.filter((sorter) => sorter.logType === 'location' || !sorter.logType),
  )
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { territories } = useTerritories()
  const { data: categories } = useGetCategoriesQuery()
  // const [updateOnboarding] = useUpdateOnboardingMutation()
  // const [getMe] = useLazyGetUserDataQuery()
  const { data: users } = useGetOrganizationUsersQuery(
    {
      businessUnit: selectedBusinessUnit?.key ?? '',
    },
    {
      skip: !selectedBusinessUnit?.key,
    },
  )
  const [createLog] = useCreateLogMutation()

  const { loading: logLoading, reset, getCustomers, customers, getTags, tags } = useLogContext()
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()
  const [filterOptions, setFilterOptions] = useState<PageFilter[]>(
    createFilterOptions({
      type: 'location',
      categories,
      users,
      suppliers,
      customers,
      tags,
      t,
      featuresAllowed,
      territories,
      shownOptions: [
        'businessUnits',
        'locations',
        'owners',
        'categories',
        'products',
        'suppliers',
        'customers',
        'tags',
        'territories',
      ],
      page: 'logs',
    }) ?? [],
  )
  const { locationId } = useParams()
  const queryLocation = new URLSearchParams(useLocation().search).get('location') || locationId
  const hasFinishedStep = useMemo(
    () =>
      steps?.length === 0 ||
      steps?.filter((step) => step.onboardingStep.key === 'logs' && step.completed)?.length === 1,
    [steps],
  )

  const onChooseCategory = async (id: number): Promise<void> => {
    await submitLog({ categoryId: id, type: 'location' })
  }

  const overviewData: OverviewCardProps[] = [
    {
      content: (
        <div>
          <HighlightValue
            value={
              overviewStats?.emissionsDataValue
                ? formatNumber(overviewStats?.emissionsDataValue).toString()
                : '-'
            }
            unit={t('co2-tonnes')}
          />
          <Row>
            <Pill>
              <span className={classes.logsPercentage}>{overviewStats?.percentageOfLogs}%</span>
              <span className={classes.totalLogsPill}>
                {' '}
                {t('log.overview-stats.of')} {overviewStats?.totalLogs} Logs
              </span>
            </Pill>
          </Row>
        </div>
      ),
      headerTitle: t('log.overview-stats.organization-footprint'),
      tooltip: {
        triggerElement: <HiInformationCircle color={COZERO_BLUE_80} />,
        subtitle: t('log.overview-stats.organization-footprint-info'),
      },
    },
  ]

  const submitLog = async (newLog: Partial<CreateLogDto>): Promise<void> => {
    try {
      const createdLog = await createLog(newLog).unwrap()
      if (createdLog) {
        const category = categories?.find((obj) => obj.id === newLog.categoryId)
        message.success(t('log.create-success', { category: category?.name }))
        return navigate(
          `${config.routes.log.carbonFootprint.organization.edit.replace(
            ':id',
            createdLog.id.toString(),
          )}`,
          {
            state: { firstLog: noLogs || (steps?.length && !hasFinishedStep) },
          },
        )
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.status === 402) {
          const limit = getLimit(organization, 'log')
          setSubscribeModalSettings({
            closable: true,
            title: t('subscription.upgrade-modal.title-logs-limit', {
              limit: limit?.max,
              item: t('product.logs.title'),
            }),
            visible: true,
          })
        } else {
          message.error(t('location.errors.log-creation'))
        }
      } else {
        message.error(t('location.errors.log-creation'))
      }
    }
  }

  const onCloseModal = (): void => {
    setModalOpen(false)
  }

  const openModal = async (): Promise<void> => {
    setModalOpen(true)
  }

  async function onSearch(filters: PageFilter[]): Promise<void> {
    saveFilters(filters)
  }

  async function changeSorting(sorters: LogSorter[]): Promise<void> {
    saveSorters(sorters)
  }

  const goToOldBulkLog = (): void => {
    navigate(config.routes.log.bulkImport)
  }

  const goToBulkLog = (): void => {
    navigate(config.routes.log.createBulkImport)
  }

  const goToBulkLogCreation = (): void => {
    navigate(config.routes.log.bulkCreate)
  }

  const openFilters = (): void => {
    const deprecatedView = locationFilters.find(
      (x) =>
        x.key !== 'startDate' &&
        x.key !== 'endDate' &&
        x.type !== 'dateRange' &&
        x.selectedCondition?.key !== 'in' &&
        x.selectedCondition?.key !== 'contains',
    )
    if (!deprecatedView) {
      setDrawerOpened(true)
    } else {
      message.error(t('views.update.failure'))
    }
  }

  useEffect(() => {
    setFilterOptions(
      createFilterOptions({
        type: 'location',
        categories,
        users,
        suppliers,
        customers,
        tags,
        t,
        featuresAllowed,
        territories,
        shownOptions: [
          'businessUnits',
          'locations',
          'owners',
          'categories',
          'products',
          'suppliers',
          'customers',
          'tags',
          'territories',
        ],
        page: 'logs',
      }) ?? [],
    )
  }, [categories, selectedBusinessUnit?.key, suppliers, customers, tags, users])

  useEffect(() => {
    if (queryLocation && currentPage === 1) {
      const queryFilter = createQueryFilter({
        value: [queryLocation],
        type: 'location',
        t,
        options: [],
      })
      saveFilters(queryFilter)
      navigate(location.pathname)
    }
  }, [currentPage, queryLocation, selectedBusinessUnit?.key])

  useEffect(() => {
    setLocationFilters(
      filters?.filter((filter) => filter.logType === 'location' || !filter.logType),
    )
  }, [filters])

  useEffect(() => {
    setLocationSorters(
      sorters?.filter((sorter) => sorter.logType === 'location' || !sorter.logType),
    )
  }, [sorters])

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    if (selectedBusinessUnit) {
      getSuppliers()
      getCustomers()
      getTags()
    }
  }, [filters, selectedBusinessUnit?.key])

  return (
    <>
      <CarbonFootprintWrapper overviewData={overviewData} overviewLoading={overviewLoading}>
        <FiltersDrawer
          search={debounce(onSearch, 500)}
          pageType={PageType.LOCATION}
          filters={[...locationFilters] as (PageFilter & { key: FilterKey })[]}
          visible={drawerOpened}
          filterOptions={
            [...filterOptions] as (PageFilter & {
              options: {
                key?: string
                value: string
                label: string
              }[]
            })[]
          }
          onClose={() => setDrawerOpened(false)}
          featuresAllowed={featuresAllowed}
        />
        <Row justify="start" className={classes.section}>
          <Col span={24}>
            <Row gutter={16} justify="end">
              {!userIsReadOnly && featuresAllowed?.includes('bulk-import-v1') && (
                <Col>
                  <Button
                    category="logs"
                    action="go-to-bulk-import"
                    type="secondary"
                    className={classes.bulkImportButton}
                    onClick={goToOldBulkLog}
                    data-cy="bulk-import-btn"
                    prefixIcon={<HiOutlinePlus />}
                  >
                    {t('log.bulkImport')}
                  </Button>
                </Col>
              )}
              {!userIsReadOnly && featuresAllowed?.includes('bulk-create') && (
                <Col>
                  <Button
                    category={'logs'}
                    action={'add-bulk'}
                    type="primary"
                    onClick={goToBulkLogCreation}
                    className={classes.createButton}
                    data-cy="new-emission-log"
                    prefixIcon={<HiOutlinePlus />}
                  >
                    {t(`log.create.bulk`)}
                  </Button>
                </Col>
              )}
              {!userIsReadOnly && featuresAllowed?.includes('bulk-import') && (
                <Col>
                  <Button
                    category="logs"
                    action="go-to-bulk-import"
                    type="secondary"
                    className={classes.bulkImportButton}
                    onClick={goToBulkLog}
                    data-cy="bulk-import-btn"
                    prefixIcon={<HiOutlinePlus />}
                  >
                    {t('log.bulkImport')}
                  </Button>
                </Col>
              )}
              {!userIsReadOnly && (
                <Col>
                  <Button
                    category={'logs'}
                    action={'open-create-modal'}
                    type="primary"
                    onClick={openModal}
                    className={classes.createButton}
                    data-cy="new-emission-log"
                    prefixIcon={<HiOutlinePlus />}
                  >
                    {t(`log.create.title`)}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={classes.logsContainer} span={24}>
            <Row justify="space-between">
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <LogViewsMenu logType="location" />
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      onClick={() => openFilters()}
                      category={'logs'}
                      action={'open-filter'}
                      className={`${classes.buttonRow} ${
                        locationFilters?.filter((filter) => filter.type !== 'date')?.length &&
                        classes.activeButton
                      }`}
                      prefixIcon={<HiOutlineFilter />}
                    >
                      <Text
                        size="xl"
                        fontWeight="medium"
                        className={`${classes.buttonTitle} ${
                          locationFilters?.length && classes.activeTitle
                        }`}
                      >
                        {t('log.filter.add')}
                      </Text>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <LogsTable
              locationId={queryLocation as string}
              type={'location'}
              businessUnitsAllowed={featuresAllowed?.includes('business-units') || false}
              filters={locationFilters}
              sorters={locationSorters}
              pageSize={pageSize}
              setPageSize={setPageSize}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              currentPage={currentPage || 1}
              setCurrentPage={setCurrentPage}
              selectedBusinessUnit={selectedBusinessUnit ?? undefined}
              setNoLogs={setNoLogs}
              loading={logLoading}
              setSort={debounce(changeSorting, 800)}
            />
          </Col>
        </Row>
      </CarbonFootprintWrapper>
      <LogCategoryModal
        open={modalOpen}
        onChooseCategory={onChooseCategory}
        onClose={onCloseModal}
      />
    </>
  )
}

export default LocationLogs
