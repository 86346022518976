import React, {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowsExpand, HiOutlineInformationCircle } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { SizeMe } from 'react-sizeme'

import { Options, Plot } from '@antv/g2plot'
import ArrowsPointingInIcon from '@heroicons/react/20/solid/ArrowsPointingInIcon'
import ArrowsPointingOutIcon from '@heroicons/react/20/solid/ArrowsPointingOutIcon'
import classNames from 'classnames'

import { Location, Report, ReportSetting, ReportToBoard, ReportType } from '@cozero/models'

import { GraphExportDropdown } from '@/molecules/GraphExportDropdown'
import { GraphSelectorDropdown } from '@/molecules/GraphSelectorDropdown'

import Divider from '@/atoms/Divider'
import SixDotsVertical from '@/atoms/Icons/SixDotsVertical'
import LoadingSpinner from '@/atoms/LoadingSpinner'
import Pill from '@/atoms/Pill'
import Select from '@/atoms/Select'
import Title from '@/atoms/Title'
import Tooltip from '@/atoms/Tooltip'

import { useBoardsContext } from '@/contexts/boards'
import { useFiltersContext } from '@/contexts/filters'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { CINDER_BLUE_50, COZERO_BLUE_80 } from '@/styles/variables'
import { PersistedBoardSettings } from '@/types/general'

import { ChartContainer } from '../ChartContainer'
import EmptyGraphView from '../EmptyGraphView'
import ErrorGraphView from '../ErrorGraphView'

import classes from './ReportContainer.module.less'

const FETCH_TIMEOUT = 5 * 1000 * 60 // 5 minutes

interface IReportContainer {
  report: Report
  draggingGraphId: number | null
  reportToBoard?: ReportToBoard & {
    report: Report | null
  }
  loading?: boolean
  disableDelete?: boolean
  showFilters?: boolean | null
  showColumns?: boolean | null
  updateGraphWidth?: (
    report: ReportToBoard & {
      report: Report | null
    },
  ) => void
  location?: Location
  hasDatePicker?: boolean
  editable?: boolean
  persistedBoardSettings?: PersistedBoardSettings
}

const ReportContainer = ({
  report,
  editable,
  draggingGraphId,
  reportToBoard,
  showFilters = false,
  showColumns = true,
  disableDelete,
  updateGraphWidth,
  persistedBoardSettings,
}: IReportContainer): ReactElement => {
  const abortControllerRef = useRef<AbortController>()
  const { t, i18n } = useTranslation('common')
  const [view, setView] = useState<string>('primary')
  const user = useAppSelector(selectUser)
  const {
    reportSettings,
    getReportWithData,
    selectedBoard,
    removeReportFromSelectedBoard,
    getBoard,
    getBoardData,
  } = useBoardsContext()
  const { filters } = useFiltersContext()
  const [reportSetting, setReportSetting] = useState<ReportSetting | undefined>(
    reportSettings.find((setting) => setting.reportId.toString() === report.id.toString()),
  )
  const [switchAxis, setSwitchAxis] = useState<boolean>(false)
  const isSwitchedAxisRef = useRef(switchAxis)

  const chartRef = useRef<Plot<Options> | null>(null)
  const svgChartRef = useRef<Plot<Options> | null>(null)
  const secondaryChartRef = useRef<Plot<Options> | null>(null)
  const secondarySvgChartRef = useRef<Plot<Options> | null>(null)

  const [hoveringOverGraph, setHoveringOverGraph] = useState<boolean>(false)
  const [isDraggingGraph, setIsDraggingGraph] = useState<boolean>(
    reportToBoard?.id === draggingGraphId,
  )
  const [resizeTooltipVisible, setResizeTooltipVisible] = useState<boolean>(false)
  const [showLegend, setShowLegend] = useState<boolean>(true)
  const isShowLegendToggled = useRef(showColumns)
  const [reportLoading, setReportLoading] = useState<boolean>(false)
  const [currentReportData, setCurrentReportData] = useState<Report>(report)
  const [graphError, setGraphError] = useState<boolean>(false)

  const fetchTime = useRef<number>(new Date().getTime())
  const [isRefetching, setIsRefetching] = useState<boolean>(false)

  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)

  const [graphDataAvailable, setGraphDataAvailable] = useState<boolean>(false)
  const [dragTooltipVisible, setDragTooltipVisible] = useState<boolean>(false)

  const saveView = (view: string): void => {
    setView(view)
  }

  useEffect(() => {
    if (!currentReportData.data && selectedBusinessUnit) {
      fetchReportData()
    }
  }, [report, selectedBusinessUnit])

  useEffect(() => {
    setIsDraggingGraph(reportToBoard?.id === draggingGraphId)
  }, [draggingGraphId])

  useEffect(() => {
    setReportSetting(
      reportSettings.find((setting) => setting.reportId.toString() === report.id.toString()),
    )
  }, [reportSettings])

  useEffect(() => {
    if (selectedBusinessUnit) {
      fetchReportData()
    }
  }, [filters, reportSetting, selectedBusinessUnit?.key])

  useEffect(() => {
    const hasData = !graphError && currentReportData.data?.seriesNames?.length > 0
    setGraphDataAvailable(hasData)
  }, [currentReportData, graphError])

  const fetchReportData = async (): Promise<void> => {
    setReportLoading(true)

    // Check if previous request was executed and trigger abort
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    // Assign new controller to the current ref for every new graph request
    abortControllerRef.current = new AbortController()

    /** Using try & catch here so that when AbortController is triggered
     * for the previous request, we set Loading false only when the report is fetched
     * or throws an actual error and not when the request has been cancelled
     **/
    try {
      const reportWithData = await getReportWithData(
        report.key,
        persistedBoardSettings?.filters ?? filters,
        reportSetting,
        abortControllerRef.current?.signal,
      )
      if (reportWithData) {
        setCurrentReportData(reportWithData)
        setGraphError(false)
        setReportLoading(false)
      }
    } catch (e) {
      const isTimeoutResponse = e.response?.status === 504
      const canRefetch = new Date().getTime() < fetchTime.current + FETCH_TIMEOUT

      if (isTimeoutResponse && canRefetch) {
        setIsRefetching(true)
        fetchReportData()
      } else if (e.message !== 'canceled') {
        setGraphError(true)
        setReportLoading(false)
        setIsRefetching(false)
      }
    }
  }

  const deleteReport = async (): Promise<void> => {
    setReportLoading(true)
    if (user && currentReportData && selectedBoard) {
      await removeReportFromSelectedBoard(report.id, user.id)
      if (persistedBoardSettings) {
        await getBoardData({
          ...persistedBoardSettings,
        })
      } else {
        await getBoard({ boardId: selectedBoard.id })
      }
    }
    setReportLoading(false)
  }

  useEffect(() => {
    handleTooltipWhileDragging()
  }, [isDraggingGraph])

  const onDragTooltipToggle = useCallback(
    (visible: boolean): void => {
      handleTooltipWhileDragging(visible)
    },
    [isDraggingGraph],
  )

  const handleTooltipWhileDragging = useCallback(
    (visible?: boolean) => {
      const currentVisibleStatus = visible !== undefined ? visible : dragTooltipVisible
      setDragTooltipVisible(currentVisibleStatus && !isDraggingGraph)
    },
    [isDraggingGraph],
  )

  const updateDimension = (dimension: ReportSetting['dimension']): void => {
    if (reportSetting) {
      setReportSetting({ ...reportSetting, dimension })
    } else {
      setReportSetting({ reportId: currentReportData.id, dimension })
    }
  }

  const updateMeasure = (measure: ReportSetting['measure']): void => {
    if (reportSetting) {
      setReportSetting({ ...reportSetting, measure })
    } else {
      setReportSetting({ reportId: currentReportData.id, measure })
    }
  }

  const updateTimeGranularity = (timeGranularity: ReportSetting['timeGranularity']): void => {
    if (reportSetting) {
      setReportSetting({ ...reportSetting, timeGranularity })
    } else {
      setReportSetting({ reportId: currentReportData.id, timeGranularity })
    }
  }

  const updateLimit = (limit: number): void => {
    if (reportSetting) {
      setReportSetting({ ...reportSetting, limit })
    } else {
      setReportSetting({ reportId: currentReportData.id, limit })
    }
  }

  const handleHover: MouseEventHandler<HTMLDivElement> | undefined = (e) => {
    switch (e.type) {
      case 'mouseenter':
        setHoveringOverGraph(true)
        break
      case 'mouseleave':
        setHoveringOverGraph(false)
        break
      default:
        break
    }
  }

  const handleSwitchAxis = useCallback(() => {
    setSwitchAxis(!isSwitchedAxisRef.current)
  }, [switchAxis, setSwitchAxis])

  useEffect(() => {
    isSwitchedAxisRef.current = switchAxis
  }, [switchAxis])

  const handleToggleLegend = useCallback(() => {
    setShowLegend(!isShowLegendToggled.current)
  }, [showLegend, setShowLegend])

  useEffect(() => {
    isShowLegendToggled.current = showLegend
  }, [showLegend])

  const handleGraphWidthUpdate = useCallback(() => {
    if (updateGraphWidth && reportToBoard) {
      updateGraphWidth(reportToBoard)
      setHoveringOverGraph(false)
      setResizeTooltipVisible(false)
    }
  }, [updateGraphWidth, reportToBoard])

  const resizeIconProps = useMemo(() => {
    return {
      onClick: handleGraphWidthUpdate,
      width: 16,
      height: 16,
      color: CINDER_BLUE_50,
      className: classes.resizeIcon,
    }
  }, [updateGraphWidth, reportToBoard])

  return (
    <div
      key={currentReportData.id}
      className={classNames(classes.wrapper, { [classes.shadow]: isDraggingGraph })}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {editable && (
        <div style={{ width: 24 }}>
          <Tooltip
            title={t('reports.reorder-tooltip-title')}
            subTitle={t('reports.reorder-tooltip-content')}
            showArrow
            open={dragTooltipVisible}
            onOpenChange={onDragTooltipToggle}
          >
            <div
              className={classNames('drag', classes.graphDragIconWrapper, {
                [classes.active]: hoveringOverGraph,
                [classes.dragging]: isDraggingGraph,
              })}
            >
              <SixDotsVertical width={16} height={16} />
            </div>
          </Tooltip>
        </div>
      )}
      <div className={classes.reportContainer}>
        <div className={classes.reportWrapper}>
          <div className={classes.header}>
            <div className={classes.headerContainer}>
              <Title size="sm" className={classes.title}>
                {currentReportData.title || ''}
                {currentReportData.description && (
                  <Tooltip title={currentReportData.description}>
                    <HiOutlineInformationCircle
                      className={classes.infoIcon}
                      color={COZERO_BLUE_80}
                    />
                  </Tooltip>
                )}
              </Title>
            </div>
            <div className={classes.dropdownSelectorsContainer}>
              {!reportLoading && editable && (
                <GraphExportDropdown
                  disableDelete={disableDelete}
                  view={view}
                  chartRef={chartRef}
                  svgChartRef={svgChartRef}
                  secondaryChartRef={secondaryChartRef}
                  secondarySvgChartRef={secondarySvgChartRef}
                  switchAxis={handleSwitchAxis}
                  toggleLegend={handleToggleLegend}
                  onDelete={selectedBoard?.type !== 'reports' ? deleteReport : undefined}
                  report={currentReportData}
                  graphDataAvailable={graphDataAvailable}
                />
              )}
              {updateGraphWidth &&
                reportToBoard &&
                currentReportData.type !== ReportType.CARBON_ACTIONS && (
                  <Tooltip
                    title={t('reports.resize-tooltip.title')}
                    subTitle={
                      reportToBoard?.width === 1
                        ? t('reports.resize-tooltip.expand')
                        : t('reports.resize-tooltip.shrink')
                    }
                    showArrow
                    open={resizeTooltipVisible}
                    onOpenChange={setResizeTooltipVisible}
                  >
                    {reportToBoard?.width === 2 ? (
                      <ArrowsPointingInIcon {...resizeIconProps} />
                    ) : (
                      <ArrowsPointingOutIcon {...resizeIconProps} />
                    )}
                  </Tooltip>
                )}
              {graphDataAvailable && (
                <GraphSelectorDropdown setView={saveView} view={view} report={currentReportData} />
              )}
              {currentReportData.limitSelector && graphDataAvailable && (
                <Select
                  variant="borderless"
                  options={[
                    {
                      key: 10,
                      label: '10',
                      value: 10,
                    },
                    {
                      key: 25,
                      label: '25',
                      value: 25,
                    },
                    {
                      key: 50,
                      label: '50',
                      value: 50,
                    },
                    {
                      key: 100,
                      label: '100',
                      value: 100,
                    },
                    {
                      key: 250,
                      label: '250',
                      value: 250,
                    },
                    {
                      key: 10000,
                      label: 'All',
                      value: -1,
                    },
                  ]}
                  value={reportSetting?.limit ?? currentReportData.cubeJSQuery?.limit ?? 10}
                  onChange={(limit) => updateLimit(limit)}
                />
              )}
              {currentReportData.timeGranularity && graphDataAvailable && (
                <Select
                  variant="borderless"
                  options={[
                    {
                      key: 'month',
                      label: t('reports.timeDimensions.month'),
                      value: 'month',
                    },
                    {
                      key: 'quarter',
                      label: t('reports.timeDimensions.quarter'),
                      value: 'quarter',
                    },
                    {
                      key: 'year',
                      label: t('reports.timeDimensions.year'),
                      value: 'year',
                    },
                  ]}
                  value={
                    reportSetting?.timeGranularity ??
                    currentReportData.cubeJSQuery?.timeDimensions?.[0]?.granularity
                  }
                  onChange={(granularity) => updateTimeGranularity(granularity)}
                />
              )}
              {currentReportData.dimensions?.length > 0 && (
                <Select
                  variant="borderless"
                  options={currentReportData.dimensions?.map((dimension) => ({
                    label: i18n.exists(`common:reports.keys.${dimension}`)
                      ? t(`reports.keys.${dimension}`)
                      : dimension,
                    value: dimension,
                    key: dimension,
                  }))}
                  value={
                    (reportSetting?.dimension
                      ? reportSetting?.dimension
                      : currentReportData.cubeJSQuery.dimensions?.[0]
                      ? currentReportData.cubeJSQuery.dimensions[0]
                      : Array.isArray(currentReportData.dimensions) &&
                        currentReportData.dimensions[0]) ?? ''
                  }
                  onChange={(dimension) => updateDimension(dimension)}
                />
              )}
              {currentReportData.measures?.length > 0 && graphDataAvailable && (
                <Select
                  variant="borderless"
                  options={currentReportData.measures?.map((measure) => ({
                    label: i18n.exists(`common:reports.legend.${measure}`)
                      ? t(`reports.legend.${measure}`)
                      : i18n.exists(`common:reports.keys.${measure}`)
                      ? t(`reports.keys.${measure}`)
                      : measure,
                    value: measure,
                    key: measure,
                  }))}
                  value={
                    reportSetting?.measure ??
                    (Array.isArray(currentReportData.measures) && currentReportData.measures[0])
                  }
                  onChange={(measure) => updateMeasure(measure)}
                />
              )}
            </div>
          </div>
          <Divider color="light" />
          {isDraggingGraph && (
            <div className={classes.draggingPillLabel}>
              <Pill icon={<HiArrowsExpand size={16} />} color="cinder-blue-80">
                {t('reports.drag-label')}
              </Pill>
            </div>
          )}
        </div>
        <SizeMe key={currentReportData.id} monitorWidth monitorHeight refreshMode="throttle">
          {({ size }) => {
            return (
              <div
                className={classNames(classes.chartWrapper, {
                  [classes.dragging]: isDraggingGraph,
                })}
              >
                {isRefetching ? (
                  <LoadingSpinner
                    className={classes.calculatingSpinner}
                    title={t('share.reports.calculating.title')}
                    text={t('share.reports.calculating.text', { joinArrays: '\n' })}
                  />
                ) : reportLoading ? (
                  <LoadingSpinner title={t('share.reports.loading')} />
                ) : graphError ? (
                  <ErrorGraphView />
                ) : !graphDataAvailable && currentReportData.type !== ReportType.FORECAST ? (
                  <EmptyGraphView />
                ) : (
                  <ChartContainer
                    chartRef={chartRef}
                    svgChartRef={svgChartRef}
                    secondaryChartRef={secondaryChartRef}
                    secondarySvgChartRef={secondarySvgChartRef}
                    height={size.height}
                    report={currentReportData}
                    view={view}
                    reportSetting={reportSettings.find(
                      (reportSetting) =>
                        reportSetting.reportId.toString() === currentReportData.id.toString(),
                    )}
                    showColumns={showColumns ?? true}
                    showFilters={showFilters ?? false}
                    showLegend={showLegend ?? true}
                    switchAxis={switchAxis}
                  />
                )}
              </div>
            )
          }}
        </SizeMe>
      </div>
    </div>
  )
}

export default ReportContainer
