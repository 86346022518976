import React, { ReactElement, ReactNode, useMemo } from 'react'

import { Tooltip } from '@antv/g2plot'

import { BORDER_RADIUS_BASE, SHADOW_MD } from '@/styles/variables'

import classes from './GraphTooltip.module.less'

type TooltipRowData = {
  color: string
  value: string | ReactElement
  title?: string
}

type GraphTooltipProps = {
  title?: string
  data: TooltipRowData[]
  footer?: ReactElement | null
}

const GraphTooltip = ({ title, data, footer }: GraphTooltipProps): JSX.Element => {
  const renderedTooltipRows = useMemo(
    (): ReactNode =>
      data
        .filter(({ title }) => title)
        .map(
          (datum): JSX.Element => (
            <div key={datum.color} className={classes.tooltipRow}>
              <div className={classes.legend}>
                <div className={classes.dot} style={{ backgroundColor: datum.color }} />

                {datum.title && <div className={classes.legendTitle}>{datum.title}</div>}

                {!datum.title && <div className={classes.value}>{datum.value}</div>}
              </div>

              {datum.title && <div className={classes.value}>{datum.value}</div>}
            </div>
          ),
        ),
    [data],
  )

  return (
    <div className={classes.tooltip}>
      {title && <div className={classes.tooltipHeader}>{title}</div>}

      <div className={classes.tooltipBody}>{renderedTooltipRows}</div>

      {footer && <div className={classes.tooltipFooter}>{footer}</div>}
    </div>
  )
}

const defaultConfig: Tooltip = {
  domStyles: {
    'g2-tooltip': {
      padding: 'none',
      boxShadow: SHADOW_MD,
      borderRadius: BORDER_RADIUS_BASE,
      fontFamily: 'Inter, sans-serif',
      opacity: 1,
    },
  },
}

export default Object.assign(GraphTooltip, { defaultConfig })
