import React, { ReactElement, ReactNode, memo, useMemo } from 'react'

import { Col, Row } from 'antd/es'

import { File } from '@cozero/models'

import Tooltip from '@/atoms/Tooltip'

import classes from './Card.module.less'

type CardTypes = 'flat' | 'default' | 'skeleton'
export interface Props {
  /**
   * The card type
   */
  type?: CardTypes
  /**
   * The title of the card
   */
  title?: string | ReactNode
  /**
   * Card description
   */
  description?: string
  /**
   * The icon to add to the title
   */
  icon?: Partial<File> | string
  /**
   * The cover element added to the card
   * Covers must have a border-radius of 8px
   */
  cover?: ReactNode
  /**
   * The shadow added to the card, defualt: "sm"
   */
  shadow?: 'none' | 'sm' | 'md' | 'lg'
  /**
   * Extras are added to the top right corner of the card.
   */
  extra?: ReactNode[]

  /**
   * Which background color should the card have?
   **/
  bgColor?: 'default' | 'cinder-blue'
  /**
   * Lift up when hovering card
   */
  hoverable?: boolean
  /**
   * Extra classnames added to the card
   */
  className?: string
  /**
   * Function called when the card is clicked
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  /**
   * Content of the card
   */
  children?: React.ReactNode
  /**
   * Actions added to the bottom of the card
   */
  actions?: React.ReactNode[]
  /**
   * Classes added to the Card body
   */
  bodyClassName?: string
  /**
   * Show or hide tooltip
   */
  hideTooltip?: boolean
}

const BG_COLOR_CLASSES = {
  default: classes.bgColorDefault,
  'cinder-blue': classes.bgColorCinderBlue,
}

const TYPE_CLASSES: Record<CardTypes, string> = {
  default: classes.typeDefault,
  flat: classes.typeFlat,
  skeleton: classes.typeSkeleton,
}

const SHADOW_CLASSES = {
  none: classes.shadowNone,
  sm: classes.shadowSm,
  md: classes.shadowMd,
  lg: classes.shadowLg,
}

/**
 * A basic card component
 */
const Card = ({
  actions,
  title,
  icon,
  cover,
  extra,
  children,
  type = 'default',
  shadow = 'sm',
  bgColor = 'default',
  bodyClassName = '',
  className = '',
  hoverable,
  onClick,
  hideTooltip = false,
  ...rest
}: Props): ReactElement => {
  const cardClasses = useMemo(
    () =>
      [
        classes.card,
        TYPE_CLASSES[type],
        BG_COLOR_CLASSES[bgColor],
        SHADOW_CLASSES[shadow],
        className,
        hoverable ? classes.hoverable : '',
      ].join(' '),

    [shadow, type, bgColor, className, hoverable],
  )

  const bodyClasses = useMemo(() => [classes.cardBody, bodyClassName].join(' '), [bodyClassName])

  const Extras = (): JSX.Element | null => {
    if (extra) {
      return <div className={classes.extras}>{extra}</div>
    }
    return null
  }

  const Icon = (): JSX.Element | null => {
    if (icon) {
      return <img src={icon instanceof String ? `${icon}` : (icon as Partial<File>)?.url || ''} />
    }
    return null
  }

  const WithTooltip = ({ children }: { children: ReactNode }): JSX.Element =>
    hideTooltip ? (
      <>{children}</>
    ) : (
      <Tooltip showArrow title={title} className={classes.titleText}>
        {title}
      </Tooltip>
    )

  const CardTitle = (): JSX.Element => (
    <Row>
      <Col span={24}>
        <Row justify="space-between" align="middle" gutter={[5, 5]}>
          <Col span={extra ? 20 : 24}>
            <Row align="middle" justify="start">
              <Col span={5}>
                <Icon />
              </Col>
              <Col span={icon ? 19 : 24} className={classes.titleWrapper}>
                <WithTooltip>{title}</WithTooltip>
              </Col>
            </Row>
          </Col>
          {extra && (
            <Col span={4}>
              <Extras />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )

  return (
    <div onClick={onClick} data-cy={title} className={cardClasses} {...rest}>
      {cover && <div className={classes.cover}>{cover}</div>}
      <div className={bodyClasses}>
        {title && !cover && (
          <div className={classes.cardTitle}>
            <CardTitle />
          </div>
        )}
        {children}
      </div>
      {actions && actions.length > 0 && <div className={classes.actions}>{actions}</div>}
    </div>
  )
}
export default memo(Card)
