import '@csstools/normalize.css'
import 'antd/dist/antd.less'
import 'core-js/es/global-this'
import 'core-js/features/array/at'
import 'moment/locale/de'

import React, { ReactElement, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import mixpanel from 'mixpanel-browser'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import { SWRConfig } from 'swr'

import ErrorBoundary from '@/templates/ErrorBoundary'
import Router from '@/templates/Router'

import AppCrashFallbackUi from '@/pages/AppCrashFallbackUi'

import AdminProvider from '@/contexts/admin'
import AntDesignConfigProvider from '@/contexts/ant-design'
import AppContextProvider from '@/contexts/app'
import BoardsProvider from '@/contexts/boards'
import FactorContextProvider from '@/contexts/factor'
import {
  default as SubscriptionProvider,
  default as SubscriptionsContextProvider,
} from '@/contexts/subscription'
import store, { persistedStore, useAppSelector } from '@/redux'
import { selectUser, useLoginWithJwtTokenMutation } from '@/redux/auth'
import { resetAuthState } from '@/redux/auth/slice'
import { resetSelectedBusinessUnit } from '@/redux/businessUnits'
import { config } from '@/utils/config'
import fetcher from '@/utils/swr-fetcher'
import { reportWebVitals, sendToVercelAnalytics } from '@/utils/vercel'

import '@/utils/sentry'
import '@/index.css'

import useUserLocale from './hooks/useUserLocale'

const BaseLayout = (): ReactElement => {
  const user = useAppSelector(selectUser)
  const dispatch = useDispatch()
  const [loginWithToken] = useLoginWithJwtTokenMutation()

  useUserLocale()

  /**
   * Disable tracking if the user
   * is a cozero team member
   */
  const disabled =
    user?.role?.type === 'cozero-admin' ||
    user?.email?.split('@')[1] === 'cozero.io' ||
    (user?.organization?.metadata as { mode: string })?.mode !== 'live'

  mixpanel.init(config.VITE_MIXPANEL_ID ?? 'dev', {
    ip: false,
    api_host: 'https://api-eu.mixpanel.com',
    debug: disabled,
  })

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries()) as { token: string }
  const pathname = window.location.pathname

  const validateToken = async (token: string): Promise<void> => {
    await loginWithToken(token)
  }

  useEffect(() => {
    // To login suppliers
    if (params.token && pathname === config.routes.onboarding) {
      dispatch(resetAuthState())
      dispatch(resetSelectedBusinessUnit())
      validateToken(params.token)
    }
  }, [params.token, pathname])

  useEffect(() => {
    if (config.VITE_NODE_ENV === 'production' && mixpanel && user?.id) {
      mixpanel.identify(`${user.id}`)
      mixpanel.register({
        user_id: user.id,
        created_at: user.createdAt,
        organization_id: user.organization?.id,
        pricing: user.organization?.pricing?.name,
        industry: user.organization?.industry?.name,
        size: user.organization?.totalEmployees,
        role: user.role?.name,
        locale: user.locale,
        test: disabled,
      })
      mixpanel.people.set({
        user_id: user.id,
        created_at: user.createdAt,
        organization_id: user.organization?.id,
        pricing: user.organization?.pricing?.name,
        industry: user.organization?.industry?.name,
        size: user.organization?.totalEmployees,
        role: user.role?.name,
        locale: user.locale,
        test: disabled,
      })
      mixpanel.track('Session start')
    }
  }, [user?.id])

  return (
    <IntercomProvider appId="qopytuvo" apiBase="https://api-iam.intercom.io">
      <BrowserRouter>
        <AppContextProvider>
          <AdminProvider>
            <SubscriptionProvider>
              <BoardsProvider>
                <FactorContextProvider>
                  <SubscriptionsContextProvider>
                    <Router />
                  </SubscriptionsContextProvider>
                </FactorContextProvider>
              </BoardsProvider>
            </SubscriptionProvider>
          </AdminProvider>
        </AppContextProvider>
      </BrowserRouter>
    </IntercomProvider>
  )
}

const App = (): JSX.Element => (
  <ErrorBoundary FallbackComponent={AppCrashFallbackUi} childName="Application" showDialog>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <SWRConfig
          value={{
            fetcher,
            revalidateOnFocus: false,
          }}
        >
          <AntDesignConfigProvider>
            <BaseLayout />
          </AntDesignConfigProvider>
        </SWRConfig>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)

if (config.VITE_NODE_ENV === 'production') {
  reportWebVitals(sendToVercelAnalytics)
}
