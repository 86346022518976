import { Moment } from 'moment'

import { BusinessUnit, Geolocation, Location, Page, PageFilter } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import {
  TAG_ACTIVE_LOCATIONS,
  TAG_GEOLOCATIONS,
  TAG_LOCATIONS,
  TAG_PAGINATED_LOCATIONS,
} from './tags'

export const GET_LOCATION = 'getLocation'
export const GET_LOCATIONS = 'getLocations'
export const GET_PAGINATED_LOCATIONS = 'getPaginatedLocations'
export const GET_LOCATIONS_COUNT = 'getLocationsCount'
export const GET_ACTIVE_LOCATIONS = 'getActiveLocations'
export const GET_LOCATIONS_LOGS = 'getLocationsLogs'
export const CREATE_LOCATION = 'createLocation'
export const EDIT_LOCATION = 'editLocation'
export const DELETE_LOCATION = 'deleteLocation'
export const GET_ADDRESS = 'getAddress'

const locationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_PAGINATED_LOCATIONS]: builder.query<
      Page<Location> | undefined,
      {
        page: number
        pageSize: number
        businessUnitKey: string
        activeFilter?: boolean
        search?: string
        filters?: PageFilter[]
      }
    >({
      query: ({ businessUnitKey, search, activeFilter, page, pageSize, filters }) => ({
        url: centralApiGatewayClient.locations.SEARCH,
        method: 'POST',
        params: {
          businessUnitKey,
          search,
          activeFilter,
          page,
          pageSize,
        },
        data: { filters },
      }),
      providesTags: (result) =>
        providesList<Location[], typeof TAG_PAGINATED_LOCATIONS>(
          result?.data,
          TAG_LOCATIONS,
          'PARTIAL-LIST',
        ),
    }),
    [GET_LOCATIONS]: builder.query<
      Location[] | undefined,
      { businessUnitKey: string; activeFilter?: boolean; search?: string }
    >({
      query: ({ businessUnitKey, search, activeFilter }) => ({
        url: centralApiGatewayClient.locations.SEARCH,
        method: 'POST',
        params: {
          businessUnitKey,
          search,
          activeFilter,
        },
      }),
      providesTags: (result) =>
        providesList<Location[], typeof TAG_LOCATIONS>(result, TAG_LOCATIONS),
    }),
    [GET_ACTIVE_LOCATIONS]: builder.query<
      Location[] | undefined,
      { businessUnitKey: string; search?: string }
    >({
      query: ({ businessUnitKey, search }) => ({
        url: centralApiGatewayClient.locations.SEARCH,
        method: 'POST',
        params: {
          businessUnitKey,
          search,
          activeFilter: true,
        },
      }),
      providesTags: (result) =>
        providesList<Location[], typeof TAG_ACTIVE_LOCATIONS>(result, TAG_ACTIVE_LOCATIONS),
    }),
    [GET_LOCATIONS_COUNT]: builder.query<number, { businessUnitKey: BusinessUnit['key'] }>({
      query: ({ businessUnitKey }) => ({
        url: centralApiGatewayClient.locations.COUNT,
        method: 'GET',
        params: {
          businessUnitKey,
        },
      }),
    }),
    [GET_LOCATION]: builder.query<Location, string>({
      query: (id) => ({
        url: centralApiGatewayClient.locations.GET_ONE.replace(':id', id),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_LOCATIONS, id: result?.id }],
    }),
    [GET_ADDRESS]: builder.query<Geolocation[] | void, string>({
      query: (search) => ({
        url: centralApiGatewayClient.locations.GET_ADDRESS,
        method: 'GET',
        params: {
          search,
        },
      }),
      providesTags: () => [{ type: TAG_GEOLOCATIONS, id: 'LIST' }],
    }),
    [GET_LOCATIONS_LOGS]: builder.mutation<
      Location,
      { id: Location['id']; dateRange: [Moment | null, Moment | null] }
    >({
      query: ({ id, dateRange }) => ({
        url: centralApiGatewayClient.locations.GET_ONE_WITH_LOG_OVERVIEW.replace(':id', `${id}`),
        method: 'GET',
        params: {
          id,
          startDate: dateRange?.[0]?.startOf('month').toISOString(),
          endDate: dateRange?.[1]?.endOf('month').toISOString(),
        },
      }),
      invalidatesTags: [{ type: TAG_LOCATIONS, id: 'LIST' }],
    }),
    [CREATE_LOCATION]: builder.mutation<Location, Partial<Location>>({
      query: (location) => ({
        url: centralApiGatewayClient.locations.CREATE,
        method: 'POST',
        data: location,
      }),
      invalidatesTags: [
        { type: TAG_LOCATIONS, id: 'LIST' },
        { type: TAG_ACTIVE_LOCATIONS, id: 'LIST' },
        { type: TAG_PAGINATED_LOCATIONS, id: 'PARTIAL-LIST' },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    [EDIT_LOCATION]: builder.mutation<
      Location,
      { id: Location['id']; location: Partial<Location> }
    >({
      query: ({ id, location }) => ({
        url: centralApiGatewayClient.locations.UPDATE_ONE.replace(':id', id.toString() ?? ''),
        method: 'PUT',
        data: location,
      }),
      invalidatesTags: (result) => [
        { type: TAG_LOCATIONS, id: result?.id },
        { type: TAG_ACTIVE_LOCATIONS, id: result?.id },
        { type: TAG_PAGINATED_LOCATIONS, id: result?.id },
        { type: TAG_PAGINATED_LOCATIONS, id: 'PARTIAL-LIST' },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    [DELETE_LOCATION]: builder.mutation<Location, Location['id']>({
      query: (id) => ({
        url: centralApiGatewayClient.locations.DELETE_ONE.replace(':id', id?.toString() ?? ''),
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: TAG_LOCATIONS, id: 'LIST' },
        { type: TAG_ACTIVE_LOCATIONS, id: 'LIST' },
        { type: TAG_PAGINATED_LOCATIONS, id: 'PARTIAL-LIST' },
      ],
    }),
  }),
})

export const {
  useGetLocationQuery,
  useGetLocationsQuery,
  useGetActiveLocationsQuery,
  useGetLocationsCountQuery,
  useGetPaginatedLocationsQuery,
  useGetAddressQuery,
  useGetLocationsLogsMutation,
  useLazyGetAddressQuery,
  useLazyGetLocationQuery,
  useLazyGetLocationsQuery,
  useLazyGetPaginatedLocationsQuery,
  useLazyGetActiveLocationsQuery,
  useLazyGetLocationsCountQuery,
  useCreateLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
  usePrefetch,
} = locationsApiSlice

export default locationsApiSlice
