import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useNavigate } from 'react-router'

import { Spin } from 'antd/es'
import {
  ColumnsType,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface'

import { omit } from 'lodash-es'

import { BusinessUnit, LogSorter, PageFilter, TableViewProductLog } from '@cozero/models'

import Table from '@/molecules/Table'

import useGenerateData from '@/hooks/useGenerateData'
import useProductData from '@/hooks/useProductData'
import { config } from '@/utils/config'

import classes from './classes.module.less'
import useProductTableColumns from './useProductTableColumns'

interface Props {
  featuresAllowed: string[]
  filters?: PageFilter[]
  sorters?: LogSorter[]
  currentPage: number
  pageSize: number
  defaultPageSize?: number
  selectedBusinessUnit?: BusinessUnit
  loading: boolean
  setSort: (sort: LogSorter[]) => void
  setCurrentPage: (size: number) => void
  setPageSize: (size: number) => void
  setNoLogs?: (state: boolean) => void
  childRef?: React.MutableRefObject<{
    onRefreshTable: () => void
  }>
}

const ProductDataTable = ({
  childRef,
  featuresAllowed,
  filters,
  sorters,
  pageSize,
  defaultPageSize = 15,
  currentPage,
  selectedBusinessUnit,
  loading: logLoading,
  setPageSize,
  setCurrentPage,
  setNoLogs,
  setSort,
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const [tableSorter, setTableSorter] = useState<LogSorter[]>([])
  const { data, loading, mutate } = useProductData({
    type: 'product',
    page: currentPage || 1,
    pageSize,
    filters: filters?.map((obj) => omit(obj, 'options')) || [],
    sorters: sorters?.filter((x) => x.logType === 'product'),
    businessUnitKey: selectedBusinessUnit?.key,
  })
  const { generateColumns } = useGenerateData()
  const { columnsData, deletedProduct } = useProductTableColumns({
    tableSorter,
    businessUnitsAllowed: featuresAllowed?.includes('business-units') || false,
  })

  useEffect(() => {
    if (deletedProduct) {
      mutate()
    }
  }, [deletedProduct])

  useImperativeHandle(childRef, () => ({
    onRefreshTable: () => {
      mutate()
    },
  }))

  useEffect(() => {
    generateColumns(columnsData)
  }, [tableSorter])

  const changeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TableViewProductLog> | SorterResult<TableViewProductLog>[],
  ): void => {
    if (pagination.current && pagination.current !== currentPage) {
      setCurrentPage(pagination.current)
    } else {
      if (Array.isArray(sorter)) {
        const sortersAux: LogSorter[] = sorter.map((x) => ({
          id: new Date().getTime().toString(),
          key: (x.column?.key || 'createdAt') as string,
          label: x.column?.key?.toString() || 'createdAt',
          selectedSort: x.order === 'descend' ? 'desc' : 'asc',
          logType: 'product',
        }))
        setSort(sortersAux)
        setTableSorter(sortersAux)
      } else {
        if (sorter && Object.keys(sorter).length) {
          const aux = {
            id: new Date().getTime().toString(),
            logType: 'product',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            key: (sorter.column?.key || 'createdAt') as any,
            label: sorter.column?.key?.toString() || 'createdAt',
            selectedSort: sorter.order === 'descend' ? 'desc' : 'asc',
          }
          setSort([aux as LogSorter])
          setTableSorter([aux as LogSorter])
        }
      }
    }
  }

  const navigateToNewTab = useCallback((location: string): void => {
    const newWindow = window.open(location, '_blank', 'noopener,noreferrer')
    if (newWindow) {
      newWindow.opener = null
    }
  }, [])

  useEffect(() => {
    if (!loading && !logLoading && data?.totalProducts && data?.totalProducts === 0 && setNoLogs) {
      setNoLogs(true)
    }
  }, [loading, logLoading, data])

  return (
    <Spin spinning={loading}>
      <Table
        scroll={{ x: 1200 }}
        columns={columnsData?.filter(Boolean) as ColumnsType<TableViewProductLog>}
        dataSource={data?.products}
        rowKey="id"
        className={classes.table}
        rowClassName={classes.tableRow}
        onChange={changeTable}
        onRow={(record) => {
          return {
            onContextMenu: () =>
              navigateToNewTab(
                `${config.routes.log.carbonFootprint.products.productDetails.replace(
                  ':productId',
                  record.id.toString(),
                )}`,
              ),
            onClick: (event) => {
              if (event.ctrlKey || event.metaKey) {
                navigateToNewTab(
                  `${config.routes.log.carbonFootprint.products.productDetails.replace(
                    ':productId',
                    record.id.toString(),
                  )}`,
                )
              } else {
                navigate(
                  `${config.routes.log.carbonFootprint.products.productDetails.replace(
                    ':productId',
                    record.id.toString(),
                  )}`,
                )
              }
            },
          }
        }}
        pagination={{
          current: currentPage,
          defaultPageSize,
          pageSize,
          total: data?.totalProducts,
          onShowSizeChange: (_current, size) => setPageSize(size),
          // hideOnSinglePage: true,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        showWrapper={false}
      />
    </Spin>
  )
}

export default forwardRef(ProductDataTable)
