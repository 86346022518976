import React from 'react'
import { HiOutlineLocationMarker, HiOutlineOfficeBuilding, HiPlus, HiX } from 'react-icons/hi'

import TargetList from '@/molecules/TargetList'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import classes from './classes.module.less'

export interface EmptyStateCardProps {
  title: string
  description: string
  buttonText: string
  type: 'location' | 'businessUnit' | 'logs' | 'targets' | 'graphs'
  onClick: () => void
  onClose?: () => void
  className?: string
}

export function EmptyStateCard({
  title,
  description,
  buttonText,
  type,
  onClick,
  onClose,
  className,
}: EmptyStateCardProps): JSX.Element {
  const Icon = (): JSX.Element => {
    switch (type) {
      case 'businessUnit':
        return <HiOutlineOfficeBuilding />
      case 'location':
        return <HiOutlineLocationMarker />
      default:
        return <HiPlus />
    }
  }

  const onCloseHandler = (): void => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Card
      className={`${className ? className : ''} ${classes.onboardingComponentCard}`}
      shadow="none"
    >
      <div className={classes.header}>
        <Title size="sm" className={classes.title}>
          {title}
        </Title>
        {onClose && (
          <Button
            type="text"
            onClick={onCloseHandler}
            category="onboarding"
            action={`Close ${type} onboarding card without finishing`}
          >
            <HiX className={classes.icon} />
          </Button>
        )}
      </div>
      <div className={classes.bodyWrapper} style={{ display: 'flex', flexDirection: 'column' }}>
        <Text size="xl" className={classes.subtitle}>
          {description}
        </Text>
        {type === 'targets' && <TargetList />}
        <Button
          type="primary"
          category="onboarding"
          action={`Start ${type} onboarding`}
          className={classes.cardBtn}
          onClick={onClick}
          prefixIcon={<Icon />}
        >
          {buttonText}
        </Button>
      </div>
    </Card>
  )
}
