import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Modal, Popconfirm, Spin, Typography, message } from 'antd/es'

import produce from 'immer'

import { BusinessUnit, Organization, Role, User } from '@cozero/models'

import ConfirmationModal from '@/organisms/ConfirmationModal'

import BusinessUnitsDropdown from '@/molecules/BusinessUnitsDropdown'
import OnboardingStepModal, { onboardingModalStyle } from '@/molecules/OnboardingStepModal'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'

import { useAdminContext } from '@/contexts/admin'
import { useSubscriptionContext } from '@/contexts/subscription'
import { useAppDispatch, useAppSelector } from '@/redux'
import {
  getFeaturesAllowed,
  getIsAdmin,
  selectUserOrganization,
  useLazyGetUserDataQuery,
} from '@/redux/auth'
import {
  selectSelectedBusinessUnit,
  selectSelectedBusinessUnitKey,
  useGetActiveBusinessUnitsQuery,
} from '@/redux/businessUnits'
import { selectSteps, setSteps, useUpdateOnboardingMutation } from '@/redux/onboarding'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from '@/redux/users'
import { config } from '@/utils/config'

import { LocationDetails } from '../UpsertLocation'

import classes from './classes.module.less'

const UpsertUser = (): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const location = useLocation()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const organization = useAppSelector(selectUserOrganization)
  const isAdmin = useAppSelector(getIsAdmin)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const [form] = Form.useForm()
  const { id } = useParams()
  const { roles, getRoles, getUserFunctions, userFunctions } = useAdminContext()
  const businessUnitKey = useAppSelector(selectSelectedBusinessUnitKey)
  const {
    data: user,
    isLoading: loadingUser,
    error,
  } = useGetUserQuery(parseInt(id ?? ''), { skip: !id })
  const { data: businessUnits = [], isLoading: loadingBusinessUnits } =
    useGetActiveBusinessUnitsQuery(
      { businessUnitKey: businessUnitKey ?? '', root: false },
      { skip: !businessUnitKey },
    )
  const { data: users } = useGetOrganizationUsersQuery(
    { businessUnit: businessUnitKey ?? '' },
    { skip: !businessUnitKey },
  )
  const searchParams = new URLSearchParams(location.search)
  const businessUnitId = parseInt(searchParams.get('businessUnitId') || '')
  const [deleteUser] = useDeleteUserMutation()
  const [updateUser] = useUpdateUserMutation()
  const [createUser] = useCreateUserMutation()
  const [selectedUserBusinessUnit, setSelectedUserBusinessUnit] = useState<number>(
    user?.businessUnit ? user.businessUnit?.id : businessUnitId,
  )
  const [selectedUserFunctions, setSelectedUserFunctions] = useState<number[]>(
    user ? user?.userFunctions.map((obj) => obj.id) : [],
  )
  const notAllowed = user?.role?.type === 'admin' && !isAdmin
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const [availableRoles, setAvailableRoles] = useState<Role[]>([])
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<User>()
  const [userDetails, setUserDetails] = useState<LocationDetails>()
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useAppDispatch()
  const [getMe] = useLazyGetUserDataQuery()
  const steps = useAppSelector(selectSteps)
  const [updateOnboarding] = useUpdateOnboardingMutation()

  function goBack(): void {
    navigate(-1)
  }

  const handleUpdateSteps = useCallback(async () => {
    const stepIndex = steps?.findIndex((step) => step.onboardingStep.key === 'users')
    if (stepIndex === undefined && stepIndex !== -1) {
      throw new Error('Step not found')
    }
    const clonedSteps = steps ? [...steps] : []
    const updatedSteps = produce(clonedSteps, (draft) => {
      draft[stepIndex].completed = false
    })
    const data = await updateOnboarding({
      steps: updatedSteps,
    }).unwrap()
    if (data) {
      await getMe().unwrap()
      dispatch(setSteps(data))
      Modal.success({
        content: <OnboardingStepModal stepKey="users" />,
        ...onboardingModalStyle,
      })
    }
  }, [])

  async function onDeleteUser(): Promise<void> {
    if (user) {
      if (users?.length === 2) {
        await handleUpdateSteps()
      }
      await deleteUser(user.id)
    }
    goBack()
  }

  const handleUpsertUser = async (values: Partial<User>): Promise<void> => {
    try {
      setSubmitting(true)
      const savedUserFunctions = userFunctions.filter((obj) =>
        selectedUserFunctions.includes(obj.id),
      )
      if (id) {
        const updatedUser = await updateUser({
          ...user,
          ...values,
          userFunctions: savedUserFunctions,
          organizationId: (user?.organization as Organization)?.id,
          businessUnit: { id: selectedUserBusinessUnit } as BusinessUnit,
        }).unwrap()

        if (updatedUser) {
          message.success(t('actions.update.item', { item: t('settings.users.user') }))
          return navigate(config.routes.settings.users)
        }
      } else {
        const isSecondUser = users?.length === 1
        const newUser = await createUser({
          ...values,
          userFunctions: savedUserFunctions,
          businessUnit: { id: selectedUserBusinessUnit } as BusinessUnit,
        }).unwrap()

        if (newUser) {
          message.success(t('actions.add.success', { item: t('settings.users.user') }))
          return navigate(
            config.routes.settings.users,
            isSecondUser ? { state: { finishOnboarding: true } } : undefined,
          )
        }
      }
    } catch (e) {
      if (e.status === 409) {
        return message.success(t('settings.users.already-invited'))
      }
      return message.success(t('actions.add.failed', { item: t('settings.users.user') }))
    } finally {
      setSubmitting(false)
    }
  }

  async function onFormFinish(values: User): Promise<void> {
    const valid = await form.validateFields()
    if ((user && user.id) || !featuresAllowed?.includes('business-units')) {
      await handleUpsertUser(values)
    } else {
      if (valid) {
        const businessUnit = businessUnits.find(
          (businessUnit) => businessUnit.id === selectedUserBusinessUnit,
        )
        const role = availableRoles.find((role) => role.id === values.role?.id)
        setIsFormValid(true)
        setFormValues(values)
        setUserDetails({
          name: `${values.firstName ?? ''} ${values.lastName ?? ''}`,
          email: values.email ?? '',
          businessUnitName: businessUnit?.title ?? '',
          role: role?.name ?? '',
        })
        setOpenConfirmationModal(!openConfirmationModal)
      }
    }
  }

  useEffect(() => {
    if (error) {
      if (error.message === 'USER_LIMIT_EXCEEDED') {
        const limit = getLimit(organization, 'users')
        setSubscribeModalSettings({
          closable: true,
          title: t('subscription.upgrade-modal.title-limit', {
            limit: limit?.max,
            item: t('settings.users.title'),
          }),
          visible: true,
        })
      } else {
        message.error(error.message, 30)
      }
    }
  }, [error])

  async function onSelectUserFunction(ids: number[]): Promise<void> {
    setSelectedUserFunctions(ids)
  }

  useEffect(() => {
    form.resetFields()
    if (user?.businessUnit) {
      setSelectedUserBusinessUnit(user.businessUnit.id)
    } else if (businessUnitId) {
      setSelectedUserBusinessUnit(businessUnitId)
    } else {
      if (selectedBusinessUnit?.id) {
        setSelectedUserBusinessUnit(selectedBusinessUnit?.id)
      }
    }
    if (user?.userFunctions) {
      setSelectedUserFunctions(user?.userFunctions.map((obj) => obj.id))
    }
  }, [user, businessUnits])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      await getRoles()
      if (featuresAllowed?.includes('user-functions')) {
        await getUserFunctions()
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (roles) {
      let availableRoles = roles
      if (!featuresAllowed?.includes('business-units')) {
        availableRoles = availableRoles.filter((obj) => obj.type !== 'manager')
      }
      if (!isAdmin) {
        availableRoles = availableRoles.filter((obj) => obj.type !== 'admin')
      }
      setAvailableRoles(availableRoles)
    }
  }, [roles])

  return (
    <div className={classes.container}>
      <Spin
        spinning={loadingUser || loadingBusinessUnits || submitting}
        wrapperClassName={classes.spinner}
      >
        <div className={classes.subContainer}>
          <Form
            category={'users'}
            layout="vertical"
            initialValues={user}
            form={form}
            onFinish={onFormFinish}
            className={classes.formContainer}
          >
            <Typography className={classes.formTitle} data-cy="edit-user-btn">
              {user
                ? `${t('settings.users.edit-user')} - ${user.email}`
                : t('settings.users.create-user')}
            </Typography>
            <Form.Item label="ID" name="id" hidden />
            <Form.Item
              label={t('email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('email-required'),
                  type: 'email',
                },
              ]}
              data-cy="user-email"
            >
              <InputField />
            </Form.Item>
            {user?.metadata?.externalId && (
              <Form.Item className={classes.formItem} label={t('settings.users.product-code')}>
                <InputField disabled value={user?.metadata?.externalId} />
              </Form.Item>
            )}
            <Form.Item label={t('first-name')} name="firstName" data-cy="user-first-name">
              <InputField />
            </Form.Item>
            <Form.Item label={t('last-name')} name="lastName" data-cy="user-last-name">
              <InputField />
            </Form.Item>
            <Form.Item label={t('job')} name="jobDescription" data-cy="user-job-description">
              <InputField />
            </Form.Item>
            {featuresAllowed?.includes('business-units') && (
              <Form.Item
                label={t('business-unit.name')}
                id="businessUnit"
                name="businessUnit"
                className={classes.formItem}
                data-cy="user-business-unit"
              >
                <BusinessUnitsDropdown
                  businessUnits={businessUnits}
                  showNoParent={false}
                  defaultValue={selectedUserBusinessUnit}
                  onChange={(value) => setSelectedUserBusinessUnit(parseInt(value))}
                />
              </Form.Item>
            )}
            <Form.Item
              label={t('settings.users.role')}
              name={['role', 'id']}
              rules={[{ required: true, message: t('settings.users.role-required') }]}
              data-cy="user-role"
            >
              <Select
                dropdownMatchSelectWidth={false}
                disabled={notAllowed}
                options={availableRoles.map((role) => ({
                  key: role.id,
                  value: role.id,
                  label: role.name,
                }))}
              />
            </Form.Item>
            {featuresAllowed?.includes('user-functions') &&
            user?.role?.type !== 'admin' &&
            selectedUserFunctions ? (
              <Form.Item label={t('settings.users.user-functions')}>
                <Select
                  mode="multiple"
                  dropdownMatchSelectWidth={false}
                  disabled={notAllowed}
                  defaultValue={selectedUserFunctions}
                  value={selectedUserFunctions}
                  onChange={onSelectUserFunction}
                  filterOption={(input, option) => {
                    if (!option || !option.label) {
                      return false
                    }
                    return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  options={userFunctions.map((userFunction) => ({
                    key: userFunction.id,
                    value: userFunction.id,
                    label: userFunction.name,
                  }))}
                />
              </Form.Item>
            ) : null}

            <div className={classes.actions}>
              <Button
                category={'users'}
                action={'submit'}
                type="primary"
                htmlType="submit"
                className={classes.submitButton}
                data-cy="save-invite-user-btn"
              >
                {user?.id ? t('actions.save') : t('settings.users.invite-btn')}
              </Button>
              <Button
                category={'users'}
                action={'cancel'}
                type="secondary"
                onClick={goBack}
                className={classes.cancelButton}
              >
                {t('actions.cancel')}
              </Button>
            </div>
          </Form>
          {user && (
            <Card className={classes.dangerZone}>
              <Typography className={classes.dangerZoneTitle}>
                {t('settings.users.delete.title')}
              </Typography>
              <Typography>{t('settings.users.delete.subtitle', { email: user.email })}</Typography>
              <Popconfirm
                title={t('settings.users.delete.warning')}
                onConfirm={onDeleteUser}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Button
                  category={'users'}
                  action={'delete'}
                  type="primary"
                  color="danger"
                  className={classes.dangerZoneButton}
                  data-cy="delete-user-btn"
                >
                  {t('settings.users.delete.title')}
                </Button>
              </Popconfirm>
            </Card>
          )}
        </div>
      </Spin>
      {isFormValid && formValues && (
        <ConfirmationModal
          openModal={openConfirmationModal}
          setOpenModal={setOpenConfirmationModal}
          title={t('settings.users.modal.title')}
          onOk={() => handleUpsertUser(formValues)}
          userOrLocationDetails={userDetails}
          confirmationText={t('settings.users.modal.invite-confirm')}
        />
      )}
    </div>
  )
}

export default UpsertUser
