import React from 'react'
import { useTranslation } from 'react-i18next'

import { Cascader, Col, Row } from 'antd'

import { ActivityDataSource } from '@cozero/models'

import ActivityDataSourceLazyLoadedCascader from '@/organisms/ActivityDataSourceLazyLoadedCascader'

import Button from '@/atoms/Button'
import DatePicker from '@/atoms/DatePicker'
import Form from '@/atoms/Form'
import Label from '@/atoms/Label'
import Select, { SelectOptionsProperties } from '@/atoms/Select'
import Text from '@/atoms/Text'

import { CascaderNode } from '@/types/general'

import classes from './RequestDetails.module.less'

interface RequestDetailsProps {
  lifecycleOptions: SelectOptionsProperties[]
  territoryOptions: CascaderNode[]
  activityDataSources: ActivityDataSource[]
  unitsOptions: SelectOptionsProperties[]
  onClickAddProduct: () => void
}

const RequestDetails = ({
  lifecycleOptions,
  territoryOptions,
  activityDataSources,
  unitsOptions,
  onClickAddProduct,
}: RequestDetailsProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Row gutter={[8, 24]} className={classes.container}>
      <Col span={24}>
        <Text fontWeight="semibold" mode="title" size="xs">
          {t('suppliers.factor.request-details')}
        </Text>
      </Col>
      <Col span={7}>
        <Form.Item
          className={classes.activityDataSourceSpacing}
          label={<Label>{t('log.product')}</Label>}
          name="activityDataSourceId"
          rules={[{ required: true, message: t('suppliers.factor.validation.required-product') }]}
        >
          <ActivityDataSourceLazyLoadedCascader
            activityDataSources={activityDataSources}
            placeholder={t('products.select')}
          />
        </Form.Item>
        <Button
          className={classes.addProductButton}
          category="log-entry"
          action="add"
          onClick={onClickAddProduct}
          type="text"
          color="blue"
        >
          {t('suppliers.factor.add-product')}
        </Button>
      </Col>
      <Col offset={1} span={7}>
        <Form.Item
          label={<Label>{t('products.footprint.product-mass')}</Label>}
          name="denominatorUnitId"
          rules={[{ required: true, message: t('suppliers.factor.validation.required-unit') }]}
        >
          <Select
            options={unitsOptions}
            disabled={!unitsOptions.length}
            placeholder={t('products.footprint.unit-placeholder')}
          />
        </Form.Item>
      </Col>
      <Col offset={1} span={7}>
        <Form.Item
          label={<Label>{t('suppliers.factor.lifecycle-steps')}</Label>}
          name="productLifecycleStepIds"
        >
          <Select
            options={lifecycleOptions}
            placeholder={t('suppliers.factor.option-placeholder')}
            mode="multiple"
          />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item label={<Label>{t('year')}</Label>} name="year">
          <DatePicker
            format="YYYY"
            picker="year"
            placeholder={t('year-placeholder')}
            className={classes.datePicker}
          />
        </Form.Item>
      </Col>
      <Col offset={1} span={7}>
        <Form.Item
          label={<Label>{t('act.marketplace.solution.supplier.country')}</Label>}
          name="locationId"
        >
          <Cascader
            style={{ width: '100%' }}
            showSearch
            dropdownMatchSelectWidth={false}
            options={territoryOptions}
            placeholder={t('act.marketplace.solution.supplier.country-placeholder')}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default RequestDetails
