import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi'

import { Divider } from 'antd'
import { Col, Row, message } from 'antd/es'
import { useForm } from 'antd/es/form/Form'
import { Store } from 'antd/es/form/interface'

import { CloseOutlined, ConsoleSqlOutlined } from '@ant-design/icons'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { BusinessUnit, Supplier } from '@cozero/models'

import BusinessUnitsDropdown from '@/molecules/BusinessUnitsDropdown'
import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import { MODAL_SIZE_SM } from '@/styles/variables'

import classes from './SuppliersModal.module.less'

interface SupplierProps {
  supplier?: Supplier
  isVisible: boolean
  onClose: () => void
  createSupplier: (values: Supplier) => void
  featuresAllowed?: string[]
}

interface ContactForm {
  name: string
  email: string
  position: string
}
interface FormValues {
  email: string
  internalSupplier: string
  address: string
  name: string
  businessUnit: string
  contact: ContactForm[]
}

const REQUIRED_FIELDS: Array<keyof FormValues | `contact.${keyof ContactForm}`> = [
  'name',
  'contact.email',
]

function SuppliersModal({
  isVisible,
  onClose,
  supplier,
  createSupplier,
  featuresAllowed,
}: SupplierProps): JSX.Element {
  const { t } = useTranslation('common')
  const businessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    { businessUnitKey: businessUnit?.key ?? ' ' },
    { skip: !businessUnit?.key },
  )
  const [form] = useForm()
  const [isOkDisable, setIsOkDisable] = useState(true)
  const [internalSupplierId, setInternalSupplierId] = useState<number | undefined | null>(
    supplier?.businessUnitId,
  )
  const [selectedBusinessUnitId, setSelectedBusinessUnitId] = useState<number | undefined | null>(
    supplier?.businessUnitId,
  )

  const onSubmit = (values: Store): void => {
    if (values) {
      createSupplier({
        ...values,
        internalSupplierId,
        businessUnitId: selectedBusinessUnitId,
      } as Supplier)
    }
  }

  useEffect((): void => {
    if (supplier) {
      form.resetFields()
      form.setFieldsValue({
        ...supplier,
        businessUnit: (supplier.businessUnit as BusinessUnit)?.id,
      })
      setInternalSupplierId(supplier.businessUnitId)
    } else {
      form.resetFields()
      form.setFieldsValue({ contact: [{ email: undefined }] })
    }
  }, [supplier])

  const onError = async (store: ValidateErrorEntity<Store>): Promise<void> => {
    await message.error(store.errorFields.flatMap((errorField) => errorField.errors).join(', '))
  }

  const onValueChanges = (changedValues: Partial<FormValues>, allFields: FormValues): void => {
    const { contact } = changedValues
    if (contact) {
      allFields = { ...allFields, contact }
    }

    const disableButton = !REQUIRED_FIELDS.every((field) => {
      const [parent, child] = field.split('.') as ['contact', keyof ContactForm]
      if (Array.isArray(allFields[parent])) {
        return (allFields[parent] as ContactForm[]).every((el) => el && !!el[child])
      } else {
        return !!allFields[field as keyof FormValues]
      }
    })
    setIsOkDisable(disableButton)
  }

  return (
    <Modal
      width={MODAL_SIZE_SM}
      title={t('suppliers.create')}
      visible={isVisible}
      onOk={() => form.submit()}
      okText={t('suppliers.create')}
      okButtonProps={{ disabled: isOkDisable }}
      onCancel={onClose}
      className={classes.modalContainer}
    >
      <Form
        category="supplier"
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onError}
        onValuesChange={onValueChanges}
      >
        <Text size="xl" strong>
          {t('suppliers.company-information')}
        </Text>
        <Form.Item
          rules={[
            { required: true, message: `${t('suppliers.name')} ${t('suppliers.is-mandatory')}` },
          ]}
          label={t('suppliers.name')}
          name={'name'}
        >
          <InputField />
        </Form.Item>
        <Form.Item label={t('suppliers.address')} name={'address'}>
          <InputField />
        </Form.Item>
        {featuresAllowed?.includes('business-units') && (
          <>
            <Form.Item
              label={t('suppliers.internalSupplier')}
              id="internalSupplier"
              name="internalSupplier"
              initialValue={internalSupplierId}
            >
              <BusinessUnitsDropdown
                businessUnits={businessUnits}
                showNoParent={false}
                onChange={(value) => setInternalSupplierId(parseInt(value))}
              />
            </Form.Item>
            <Form.Item
              label={t('business-unit.name')}
              id="businessUnit"
              name="businessUnit"
              initialValue={selectedBusinessUnitId}
            >
              <BusinessUnitsDropdown
                businessUnits={businessUnits}
                showNoParent={false}
                onChange={(value) => setSelectedBusinessUnitId(parseInt(value))}
              />
            </Form.Item>
          </>
        )}

        <Text size="xl" strong>
          {t('suppliers.contact-details')}
        </Text>
        <Form.List name="contact">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => {
                return (
                  <Row key={`container_${field.key}`}>
                    <Col span={24}>
                      <Form.Item label={t('suppliers.contact_name')} name={[field.name, 'name']}>
                        <InputField />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            type: 'email',
                            required: true,
                            message: `${t('suppliers.email')} ${t('suppliers.is-mandatory')}`,
                          },
                        ]}
                        label={t('suppliers.email')}
                        name={[field.name, 'email']}
                      >
                        <InputField type={'email'} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={t('suppliers.position')}
                        name={[field.name, 'position']}
                        style={{ marginBottom: '0px' }}
                      >
                        <InputField />
                      </Form.Item>
                    </Col>
                    <Row
                      justify="space-between"
                      className={`${classes.contactActionsContainer} ${
                        fields.length > 1 && i !== fields.length - 1 && classes.deleteBtn
                      }`}
                    >
                      {i === fields.length - 1 && (
                        <Col>
                          <Button
                            category="factor-requests"
                            action="create factor request"
                            type="text"
                            color="blue"
                            className={classes.createButton}
                          >
                            {t('suppliers.add-contact')}
                          </Button>
                        </Col>
                      )}
                      {fields.length > 1 && (
                        <Col>
                          <Button
                            category="factor-requests"
                            action="create factor request"
                            type="text"
                            color="danger"
                            className={classes.createButton}
                            onClick={() => {
                              remove(field.name)
                            }}
                            prefixIcon={<HiOutlineTrash />}
                          >
                            {t('actions.delete')}
                          </Button>
                        </Col>
                      )}
                      {i !== fields.length - 1 && (
                        <Divider dashed={true} className={classes.divider} />
                      )}
                    </Row>
                  </Row>
                )
              })}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

export default SuppliersModal
