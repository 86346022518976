import { RawNodeDatum } from 'react-d3-tree/lib/types/common'

import { omit } from 'lodash-es'

import { BusinessUnit, Location } from '@cozero/models'
import { EmissionsSumGroupResultItem, OrganigramEmissionsByGroup } from '@cozero/models/src'

export function updateTreeData(
  list: BusinessUnit[],
  id: React.Key,
  children: BusinessUnit[],
): BusinessUnit[] {
  const cleanChildren = children.map((child) => {
    if (child.children?.length === 0) {
      child = omit(child, 'children') as BusinessUnit
    }

    return child
  })

  return list.map((node) => {
    if (node.id === id) {
      return {
        ...node,
        children: cleanChildren,
      }
    }

    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children as BusinessUnit[], id, cleanChildren),
      }
    }

    return node
  })
}

// Update the organigram tree with new children when fetched
export function updateOrganigramData(
  list: RawNodeDatum[],
  id: number,
  children: RawNodeDatum[],
): RawNodeDatum[] {
  return list.map((node) => {
    if (node.attributes?.id === id) {
      return { ...node, children }
    }

    if (node.children) {
      return { ...node, children: updateOrganigramData(node.children, id, children) }
    }

    return node
  })
}

const formatEmissions = (
  scopeEmissions: EmissionsSumGroupResultItem | undefined,
): {
  scope1: number
  scope2: number
  scope3: number
  totalEmissions: number
} => {
  const scope1 = Math.round(scopeEmissions?.scope.find((scope) => scope.scope === 1)?.total ?? 0)
  const scope2 = Math.round(scopeEmissions?.scope.find((scope) => scope.scope === 2)?.total ?? 0)
  const scope3 = Math.round(scopeEmissions?.scope.find((scope) => scope.scope === 3)?.total ?? 0)

  const totalEmissions = Math.round(scopeEmissions?.total ?? 0)

  return {
    scope1,
    scope2,
    scope3,
    totalEmissions,
  }
}

const aggregateEmissions = (
  emissions: EmissionsSumGroupResultItem[],
): EmissionsSumGroupResultItem => {
  const aggregatedEmissionsInitialValue: EmissionsSumGroupResultItem = {
    name: '',
    total: 0,
    scope: [
      { scope: 1, total: 0 },
      { scope: 2, total: 0 },
      { scope: 3, total: 0 },
    ],
  }

  return emissions.reduce((aggregatedEmissions, emissions) => {
    aggregatedEmissions.total += emissions.total
    aggregatedEmissions.name = emissions.name

    emissions.scope.forEach((scopeItem) => {
      const scopeIndex = scopeItem.scope - 1
      aggregatedEmissions.scope[scopeIndex].total += scopeItem.total
    })

    return aggregatedEmissions
  }, aggregatedEmissionsInitialValue)
}

export const prepareOrganigramData = (
  entities: (BusinessUnit | Location)[],
  emissions: OrganigramEmissionsByGroup,
): RawNodeDatum[] => {
  return entities.map((entity) => {
    const isBusinessUnit = 'ancestorIds' in entity

    const baseAttributes = {
      id: entity.id,
      ...(entity.metadata?.externalId ? { externalId: entity.metadata?.externalId } : {}),
    }

    if (isBusinessUnit) {
      // Current business unit emissions calculations
      const businessUnitsEmissions = emissions.businessUnits?.find(
        (emission) => emission.name === entity.title,
      )
      const { scope1, scope2, scope3, totalEmissions } = formatEmissions(businessUnitsEmissions)

      // Children emissions calculations
      const children = prepareOrganigramData(
        ([...(entity.children || []), ...(entity.locations || [])] as BusinessUnit[]) ?? [],
        emissions,
      )

      const businessUnitChildrenNames = children
        .filter((child) => child.attributes?.type === 'Business unit')
        .map((child) => child.name)

      const businessUnitChildrenEmissions = emissions.businessUnits.filter((emission) =>
        businessUnitChildrenNames.includes(emission.name),
      )

      const aggregatedChildrenEmissions = aggregateEmissions(businessUnitChildrenEmissions)

      const {
        scope1: childrenScope1Emissions,
        scope2: childrenScope2Emissions,
        scope3: childrenScope3Emissions,
        totalEmissions: childrenTotalEmissions,
      } = formatEmissions(aggregatedChildrenEmissions)

      return {
        name: entity.title,
        attributes: {
          ...baseAttributes,
          type: 'Business unit',
          address: '',
          organizationName: entity.organization?.name,
          scope1Emissions: scope1 + childrenScope1Emissions,
          scope2Emissions: scope2 + childrenScope2Emissions,
          scope3Emissions: scope3 + childrenScope3Emissions,
          totalEmissions: totalEmissions + childrenTotalEmissions,
        },
        children,
      }
    }

    const locationEmissions = emissions.locations?.find((emission) => emission.name === entity.name)

    const { scope1, scope2, scope3, totalEmissions } = formatEmissions(locationEmissions)

    const responsibleName =
      entity.responsible?.firstName && entity.responsible?.lastName
        ? `${entity.responsible?.firstName} ${entity.responsible?.lastName}`
        : undefined

    return {
      name: entity.name,
      attributes: {
        ...baseAttributes,
        type: 'Location',
        organizationName: entity.organization?.name,
        responsibleName: responsibleName ?? '',
        scope1Emissions: scope1,
        scope2Emissions: scope2,
        scope3Emissions: scope3,
        totalEmissions,
      },
      children: [],
    }
  })
}
