import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineMail } from 'react-icons/hi'

import { Col, Row } from 'antd'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import requestFactorSuccess from '@/assets/request-factor-success.svg'

import classes from './requestFactorSuccessModal.module.less'

const RequestFactorSuccessModal = ({
  visible,
  openFactorRequestEmail,
  closeModal,
}: {
  visible: boolean
  openFactorRequestEmail: () => void
  closeModal: () => void
}): ReactElement => {
  const { t } = useTranslation()
  return (
    <Modal
      bodyStyle={{ overflowY: 'unset', padding: '0px' }}
      centered
      visible={visible}
      className={classes.modal}
      afterClose={closeModal}
    >
      <Row justify="center">
        <Col>
          <img src={requestFactorSuccess} alt="request factor success" />
        </Col>
        <Col>
          <Title size="sm" className={classes.title}>
            {t('suppliers.factor.request-modal.title')}
          </Title>
        </Col>
        <Col>
          <Text color="secondary" size="xl" className={classes.description}>
            {t('suppliers.factor.request-modal.description')}
          </Text>
        </Col>
        <Col>
          <Button
            className={classes.button}
            category={'logs'}
            action={'open-create-modal'}
            type="primary"
            data-cy="request-factor-send-email"
            prefixIcon={<HiOutlineMail />}
            size="lg"
            onClick={openFactorRequestEmail}
          >
            {t('suppliers.factor.request-modal.button')}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default RequestFactorSuccessModal
