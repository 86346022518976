import React, { useCallback, useEffect, useState } from 'react'

import { Cascader } from 'antd/es'
import { DefaultOptionType } from 'antd/es/select'

import _ from 'lodash'

import { ActivityDataSourceWithChildren } from '@cozero/models'

import { useLogContext } from '@/contexts/log'

interface ActivityDataSourceLazyLoadedCascaderProps {
  activityDataSources: ActivityDataSourceWithChildren[]
  disabled?: boolean
  value?: number
  onChange?: (ads: number[]) => void
  placeholder?: string
}

interface CascaderOption {
  label: string
  value: number
  children: CascaderOption[]
  isLeaf: boolean
}

const ActivityDataSourceLazyLoadedCascader = ({
  value,
  activityDataSources,
  disabled,
  placeholder,
  onChange,
}: ActivityDataSourceLazyLoadedCascaderProps): JSX.Element => {
  const [activityDataSourcesToDisplay, setActivityDataSourcesToDisplay] = useState<
    ActivityDataSourceWithChildren[]
  >([])
  const [initialStateLoaded, setInitialStateLoaded] = useState(false)
  const [adsStructurePath, setAdsStructurePath] = useState<number[]>([])

  const { getActivityDataSourcesWithoutStructure, getActivityDataSourcePathStructure } =
    useLogContext()

  const fetchInitialStructure = async (parentIds: number[]): Promise<void> => {
    const parentIdsGroupArray: Array<number[]> = parentIds.reduce(
      (accumulator: Array<number[]>, value) => {
        const lastItem = _.last(accumulator)
        const newArray = lastItem ? [...lastItem, value] : [value]
        return [...accumulator, newArray]
      },
      [],
    )
    const promise = parentIdsGroupArray.map((parentIdsGroup) =>
      loadActivityDataSourceChildren(parentIdsGroup),
    )
    await Promise.all(promise)
  }

  const fetchActivityDataSourcePathStructure = async (id: number): Promise<void> => {
    const structure = await getActivityDataSourcePathStructure(id)
    setAdsStructurePath(structure.adsStructure)
  }

  useEffect(() => {
    if (activityDataSources.length) {
      setActivityDataSourcesToDisplay(activityDataSources)
    }
  }, [activityDataSources])

  useEffect(() => {
    if (value && !initialStateLoaded) {
      fetchActivityDataSourcePathStructure(value)
    }
  }, [value])

  useEffect(() => {
    if (adsStructurePath.length && activityDataSourcesToDisplay.length && !initialStateLoaded) {
      fetchInitialStructure(adsStructurePath)
      setInitialStateLoaded(true)
    }
  }, [activityDataSourcesToDisplay, adsStructurePath, initialStateLoaded])

  const parseActivityDataSources = (
    activityDataSources: ActivityDataSourceWithChildren[],
  ): CascaderOption[] => {
    const parsedActivityDataSources = []
    for (const ads of activityDataSources) {
      parsedActivityDataSources.push({
        label: ads.name,
        value: ads.id,
        children: ads?.children?.length ? parseActivityDataSources(ads.children) : [],
        isLeaf: !ads?.children?.length,
      })
    }
    return parsedActivityDataSources
  }

  const adsOptions = useCallback(() => {
    if (activityDataSourcesToDisplay) {
      return parseActivityDataSources(activityDataSourcesToDisplay)
    }
  }, [activityDataSourcesToDisplay])

  const findRelevantParent = (
    activityDataSources: ActivityDataSourceWithChildren[],
    pathIds: number[],
  ): ActivityDataSourceWithChildren | undefined => {
    let childrenAds = activityDataSources
    for (const [index, id] of Object.entries(pathIds)) {
      const ads = childrenAds.find((ads) => ads.id === id)
      if (ads) {
        if (Number(index) === pathIds.length - 1) {
          return ads
        }
        if (ads.children) {
          childrenAds = ads.children
        }
      }
    }
  }

  const loadActivityDataSourceChildren = async (ids: number[]): Promise<void> => {
    const ads = await getActivityDataSourcesWithoutStructure(undefined, undefined, ids.at(-1))

    if (ads && ads.length && activityDataSourcesToDisplay) {
      setActivityDataSourcesToDisplay((prev) => {
        const parent = findRelevantParent(prev, ids)
        if (parent) {
          parent.children = [...ads]
        }
        return prev
      })
    }
  }

  return (
    <Cascader
      value={adsStructurePath}
      disabled={disabled}
      options={adsOptions()}
      loadData={(selectedValue: DefaultOptionType[]) => {
        const values = selectedValue.map((el) => el.value) as number[]
        loadActivityDataSourceChildren(values)
      }}
      placeholder={placeholder}
      changeOnSelect
      onChange={(value) => {
        onChange && onChange(value as number[])
        setAdsStructurePath(value as number[])
      }}
    />
  )
}

export default ActivityDataSourceLazyLoadedCascader
