import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { Col, List, Row, Tabs } from 'antd/es'

import moment from 'moment'

import { ApiTransaction } from '@cozero/models'

import Modal from '@/molecules/Modal'
import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useAdminContext } from '@/contexts/admin'
import { WHITE_NEUTRAL } from '@/styles/variables'
import { config } from '@/utils/config'

import classes from './classes.module.less'

function Integrations(): JSX.Element {
  const { t } = useTranslation('common')
  const { getApiIntegrations, apiIntegrations, loading } = useAdminContext()
  const [data, setData] = React.useState<ApiTransaction[]>()
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [jsonPayload, setJsonPayload] = React.useState<
    ApiTransaction['response'] | ApiTransaction['request']
  >()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const transactionId = searchParams.get('transactionId')

  useEffect(() => {
    async function fetchApiIntegrations(): Promise<void> {
      await getApiIntegrations()
    }
    fetchApiIntegrations()
  }, [])

  const toggleModal = (
    visible: boolean,
    transaction?: ApiTransaction['response'] | ApiTransaction['request'],
  ): void => {
    if (transaction) {
      setJsonPayload(transaction)
    }
    setIsModalVisible(visible)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      filterSearch: true,
      filters: apiIntegrations
        .flatMap((integration) => integration.transactions)
        .map((transaction) => ({
          text: transaction.id,
          value: transaction.id,
        })),
      defaultFilteredValue: transactionId ? [transactionId] : undefined,
      onFilter: (value: number | string | boolean, transaction: ApiTransaction) =>
        transaction.id === Number(value),
    },
    {
      title: 'API',
      dataIndex: 'integration',
      key: 'integration',
      filters: apiIntegrations.map((apiIntegration) => ({
        text: apiIntegration.name,
        value: apiIntegration.id,
      })),
      onFilter: (value: number | string | boolean, transaction: ApiTransaction) =>
        transaction.integrationId === Number(value),
    },
    {
      title: t('settings.integrations.transactions.timestamp'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: string, transaction: ApiTransaction): string =>
        moment(transaction.createdAt).format('LLL:SS'),
    },
    {
      title: t('settings.integrations.transactions.request-status'),
      dataIndex: 'responseCode',
      key: 'responseCode',
      render: (status: number): JSX.Element => (
        <Tag type={status === 200 ? 'success' : 'danger'}>{status}</Tag>
      ),
      filters: [
        {
          text: 'Success',
          value: 200,
        },
        {
          text: 'Error',
          value: 400,
        },
      ],
      onFilter: (value: number | string | boolean, transaction: ApiTransaction) =>
        value === Number(200) ? transaction.responseCode === 200 : transaction.responseCode !== 200,
    },
    {
      title: t('settings.integrations.transactions.request'),
      dataIndex: 'request',
      key: 'request',
      width: '30%',
      maxWidth: '30%',
      render: (_: string, transaction: ApiTransaction): JSX.Element => (
        <Button
          action={'open-request'}
          category={'integrations'}
          onClick={() => toggleModal(true, transaction.request)}
          type="text"
        >
          {t('actions.view')} {t('settings.integrations.transactions.request')}
        </Button>
      ),
    },
    {
      title: t('settings.integrations.transactions.response'),
      dataIndex: 'response',
      width: '30%',
      key: 'response',
      render: (_: string, transaction: ApiTransaction): JSX.Element => (
        <Button
          action={'open-response'}
          category={'integrations'}
          onClick={() => toggleModal(true, transaction.response)}
          type="text"
        >
          {t('actions.view')} {t('settings.integrations.transactions.response')}
        </Button>
      ),
    },
    {
      title: t('settings.integrations.transactions.import-status'),
      dataIndex: 'importSuccess',
      key: 'importSuccess',
      render: (_: string, transaction: ApiTransaction): JSX.Element => (
        <Tag type={transaction.importSuccess ? 'success' : 'danger'}>
          {transaction.importSuccess ? 'Success' : 'Failed'}
        </Tag>
      ),
      filters: [
        {
          text: t('settings.integrations.transactions.success'),
          value: true,
        },
        {
          text: t('settings.integrations.transactions.error'),
          value: false,
        },
      ],
      onFilter: (value: number | string | boolean, transaction: ApiTransaction) =>
        transaction.importSuccess === Boolean(value),
    },
    {
      title: t('settings.integrations.transactions.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_: string, transaction: ApiTransaction): JSX.Element =>
        transaction.logs?.length > 0 ? (
          <Button
            onClick={() =>
              navigate(
                config.routes.log.carbonFootprint.organization.edit.replace(
                  ':id',
                  `${transaction.logs[0]?.id}`,
                ),
              )
            }
            action={'go-to-log'}
            category={'integrations'}
          >
            {t('settings.integrations.transactions.go-to-log')}
          </Button>
        ) : (
          <></>
        ),
    },
  ]

  useEffect(() => {
    setData(
      apiIntegrations.flatMap((integration) => [
        ...integration.transactions.map((transaction) => ({
          ...transaction,
          integration: integration.name,
        })),
      ]),
    )
  }, [apiIntegrations])

  return (
    <>
      <Row>
        <Col span={24}>
          <Row className={classes.section}>
            <Col span={24}>
              <Title size="sm">{t('settings.integrations.title')}</Title>
            </Col>
            <Col span={24}>
              <Text size="xl" color="secondary">
                {t('settings.integrations.subtitle')}
              </Text>
            </Col>
          </Row>
          <Modal
            title={t('settings.integrations.transactions.response')}
            visible={isModalVisible}
            onCancel={() => toggleModal(false)}
            onOk={() => toggleModal(false)}
          >
            <ReactJson
              name={false}
              src={
                ((typeof jsonPayload === 'string'
                  ? JSON.parse(jsonPayload)
                  : jsonPayload) as Record<string, unknown>) ?? {}
              }
            />
          </Modal>
          <Tabs defaultActiveKey={transactionId ? 'transactions' : 'configurations'}>
            <Tabs.TabPane tab={t('settings.integrations.tabs.configurations')} key="configurations">
              <List
                className="demo-loadmore-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={apiIntegrations}
                renderItem={(integration) => (
                  <List.Item
                    actions={[
                      ...(integration.configurations?.[0]?.active
                        ? [
                            <div key={'active'}>
                              <Tag showDot type={'success'}>
                                {t('settings.integrations.fields.active.title')}
                              </Tag>
                            </div>,
                          ]
                        : [
                            <div key={'inactive'}>
                              <Tag showDot>{t('settings.integrations.fields.active.not')}</Tag>
                            </div>,
                          ]),
                      ...(integration.url
                        ? [
                            <Button
                              key={`visit-website`}
                              type="link"
                              href={integration.url}
                              target="_blank"
                            >
                              {t('settings.integrations.actions.visit-website')}
                            </Button>,
                          ]
                        : []),
                      <div key={`configure`}>
                        <Button
                          type={'primary'}
                          onClick={() =>
                            navigate(
                              config.routes.settings.installIntegration.replace(
                                ':id',
                                integration.id.toString(),
                              ),
                            )
                          }
                          action={'configure'}
                          category={'api-integrations'}
                        >
                          {t('settings.integrations.actions.configure')}
                        </Button>
                      </div>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        integration.logoUrl && (
                          <div
                            style={{
                              background: integration.logoBg ?? WHITE_NEUTRAL,
                              borderRadius: 5,
                            }}
                          >
                            <img src={integration.logoUrl} width={100} style={{ margin: 25 }} />
                          </div>
                        )
                      }
                      title={integration.name}
                      description={integration.description as string}
                    />
                  </List.Item>
                )}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('settings.integrations.tabs.transactions')} key="transactions">
              <Table columns={columns} dataSource={data} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  )
}

export default Integrations
