import React, { ReactElement } from 'react'

import { Col, Row } from 'antd/es'

import classes from './HighlightValue.module.less'

interface Props {
  value: string
  unit?: string
  leftUnit?: string
}

const HighlightValue = ({ value, unit, leftUnit }: Props): ReactElement => (
  <Row className={classes.alignRow}>
    {leftUnit && <Col className={classes.leftUnit}>{leftUnit}</Col>}
    <Col className={classes.content}>{value}</Col>
    {unit && <Col className={classes.unitMargin}>{unit}</Col>}
  </Row>
)

export default HighlightValue
