import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Drawer, Space } from 'antd/es'
import { BaseOptionType, DefaultOptionType } from 'antd/es/select'

import { random } from 'lodash-es'

import { LogType, PageFilter, RelationshipFilter } from '@cozero/models'

import Alert from '@/atoms/Alert'
import CloseIcon from '@/atoms/CloseIcon'

import { useAppDispatch } from '@/redux'
import {
  resetFactorsFilters,
  resetLocationListFilters,
  resetLocationsFilters,
  resetProductsFilters,
  setProductsFilters,
} from '@/redux/filters/slice'

import ClimateActionsFiltersForm from './ClimateActionsFiltersForm'
import EmissionFactorsFiltersForm from './EmissionFactorsFiltersForm'
import LocationFiltersForm from './LocationFiltersForm'
import LocationListFiltersForm from './LocationListFilters'
import ProductFiltersForm from './ProductFiltersForm'
import QuantitiesFiltersForm from './QuantitiesFiltersForm'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Select {
  export type value = number[]
  export type option = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[]
}

export type FilterValue = {
  [FilterKey.CATEGORY]: number[]
  [FilterKey.LOCATION]: number[]
  [FilterKey.BUSINESS_UNIT]: number[]
  [FilterKey.PRODUCT]: number[]
  [FilterKey.PRODUCT_TAGS]: string[]
  [FilterKey.PRODUCT_TYPE]: number[]
  [FilterKey.RESPONSIBLE]: number[]
  [FilterKey.TAG]: string[]
  [FilterKey.CUSTOMER]: number[]
  [FilterKey.SUPPLIER]: number[]
  [FilterKey.DESCRIPTION]: string
  [FilterKey.SCOPE]: number[]
  [FilterKey.ACTION_AREA]: number[]
  [FilterKey.QUANTITY_TAGS]: string[]
  [FilterKey.EXTERNAL_ID]: string[]
  [FilterKey.ACTIVITY_DATA_SOURCE]: number[]
  [FilterKey.TERRITORY]: number[]
  [FilterKey.UNIT]: number[]
  [FilterKey.FACTOR_ORIGIN]: number[]
  [FilterKey.ACTIVE]: boolean
}

export enum FilterKey {
  CATEGORY = 'categoryId',
  PRODUCT_TYPE = 'productTypeId',
  EXTERNAL_ID = 'externalId',
  PRODUCT_TAGS = 'tags',
  LOCATION = 'locationId',
  BUSINESS_UNIT = 'businessUnit',
  PRODUCT = 'productId',
  RESPONSIBLE = 'ownerId',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  DESCRIPTION = 'description',
  TAG = 'tag',
  QUANTITY_TAGS = 'quantityTags',
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier',
  SCOPE = 'scope',
  ACTION_AREA = 'actionArea',
  TERRITORY = 'territory',
  UNIT = 'unit',
  ACTIVITY_DATA_SOURCE = 'activityDataSource',
  FACTOR_ORIGIN = 'origin',
  ACTIVE = 'active',
}

export enum PageType {
  PRODUCT = 'product',
  LOCATION = 'location',
  FACTOR = 'factor',
  LOCATION_LIST = 'locationList',
  CLIMATE_ACTIONS = 'climateActions',
}

const MAX_FILTERS_ALLOWED = 2

const defaultFilterDrawerValues = {
  [FilterKey.CATEGORY]: [],
  [FilterKey.LOCATION]: [],
  [FilterKey.BUSINESS_UNIT]: [],
  [FilterKey.PRODUCT]: [],
  [FilterKey.PRODUCT_TYPE]: [],
  [FilterKey.PRODUCT_TAGS]: [],
  [FilterKey.RESPONSIBLE]: [],
  [FilterKey.TAG]: [],
  [FilterKey.CUSTOMER]: [],
  [FilterKey.SUPPLIER]: [],
  [FilterKey.DESCRIPTION]: '',
  [FilterKey.SCOPE]: [],
  [FilterKey.ACTION_AREA]: [],
  [FilterKey.QUANTITY_TAGS]: [],
  [FilterKey.EXTERNAL_ID]: [],
  [FilterKey.ACTIVITY_DATA_SOURCE]: [],
  [FilterKey.TERRITORY]: [],
  [FilterKey.UNIT]: [],
  [FilterKey.FACTOR_ORIGIN]: [],
  [FilterKey.ACTIVE]: true,
}

export interface FiltersDrawerProps {
  visible: boolean
  search: (filters: PageFilter[]) => void
  onClose: () => void
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[]
  filters?: (PageFilter & { key: FilterKey })[]
  pageType?: LogType | PageType
  featuresAllowed?: string[]
  setFilterCount?: (count: number) => void
}

const FiltersDrawer = ({
  visible,
  filterOptions,
  pageType,
  filters: initialFilters,
  search,
  onClose,
  featuresAllowed,
  setFilterCount,
}: FiltersDrawerProps): ReactElement => {
  const { t } = useTranslation('')
  const [filters, setFilters] = useState<(PageFilter & { key: FilterKey })[]>(initialFilters || [])
  const [currentValues, setCurrentValues] = useState<FilterValue>(defaultFilterDrawerValues)
  const dispatch = useAppDispatch()
  const isMultipleFilteringAllowed = featuresAllowed?.includes('multiple-filtering')
  const showMultipleFiltersNotAllowedError =
    !isMultipleFilteringAllowed && filters.length > MAX_FILTERS_ALLOWED

  useEffect(() => {
    if (initialFilters) {
      setFilters(initialFilters)
    }
  }, [initialFilters])

  const onChangeCondition = (
    filter: RelationshipFilter & { key: FilterKey },
    value: Select.value | string | string[],
  ): void => {
    let index = filters.findIndex((x) => x.key === filter.key)

    const selectedCondition = filter.conditions?.find((obj) => obj.key === value)
    const newFilter = {
      ...filter,
      selectedCondition,
    }
    if (index === -1) {
      newFilter.id = random(10000)
      index = filters.length
    }
    const newFilters = [...filters]
    newFilters[index] = newFilter
    return setFilters(newFilters)
  }

  const onChange = (
    filter: PageFilter & { key: FilterKey },
    value: Select.value | string | string[],
  ): void => {
    let index = filters.findIndex((x) => x.key === filter.key)
    const existingFilter = filters.find((x) => x.key === filter.key)

    // Delete filter if value is empty
    if (value.length === 0 && index !== -1) {
      setFilters(filters.filter((x) => x.key !== filter.key))
      return
    }
    const selectedCondition =
      typeof value === 'string'
        ? { key: 'contains', label: 'Contains' }
        : existingFilter?.selectedCondition || {
            key: 'in',
            label: 'Is',
          }
    const newFilter = {
      ...filter,
      selectedCondition,
      pageType,
      value: value as never,
    }
    if (index === -1) {
      newFilter.id = random(10000)
      index = filters.length
    }

    const dateFilters = filters.filter(
      (filter) => filter.type === 'date' || filter.type === 'dateRange',
    )

    const newFilters = [
      ...filters.filter((filter) => filter.type !== 'date' && filter.type !== 'dateRange'),
    ]

    newFilters[index] = { ...newFilter, logType: pageType as LogType }

    if (setFilterCount) {
      setFilterCount(newFilters.length)
    }

    return setFilters([...newFilters.filter(Boolean), ...dateFilters])
  }

  const resetFilters = (): void => {
    setCurrentValues({
      [FilterKey.CATEGORY]: [],
      [FilterKey.PRODUCT_TYPE]: [],
      [FilterKey.PRODUCT_TAGS]: [],
      [FilterKey.LOCATION]: [],
      [FilterKey.BUSINESS_UNIT]: [],
      [FilterKey.PRODUCT]: [],
      [FilterKey.RESPONSIBLE]: [],
      [FilterKey.TAG]: [],
      [FilterKey.QUANTITY_TAGS]: [],
      [FilterKey.CUSTOMER]: [],
      [FilterKey.SUPPLIER]: [],
      [FilterKey.DESCRIPTION]: '',
      [FilterKey.SCOPE]: [],
      [FilterKey.ACTION_AREA]: [],
      [FilterKey.EXTERNAL_ID]: [],
      [FilterKey.ACTIVITY_DATA_SOURCE]: [],
      [FilterKey.TERRITORY]: [],
      [FilterKey.UNIT]: [],
      [FilterKey.FACTOR_ORIGIN]: [],
      [FilterKey.ACTIVE]: true,
    })
  }

  useEffect(() => {
    const newCurrentValuesState = { ...currentValues }
    if (filters.length === 0) {
      resetFilters()
      return
    }

    filters.forEach((filter) => {
      if (
        !(
          filter.key === FilterKey.START_DATE ||
          filter.key === FilterKey.END_DATE ||
          filter.type === 'dateRange'
        )
      ) {
        if (filter.key === FilterKey.DESCRIPTION) {
          newCurrentValuesState[filter.key] = filter.value as string
        } else if (filter.key === FilterKey.TAG) {
          newCurrentValuesState[filter.key] = filter.value as string[]
        } else {
          if (filter.key === FilterKey.PRODUCT_TAGS) {
            newCurrentValuesState[filter.key] = filter.value as string[]
          } else {
            if (filter.key === FilterKey.ACTIVE) {
              newCurrentValuesState[filter.key] = filter.value as boolean
            } else {
              newCurrentValuesState[filter.key] = [
                ...(filter.value as number[]).map((x) => (!isNaN(x) ? +x : x)),
              ] as number[] & string[]
            }
          }
        }
      }
    })
    setCurrentValues(newCurrentValuesState)
  }, [filters])

  const saveFilters = (): void => {
    if (showMultipleFiltersNotAllowedError) {
      return
    }
    search && search([...filters])
    switch (pageType) {
      case PageType.PRODUCT:
        dispatch(setProductsFilters(filters))
        break
      case PageType.FACTOR:
        dispatch(setProductsFilters(filters))
        break
      case PageType.LOCATION:
        dispatch(setProductsFilters(filters))
        break
      case PageType.LOCATION_LIST:
        dispatch(setProductsFilters(filters))
        break

      default:
        break
    }
    onClose()
  }

  const clearFilters = (): void => {
    switch (pageType) {
      case PageType.PRODUCT:
        dispatch(resetProductsFilters())
        break
      case PageType.FACTOR:
        dispatch(resetFactorsFilters())
        break
      case PageType.LOCATION:
        dispatch(resetLocationsFilters())
        break
      case PageType.LOCATION_LIST:
        dispatch(resetLocationListFilters())
        break

      default:
        break
    }
    resetFilters()
    search && search([])
    onClose()
  }

  return (
    <>
      <Drawer
        title={t('log.filter.title')}
        placement="right"
        onClose={onClose}
        visible={visible}
        closeIcon={<CloseIcon />}
        footer={
          <Space>
            <Button onClick={clearFilters}>{t('log.filter.clear')}</Button>
            <Button
              disabled={showMultipleFiltersNotAllowedError || filters.length === 0}
              type="primary"
              onClick={saveFilters}
            >
              {t('log.filter.save')}
            </Button>
          </Space>
        }
        footerStyle={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <>
          {showMultipleFiltersNotAllowedError && (
            <Alert
              type="error"
              title={t('log.filter.multiple-disabled-title')}
              message={t('log.filter.multiple-disabled')}
            />
          )}
          {pageType === PageType.PRODUCT ? (
            <ProductFiltersForm
              featuresAllowed={featuresAllowed || []}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
            />
          ) : pageType === PageType.LOCATION ? (
            <LocationFiltersForm
              featuresAllowed={featuresAllowed || []}
              filters={filters}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
              onChangeCondition={(...args) => onChangeCondition(...args)}
            />
          ) : pageType === PageType.FACTOR ? (
            <EmissionFactorsFiltersForm
              featuresAllowed={featuresAllowed || []}
              filters={filters}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
            />
          ) : pageType === PageType.LOCATION_LIST ? (
            <LocationListFiltersForm
              featuresAllowed={featuresAllowed || []}
              filters={filters}
              values={currentValues}
              onChange={(...args) => onChange(...args)}
            />
          ) : pageType === 'climateActions' ? (
            <ClimateActionsFiltersForm
              featuresAllowed={featuresAllowed || []}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
            />
          ) : (
            <QuantitiesFiltersForm
              featuresAllowed={featuresAllowed || []}
              filters={filters}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
            />
          )}
        </>
      </Drawer>
    </>
  )
}

export default React.memo(FiltersDrawer)
