import React, { useEffect, useImperativeHandle } from 'react'
import { useLocation, useParams } from 'react-router'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { useLifecycleContext } from '../../../../contexts/lifecycle'
import { useGetProductQuery } from '../../../../redux/products'
import LifecycleSteps from '../LifecycleSteps'
import { ProductConfigurationValues } from '../ProductConfiguration/UpsertProduct'
import { useProductOutletContext } from '../index'

interface SelectStepsProps {
  edit?: boolean
}

function SelectSteps({ edit = false }: SelectStepsProps): JSX.Element {
  const { id } = useParams()
  const { data: product } = useGetProductQuery(id ?? skipToken)
  const { childRef, STEPS, updateProduct } = useProductOutletContext()
  const { selectedSteps, setFrom } = useLifecycleContext()
  const location = useLocation()

  useImperativeHandle(childRef, () => ({
    onNext: async () => {
      if (edit && product && product.active) {
        const selectedStepsKeys = selectedSteps.map((x) => STEPS[x.index].stepKey)
        const data: Partial<ProductConfigurationValues> = {
          selectedSteps: selectedStepsKeys || [],
        }
        await updateProduct(product?.id.toString(), data)
      }
    },
    onReturn: () => undefined,
    onClearData: () => undefined,
  }))

  useEffect(() => {
    if (location.state && (location.state as { from: string }).from) {
      setFrom((location.state as { from: string }).from)
    }
  }, [location])

  return (
    <>
      <LifecycleSteps initialSteps={STEPS} />
    </>
  )
}

export default React.memo(SelectSteps)
