import React, { ReactElement, ReactNode } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import FactorRequestPage from '@/pages/Log/FactorRequest'
import UpsertProductPage from '@/pages/Organization/NewProduct'
import GenericStepSelector from '@/pages/Organization/NewProduct/GenericStepSelector'
import ProductConfiguration from '@/pages/Organization/NewProduct/ProductConfiguration'
import Quantities from '@/pages/Organization/NewProduct/Quantities'
import SelectSteps from '@/pages/Organization/NewProduct/SelectSteps'
import { LifecycleStepsID, LifecycleStepsKey } from '@/pages/Organization/NewProduct/steps'

import LifecycleProvider from '@/contexts/lifecycle'
import LogProvider from '@/contexts/log'
import { config } from '@/utils/config'

import PrivateRoute from '../PrivateRoute'

const Wrapper = ({
  children,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <LogProvider>
      <PrivateRoute>{children}</PrivateRoute>
    </LogProvider>
  </LogProvider>
)

const getFactorRequestRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: config.routes.log.factorRequest,
    element: (
      <Wrapper>
        <FactorRequestPage />
      </Wrapper>
    ),
    breadcrumb: t('layout.products'),
  },
]

export default getFactorRequestRoutes
