import { Product } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import { UploadProductConfigurationParams } from '../../hooks/useProducts'
import { ProductConfigurationValues } from '../../pages/Organization/NewProduct/ProductConfiguration'
import apiSlice from '../api'

import { TAG_ACTIVE_PRODUCTS, TAG_PRODUCT } from './tags'

export const GET_ACTIVE_PRODUCTS = 'getActiveProducts'
export const GET_PRODUCTS = 'getProducts'
export const GET_PRODUCT = 'getProduct'
export const CREATE_PRODUCT = 'createProduct'
export const GET_ORGANIZATION_TOTAL_PRODUCT_EMISSIONS = 'getOrganizationTotalProductEmissions'
export const EDIT_PRODUCT = 'editProduct'

const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_PRODUCT]: builder.query<Product | undefined, number | string>({
      query: (id) => ({
        url: centralApiGatewayClient.products.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_PRODUCT, id: result?.id }],
    }),
    [CREATE_PRODUCT]: builder.query<Product | undefined, ProductConfigurationValues>({
      query: (data) => ({
        url: centralApiGatewayClient.products.CREATE,
        method: 'POST',
        params: {
          data,
          ...(data.photo ? { photo: (data.photo as UploadProductConfigurationParams).path } : {}),
        },
      }),
    }),
    [GET_ACTIVE_PRODUCTS]: builder.query<
      Product[] | undefined,
      { businessUnitKey: string; search?: string }
    >({
      query: ({ businessUnitKey, search }) => ({
        url: centralApiGatewayClient.products.SEARCH,
        method: 'POST',
        params: {
          businessUnitKey,
          search,
          activeFilter: true,
        },
      }),
      providesTags: (result) =>
        providesList<Product[], typeof TAG_ACTIVE_PRODUCTS>(result, TAG_ACTIVE_PRODUCTS),
    }),
    [GET_PRODUCTS]: builder.query<
      Product[] | undefined,
      { businessUnitKey: string; activeFilter?: boolean; search?: string; root?: boolean }
    >({
      query: ({ businessUnitKey, search, activeFilter, root }) => ({
        url: centralApiGatewayClient.products.SEARCH,
        method: 'POST',
        params: {
          businessUnitKey,
          search,
          activeFilter,
          root,
        },
      }),
      providesTags: (result) =>
        providesList<Product[], typeof TAG_ACTIVE_PRODUCTS>(result, TAG_ACTIVE_PRODUCTS),
    }),
    [GET_ORGANIZATION_TOTAL_PRODUCT_EMISSIONS]: builder.query<
      {
        productsEmissions: number
      },
      void
    >({
      query: () => ({
        url: centralApiGatewayClient.products.ORGANIZATION_TOTAL_PRODUCT_EMISSIONS,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetProductQuery,
  useGetActiveProductsQuery,
  useLazyGetActiveProductsQuery,
  useGetProductsQuery,
  useLazyGetProductsQuery,
  usePrefetch,
  useGetOrganizationTotalProductEmissionsQuery,
} = productsApiSlice

export default productsApiSlice
