import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Divider, Row, Space, Spin, Switch, Typography, message } from 'antd/es'

import { CheckOutlined } from '@ant-design/icons'

import { Limit, Organization, SubscriptionProduct } from '@cozero/models'

import { ContactModal } from '@/organisms/ContactModal'
import { VATModal } from '@/organisms/VATModal/VATModal'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Title from '@/atoms/Title'

import DuplicateIcon from '@/assets/icons/duplicate.svg'
import SupportIcon from '@/assets/icons/support-white.svg'
import { useSubscriptionContext } from '@/contexts/subscription'
import { useAppSelector } from '@/redux'
import { selectUserOrganization } from '@/redux/auth'
import { formatNumber } from '@/utils/number'

import classes from './classes.module.less'

function Plans(): ReactElement | null {
  const { t } = useTranslation('common')
  const {
    getSubscription,
    loading,
    getSubscriptionProducts,
    subscriptionProducts,
    getCheckoutUrl,
    subscriptionExpired,
  } = useSubscriptionContext()
  const organization = useAppSelector(selectUserOrganization)
  const [interval, setInterval] = useState(true)
  const [selectedProductId, setSelectedProductId] = useState<string>('')
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>()

  const closeVatModal = (): void => {
    setSelectedProductId('')
  }

  const openVatModal = (productId: string): void => {
    setSelectedProductId(productId)
  }

  async function fetchData(): Promise<void> {
    await getSubscription()
    await getSubscriptionProducts()
  }

  const goToCheckout = async (productId: string): Promise<void> => {
    const url = await getCheckoutUrl(productId)
    if (url) {
      window.open(url, '_self')
    } else {
      message.error(t('plans.error'))
    }
  }

  const openContactModal = async (): Promise<void> => {
    setSelectedOrganization(organization)
  }

  const closeContactModal = (): void => {
    setSelectedOrganization(undefined)
  }

  function changeInterval(): void {
    setInterval(!interval)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (
    !organization ||
    organization?.pricing?.key === 'enterprise' ||
    organization?.pricing?.key === 'supplier'
  ) {
    return null
  }

  return (
    <Row>
      <Col span={24}>
        <Spin spinning={loading} tip={t('loading')}>
          <Row className={classes.section}>
            <Col span={24}>
              <div className={classes.pricing}>
                <Title size="sm">{t('plans.title')}</Title>
                <Typography className={classes.subtitle}>{t('plans.subtitle')}</Typography>
                <Divider type="horizontal" />
                <Col>
                  <Row className={classes.billingContainer}>
                    <Typography className={classes.billing}>{t('plans.billing')}</Typography>
                    <Switch
                      checkedChildren={t('plans.yearly')}
                      unCheckedChildren={t('plans.monthly')}
                      checked={interval}
                      onChange={changeInterval}
                    />
                    {interval && (
                      <Typography className={classes.discount}>{t('plans.discount')}</Typography>
                    )}
                  </Row>
                </Col>
                <Space direction="horizontal" className={classes.productsContainer}>
                  {subscriptionProducts.map((plan, key) => (
                    <Card key={plan.id} shadow="none">
                      <ProductPlan
                        plan={plan}
                        organization={organization}
                        openContactModal={openContactModal}
                        goToCheckout={openVatModal}
                        interval={interval}
                        previousPlan={key !== 0 ? subscriptionProducts[key - 1].name || '' : ''}
                        subscriptionExpired={subscriptionExpired}
                      />
                    </Card>
                  ))}
                </Space>
              </div>
            </Col>
          </Row>
        </Spin>
      </Col>
      <VATModal
        goToCheckout={goToCheckout}
        productId={selectedProductId}
        handleCancel={closeVatModal}
      />
      <ContactModal selectedOrganization={selectedOrganization} handleCancel={closeContactModal} />
    </Row>
  )
}

const ActionButton = ({
  plan,
  organization,
  openContactModal,
  goToCheckout,
  interval,
  subscriptionExpired,
}: {
  plan: SubscriptionProduct
  organization: Organization
  openContactModal: () => void
  goToCheckout: (priceId: string) => void
  interval: 'month' | 'year'
  subscriptionExpired: boolean
}): ReactElement | null => {
  const { t } = useTranslation('common')
  if (plan.key === 'enterprise') {
    return (
      <Button
        category="plans"
        action="enterprise"
        className={classes.upgradeButton}
        type="primary"
        onClick={openContactModal}
      >
        <img className={classes.icon} src={SupportIcon} /> {t('plans.sales')}
      </Button>
    )
  }
  if (!subscriptionExpired && plan.key === organization?.pricing?.key) {
    return (
      <Button category="plans" action="upgrade" disabled className={classes.upgradeButton}>
        {t('plans.current-plan')}
      </Button>
    )
  }
  const priceId = plan.prices?.find((obj) => obj.interval === interval)?.id
  if (!priceId) {
    return null
  }

  return (
    <Button
      category="plans"
      action={`upgrade-${priceId}`}
      className={classes.upgradeButton}
      type="primary"
      onClick={() => goToCheckout(priceId)}
    >
      <img className={classes.icon} src={DuplicateIcon} />
      {t('plans.upgrade')}
    </Button>
  )
}

function ProductPlan({
  plan,
  organization,
  openContactModal,
  goToCheckout,
  interval,
  previousPlan,
  subscriptionExpired,
}: {
  plan: SubscriptionProduct
  organization: Organization
  goToCheckout: (productId: string) => void
  openContactModal: () => void
  interval: boolean
  previousPlan: string
  subscriptionExpired: boolean
}): ReactElement | null {
  const { t } = useTranslation('common')
  const priceInterval = interval ? 'year' : 'month'
  const price = plan.prices?.find((obj) => obj.interval === priceInterval)
  const priceAmount = priceInterval === 'year' ? (price?.amount || 0) / 12 : price?.amount
  const limits = plan.limits as Limit[]

  function findLimit(limits: Limit[], feature: string): ReactElement | null {
    if (plan.key === 'enterprise') {
      return null
    }
    const string = t(`plans.${feature}`)
    const limit = limits?.find((obj) => obj?.feature?.key === feature)?.max
    if (!limit || limit === 0) {
      return null
    }
    return (
      <>
        <Typography className={classes.limitTitle}>{limit}</Typography>
        <Typography className={classes.limitSubtitle}>{string}</Typography>
      </>
    )
  }

  return (
    <Col className={classes.planContainer}>
      <Row className={classes.planBillingContainer}>
        <Title>{plan.name}</Title>
        <Typography className={classes.subtitle}>{plan.description}</Typography>
      </Row>
      <Row className={classes.periodContainer}>
        {plan.key === 'enterprise' ? (
          <Typography className={classes.price}>{t('plans.custom')}</Typography>
        ) : (
          <>
            <Typography className={classes.price}>
              {formatNumber(priceAmount || 0, {
                currency: price?.currency || 'eur',
                style: 'currency',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })}
            </Typography>
            <Typography className={classes.planPeriod}>{t('plans.interval')}</Typography>
          </>
        )}
      </Row>
      <Row className={classes.buttonContainer}>
        <ActionButton
          openContactModal={openContactModal}
          goToCheckout={goToCheckout}
          plan={plan}
          organization={organization}
          interval={priceInterval}
          subscriptionExpired={subscriptionExpired}
        />
      </Row>
      <Row className={classes.everythingContainer}>
        {previousPlan && (
          <Typography className={classes.everythingText}>
            {t('plans.everything', { plan: previousPlan })}
          </Typography>
        )}
      </Row>
      <div className={classes.limitsContainer}>
        {plan.key === 'enterprise' ? (
          <div className={classes.enterpriseSpace}></div>
        ) : (
          <>
            <Row className={classes.limitContainer}>{findLimit(limits, 'users')}</Row>
            <Row className={classes.limitContainer}>{findLimit(limits, 'locations')}</Row>
            <Row className={classes.limitContainer}>{findLimit(limits, 'products')}</Row>
            <Row className={classes.limitContainer}>{findLimit(limits, 'business_units')}</Row>
          </>
        )}
      </div>

      {plan.highlights?.split(',').map((feature) => (
        <Row className={classes.rowContainer} key={feature}>
          <CheckOutlined className={classes.icon} />
          <Typography className={classes.rowTitle}>{feature}</Typography>
        </Row>
      ))}
      <Row className={classes.rowContainer}>
        <Typography className={classes.rowTitle}>{t('plans.contract-duration')}</Typography>
      </Row>
    </Col>
  )
}

export default Plans
