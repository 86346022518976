import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiDotsHorizontal, HiOutlineLogout } from 'react-icons/hi'
import { useNavigate } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { Col, Dropdown, Menu, Row } from 'antd/es'

import Avatar from '@/atoms/Avatar'
import Text from '@/atoms/Text'

import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUser } from '@/redux/auth'
import { config } from '@/utils/config'

import classes from './NavUserMenu.module.less'

const UserMenu = ({
  isManagerOrAdmin,
  onLogoutHandler,
}: {
  isManagerOrAdmin: boolean
  onLogoutHandler: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { show } = useIntercom()
  return (
    <Menu className={classes.menu}>
      <Menu.Item
        key="profile"
        className={classes.menuItem}
        onClick={() => navigate(config.routes.settings.profile)}
      >
        {t('nav.profile')}
      </Menu.Item>
      {isManagerOrAdmin && (
        <Menu.Item
          key="admin"
          className={classes.menuItem}
          onClick={() => navigate(config.routes.settings.organization)}
          data-cy="select-settings"
        >
          {t('nav.settings')}
        </Menu.Item>
      )}
      <Menu.Item key="wiki" className={classes.menuItem} onClick={show}>
        {t('nav.wiki')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="logout"
        icon={<HiOutlineLogout />}
        className={`${classes.menuItem} ${classes.danger}`}
        onClick={onLogoutHandler}
      >
        {t('nav.sign-out')}
      </Menu.Item>
    </Menu>
  )
}

const NavUserMenu = (): JSX.Element => {
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)

  const _handleLogout = (): void => navigate(config.routes.logout)

  return (
    <Row align="middle" gutter={16} data-cy="user-name-display">
      <Col>
        <Avatar
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
          image={user?.photo}
          showTooltip={false}
          outlined
        />
      </Col>
      <Col span={13}>
        <div className={classes.text}>
          <Text color="white" size="xl" fontWeight="medium" ellipsis>
            {user?.firstName ?? ''} {user?.lastName ?? ''}
          </Text>
          <Text color="secondary" ellipsis>
            {user?.email ?? ''}
          </Text>
        </div>
      </Col>
      <Col span={6}>
        <div className={classes.menuContainer} data-cy="3dot-user-menu">
          <Dropdown
            overlay={
              <UserMenu isManagerOrAdmin={isManagerOrAdmin} onLogoutHandler={_handleLogout} />
            }
            placement="topRight"
          >
            <span className={classes.iconWrapper}>
              <HiDotsHorizontal className={classes.icon} />
            </span>
          </Dropdown>
        </div>
      </Col>
    </Row>
  )
}

export default NavUserMenu
