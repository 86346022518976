import React, { ReactNode } from 'react'

import { Col, Row, Skeleton, Tooltip } from 'antd/es'

import Text from '@/atoms/Text'

import { CINDER_BLUE_60, CINDER_BLUE_80 } from '@/styles/variables'

import classes from './OverviewCard.module.less'

export interface OverviewCardProps {
  headerColor?: string
  headerTitle: string
  headerWeight?: 'bold' | 'medium' | 'semibold' | 'regular' | 'light'
  tooltip?: {
    triggerElement: ReactNode
    subtitle: string
  }
  content: ReactNode
  isVisible?: boolean
  style?: React.CSSProperties | undefined
  loading?: boolean
}

const OverviewCard = ({
  headerTitle,
  headerColor = CINDER_BLUE_60,
  headerWeight = 'medium',
  tooltip,
  content,
  loading,
  ...rest
}: OverviewCardProps): JSX.Element => {
  return (
    <>
      <div className={classes.overviewContainer} {...rest}>
        {!loading ? (
          <>
            <Row className={classes.header}>
              <Col>
                <Text size="xl" fontWeight={headerWeight} style={{ color: headerColor }}>
                  {headerTitle}
                </Text>
              </Col>
              <Col>
                {tooltip && (
                  <Col className={classes.tooltipIcon}>
                    <Tooltip
                      overlayClassName={classes.tooltip}
                      color={CINDER_BLUE_80}
                      title={tooltip?.subtitle}
                    >
                      {tooltip.triggerElement}
                    </Tooltip>
                  </Col>
                )}
              </Col>
            </Row>
            {!loading ? content : ''}
          </>
        ) : (
          <Skeleton active paragraph={{ rows: 2 }} />
        )}
      </div>
    </>
  )
}

export default OverviewCard
