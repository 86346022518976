import { TFunction } from 'react-i18next'

import { i18n } from 'i18next'
import moment from 'moment'

import { AntTableColumn } from '@cozero/models'

import { formatColumns } from './config'

interface renderProps {
  t: TFunction<'common'>
  i18n: i18n
}

interface renderColumnProps extends renderProps {
  column: AntTableColumn
}

interface renderRowProps extends renderProps {
  row: { [key: string]: string | number | boolean }
}

export const renderColumn = ({ column, t, i18n }: renderColumnProps): AntTableColumn => {
  column.children?.forEach((children) => renderColumn({ column: children, t, i18n }))
  if (
    formatColumns.number.includes(column.key) ||
    moment(column.key, moment.ISO_8601, true).isValid()
  ) {
    column.render = (text: string) => {
      if (!isNaN(Number(text))) {
        return Number(text).toLocaleString(i18n.language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      } else {
        return '-'
      }
    }
  } else if (formatColumns.date_year.includes(column.key)) {
    column.render = (text: string) => moment(text).format('YYYY')
  } else if (formatColumns.date_month.includes(column.key)) {
    column.render = (text: string) => moment(text).format('MM/YYYY')
  }
  if (moment(column.key, moment.ISO_8601, true).isValid()) {
    column.title = moment(column.key).format('YYYY')
  } else {
    if (i18n.exists(`common:reports.keys.${column.key}`)) {
      column.title = t(`reports.keys.${column.key}`)
    }
  }
  return column
}

export const renderRow = ({ row, t, i18n }: renderRowProps): renderRowProps['row'] => {
  for (const [key, value] of Object.entries(row)) {
    if (i18n.exists(`common:reports.keys.${value}`)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      row[key] = t(`reports.keys.${value}`)
    }
  }
  return row
}

export const renderCSVRow = ({ row, t, i18n }: renderRowProps): renderRowProps['row'] => {
  const translatedRow: { [key: string]: string | number | boolean } = {}
  for (const [key, value] of Object.entries(row)) {
    let translatedKey = key
    if (i18n.exists(`common:reports.keys.${key}`)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      translatedKey = t(`reports.keys.${key}`)
    }
    if (i18n.exists(`common:reports.keys.${value}`)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      translatedRow[translatedKey] = t(`reports.keys.${value}`)
    } else {
      translatedRow[translatedKey] = value
    }
  }
  return translatedRow
}
