import React, { memo, useId, useMemo } from 'react'

import { Input } from 'antd/es'

import Label from '../Label'
import Tag from '../Tag'
import WikiIcon from '../WikiIcon'

import classes from './InputField.module.less'
import { InputFieldProps, TextAreaProps } from './types'

const InputField = ({
  className,
  size = 'middle',
  type = 'text',
  placeholder,
  label,
  optional,
  value,
  ...rest
}: InputFieldProps): JSX.Element => {
  const id = useId()
  const classNames = useMemo(() => `${classes.inputField} ${className}`, [className])

  const LabelComponent = useMemo(() => {
    const htmlFor = rest.id ? rest.id : rest.name ? rest.name : id

    if (label && typeof label === 'string') {
      return <Label htmlFor={htmlFor}>{label}</Label>
    } else if (label && typeof label === 'object') {
      return (
        <Label htmlFor={htmlFor}>
          {label.text}
          <WikiIcon title={label.tooltipText} url={label.url} />
        </Label>
      )
    }
    return null
  }, [label])

  const InputTag = useMemo(() => {
    switch (type) {
      case 'search':
        return <Input.Search id={id} className={classNames} value={value} {...rest} />
      case 'textarea':
        return (
          <Input.TextArea
            id={id}
            className={classNames}
            size={size}
            value={value}
            placeholder={placeholder}
            {...(rest as TextAreaProps)}
          />
        )
      default:
        return (
          <Input
            type={type}
            className={classNames}
            size={size}
            placeholder={placeholder}
            value={value}
            {...rest}
          />
        )
    }
  }, [type, classNames, value, rest])

  return (
    <>
      {LabelComponent}
      {optional && <Tag className={classes.optionalTag}>Optional</Tag>}
      {InputTag}
    </>
  )
}

export type { InputFieldProps }
export default memo(InputField)
