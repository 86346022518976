import { useState } from 'react'

import { Store } from 'antd/es/form/interface'

import { ApiIntegration, AuditEvent, Product, Role, User, UserFunction } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { getAuditEvents as fetchAuditEvents } from '../backend/admin/auditEvents'
import { getRoles as fetchRoles } from '../backend/admin/organization'
import {
  deleteProduct as destroyProduct,
  createProduct as postProduct,
  transferProduct as postTransferProduct,
} from '../backend/admin/product'
import {
  deleteUserFunction as destroyUserFunction,
  getUserFunctions as fetchUserFunctions,
  createUserFunction as submitCreateUserFunction,
  updateUserFunction as submitUpdateUserFunction,
} from '../backend/admin/userFunctions'
import { AdminContextInterface } from '../contexts/admin'
import { useAppContext } from '../contexts/app'
import axios from '../utils/axios'

const useAdmin = (): AdminContextInterface => {
  const [error, setError] = useState<Error | undefined>()
  const [loading, setLoading] = useState(false)
  const [userFunctions, setUserFunctions] = useState<UserFunction[]>([])
  const [roles, setRoles] = useState<Role[]>([])
  const [apiIntegrations, setApiIntegrations] = useState<ApiIntegration[]>([])
  const { getProducts } = useAppContext()

  const getAuditEvents = async (): Promise<AuditEvent[] | void> => {
    try {
      return await fetchAuditEvents()
    } catch (e) {
      setError(e)
    }
  }

  const createProduct = async (product: Partial<Product>): Promise<Product | void> => {
    try {
      setError(undefined)
      setLoading(true)
      return await postProduct(product)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const deleteProduct = async (locationId: number): Promise<void> => {
    try {
      setLoading(true)
      await destroyProduct(locationId)
      await getProducts()
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getRoles = async (): Promise<void> => {
    try {
      setLoading(true)
      const roles = await fetchRoles()
      setRoles(roles)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const transferProduct = async (productId: number, newParentId: number): Promise<void> => {
    try {
      setLoading(true)
      await postTransferProduct(productId, newParentId)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getApiIntegrations = async (): Promise<void> => {
    try {
      setLoading(true)
      const { data } = await axios.get<ApiIntegration[]>(
        centralApiGatewayClient.apiIntegrations.GET_MANY,
      )
      setApiIntegrations(data)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getUserFunctions = async (): Promise<UserFunction[] | void> => {
    try {
      setLoading(true)
      const data = await fetchUserFunctions()
      setUserFunctions(data)
      return data
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const createUserFunction = async (body: Partial<UserFunction>): Promise<UserFunction | void> => {
    try {
      return await submitCreateUserFunction(body)
    } catch (e) {
      setError(e)
    }
  }

  const updateUserFunction = async (
    id: number,
    body: Partial<UserFunction>,
  ): Promise<UserFunction | void> => {
    try {
      return await submitUpdateUserFunction(id, body)
    } catch (e) {
      setError(e)
    }
  }

  const deleteUserFunction = async (id: number): Promise<void> => {
    try {
      await destroyUserFunction(id)
      await getUserFunctions()
    } catch (e) {
      setError(e)
    }
  }

  function reset(): void {
    setError(undefined)
  }

  const manualImport = async (
    integrationConfigurationId: number,
    startDate: string,
    endDate: string,
  ): Promise<void> => {
    await axios.post(
      centralApiGatewayClient.apiIntegrations.MANUAL_IMPORT.replace(
        ':id',
        integrationConfigurationId.toString(),
      ),
      {},
      { params: { startDate, endDate } },
    )
  }

  const checkConnection = async (integrationId: number, values: Store): Promise<boolean> => {
    try {
      setError(undefined)
      setLoading(true)
      const { data } = await axios.post<boolean>(
        centralApiGatewayClient.apiIntegrations.CHECK_CONNECTION.replace(
          ':id',
          integrationId.toString(),
        ),
        values,
      )
      return data
    } catch (e) {
      setError(e)
      return false
    } finally {
      setLoading(false)
    }
  }

  const configureApiIntegration = async (
    integrationId: number,
    active: boolean,
    interval: string,
    locationId: number,
    userId: number,
    configuration: Record<string, unknown>,
  ): Promise<void> => {
    try {
      setError(undefined)
      setLoading(true)
      await axios.post<User>(
        centralApiGatewayClient.apiIntegrations.CONFIGURE.replace(':id', integrationId.toString()),
        { configuration, active, interval, locationId, userId },
      )
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const exportUsers = async (): Promise<User[] | void> => {
    try {
      setError(undefined)
      return (await axios.post<User[]>(centralApiGatewayClient.users.EXPORT)).data
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  return {
    getAuditEvents,
    error,
    loading,
    createProduct,
    deleteProduct,
    roles,
    getRoles,
    transferProduct,
    userFunctions,
    getUserFunctions,
    createUserFunction,
    updateUserFunction,
    deleteUserFunction,
    reset,
    getApiIntegrations,
    apiIntegrations,
    configureApiIntegration,
    checkConnection,
    manualImport,
    exportUsers,
  }
}

export default useAdmin
