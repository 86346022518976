import React from 'react'
import { Navigate, RouteProps } from 'react-router-dom'

import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { config } from '@/utils/config'

const PrivateRoute = ({ children }: RouteProps): React.ReactElement => {
  const user = useAppSelector(selectUser)
  const userHasNoOrganization = useAppSelector((state) => !state.auth.organization)

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries()) as { token: string }

  // If the user is not present or the user is blocked, send the user to logout
  if ((!user && !params.token) || user?.blocked) {
    return <Navigate to={config.routes.logout} />
  }
  // If the user has no organzation, the registation is not over.
  if (userHasNoOrganization && user) {
    return <Navigate to={config.routes.register.organization} />
  }

  if (params.token && !user) {
    return <></>
  }

  return <>{children}</>
}

export default PrivateRoute
