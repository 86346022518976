import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Space } from 'antd'

import classNames from 'classnames'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import TRIAL_ENDED from '@/assets/trial-ended.svg'
import WAVE from '@/assets/wave.svg'

import Plans from '../Plans'

import classes from './classes.module.less'

type FreeSubscriptionExpiredProps = {
  onContactUsClicked: () => void
}

const FreeSubscriptionExpired = ({
  onContactUsClicked,
}: FreeSubscriptionExpiredProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <Card shadow="none" className={classNames(classes.statCard, classes.freeExpiredCard)}>
        <img src={WAVE} className={classes.freeExpiredBackground} />
        <img src={TRIAL_ENDED} className={classes.freeExpiredIllustration} />
        <Row className={classes.headerSection}>
          <Col span={24}>
            <Space direction={'vertical'} size={'large'}>
              <Title size="lg">{t('settings.subscription.free-expired.title')}</Title>
              <Text size="xl" className={classes.subtitle}>
                {t('settings.subscription.free-expired.subtitle-line-1')}
                <br />
                {t('settings.subscription.free-expired.subtitle-line-2')}
              </Text>
              <Button
                type="primary"
                size={'lg'}
                action="manage-subscription"
                category="plans"
                className={classes.cardButton}
                onClick={onContactUsClicked}
              >
                {t('settings.subscription.free-expired.button-call-to-action')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <Plans />
    </>
  )
}

export default FreeSubscriptionExpired
