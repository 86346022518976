import React, { ReactNode } from 'react'

import { Col, Row } from 'antd/es'

import { OverviewCardProps } from '@/molecules/OverviewCard'

import OverviewRow from '../OverviewRow'

function CarbonFootprintWrapper({
  children,
  overviewLoading,
  overviewData,
}: {
  children: ReactNode
  overviewLoading: boolean
  overviewData: OverviewCardProps[]
}): JSX.Element {
  return (
    <>
      {/* The overview is temporarily hidden as the underlying reports are showing incorrect data. */}
      {/* <Row>
        <Col span={24}>
          <OverviewRow
            loadingOverview={overviewLoading}
            marginBetween={14}
            overviewData={overviewData}
          />
        </Col>
      </Row> */}
      <Row>
        <Col span={24}>{children}</Col>
      </Row>
    </>
  )
}

export default CarbonFootprintWrapper
