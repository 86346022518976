import React, { ReactElement, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import { Report, ReportCategory } from '@cozero/models'

import classes from '@/pages/Share/Modals/classes.module.less'

const ChooseReportCategory = ({
  reportCategories,
  setReports,
}: {
  reportCategories: ReportCategory[]
  setReports: (newReports: Report[]) => void
}): ReactElement => {
  const [chosenReportCategory, setChosenReportCategory] = useState<ReportCategory>()
  const { t } = useTranslation('common')
  return (
    <>
      <div>
        <Row gutter={[8, 8]} wrap className={classes.chooseReportCategoryWrapper}>
          <Col
            span={24}
            className={`${!chosenReportCategory ? classes.selectedCategory : undefined} ${
              classes.reportCategory
            }`}
            onClick={() => {
              setChosenReportCategory(undefined)
              const newReports = reportCategories.reduce(
                (acc, category) => [...acc, ...category.reports],
                [] as Report[],
              )
              setReports(
                newReports.filter(
                  (value, index, self) =>
                    self.findIndex((value2) => value2.id === value.id) === index,
                ),
              )
            }}
          >
            <div className={classes.cardTitle}>{t('log.category-modal.viewAll')}</div>
          </Col>

          {reportCategories.map((reportCategory) => (
            <Col
              key={reportCategory.id}
              span={24}
              className={`${
                chosenReportCategory === reportCategory ? classes.selectedCategory : undefined
              } ${classes.reportCategory}`}
              onClick={() => {
                setChosenReportCategory(reportCategory)
                setReports(reportCategory.reports)
              }}
            >
              <div className={classes.cardTitle}>{reportCategory.name}</div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}

export default memo(ChooseReportCategory)
