import React, { ReactElement, ReactNode, useMemo } from 'react'
import { memo } from 'react'

import { Tag as AntdTag, TagProps } from 'antd/es'

import classes from './Tag.module.less'

export interface Props extends TagProps {
  children: ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg'
  type?: 'default' | 'danger' | 'info' | 'success' | 'warning'
  className?: string
  showDot?: boolean
  showSquare?: boolean
  truncateText?: boolean
  shape?: 'default' | 'pill'
}

/**
 * A basic tag
 * @param {Props} props
 * @returns
 */
const Tag = ({
  size = 'sm',
  type = 'default',
  showDot = false,
  showSquare = false,
  truncateText = false,
  shape = 'default',
  className,
  children,
  ...rest
}: Props): ReactElement => {
  const tagClasses = useMemo(() => {
    const classNames = [
      className,
      classes.tag,
      classes[size],
      classes[type],
      classes[shape],
      truncateText ? classes.truncate : null,
      rest.onClick ? classes.onClick : null,
    ]
    return classNames.join(' ')
  }, [size, type, shape, truncateText, className, rest.onClick])

  return (
    <AntdTag className={tagClasses} {...rest}>
      {showDot && <div className={classes.dot} />}{' '}
      {showSquare && <div className={classes.square} />}
      {children}
    </AntdTag>
  )
}

export default memo(Tag)
