import React, { ReactElement } from 'react'
import { HiOutlineBookOpen, HiOutlineDocumentReport, HiOutlineLightningBolt } from 'react-icons/hi'

import { PieChartOutlined } from '@ant-design/icons'
import { TFunction } from 'i18next'

import { config } from '@/utils/config'

type Base = {
  title: string
  feature: string
  icon?: ReactElement
  cy?: string
  newFeature?: boolean
}

export type MenuItem =
  | (Base & {
      children?: Record<string, MenuItem>
      key: string
      route?: never
      excludeRoles?: string[]
    })
  | (Base & { children?: never; key?: never; route?: string; excludeRoles?: string[] })

/**
 * This array determines the menu points for the navSidebar.
 * Submenus take a children object containing more MenuItems
 */

export const getMenuItems = (t: TFunction): Record<string, MenuItem> => ({
  overview: {
    title: t('layout.overview'),
    icon: <PieChartOutlined />,
    feature: 'log',
    key: 'overview',
    children: {
      home: {
        title: t('layout.dashboard.title'),
        route: config.routes.overview.dashboard,
        feature: 'log',
      },
      reports: {
        title: t('layout.reports'),
        route: config.routes.overview.reports,
        feature: 'reports',
      },
    },
  },
  log: {
    key: 'log',
    title: 'Log',
    icon: <HiOutlineBookOpen />,
    feature: 'log',
    children: {
      organization: {
        title: t('layout.organization'),
        route: config.routes.organization.locations.base,
        cy: 'organization',
        feature: 'log',
      },
      carbonFootprint: {
        title: t('layout.carbon-footprint'),
        route: config.routes.log.carbonFootprint.organization.base,
        cy: 'carbon-footprint',
        feature: 'log',
      },
      factors: {
        title: t('layout.factors'),
        route: config.routes.log.factors,
        feature: 'custom-factors',
      },
      customers: {
        title: t('layout.customers'),
        route: config.routes.log.customers,
        feature: 'log',
        excludeRoles: ['viewer'],
      },
      suppliers: {
        title: t('layout.suppliers'),
        route: config.routes.log.suppliers,
        feature: 'log',
        excludeRoles: ['viewer'],
      },
      tags: {
        title: t('location.tags'),
        route: config.routes.log.tags,
        feature: 'log',
        excludeRoles: ['viewer'],
      },
      'closed-periods': {
        title: t('layout.closed-periods'),
        route: config.routes.log['closed-periods'],
        feature: 'closed-period',
      },
    },
  },
  act: {
    key: 'act',
    title: 'Act',
    icon: <HiOutlineLightningBolt />,
    feature: 'act',
    children: {
      customActions: {
        title: t('act.climateActions.title'),
        route: config.routes.act.climateActions,
        feature: 'act',
        newFeature: true,
      },
      targets: {
        title: t('act.targets.nav-title'),
        route: config.routes.act.targets,
        feature: 'act',
      },
      marketplace: {
        title: t('act.marketplace.title'),
        route: config.routes.act.marketplace,
        feature: 'act',
      },
    },
  },
  share: {
    key: 'share',
    title: 'Share',
    icon: <HiOutlineDocumentReport />,
    feature: 'share',
    children: {
      sharePages: {
        title: t('layout.share-pages'),
        route: config.routes.share.sharePages,
        feature: 'share',
      },
      stories: {
        title: t('layout.stories'),
        route: config.routes.share.stories,
        feature: 'share',
      },
      certifications: {
        title: t('layout.certificates'),
        route: config.routes.share.certifications,
        feature: 'share',
      },
      sustainabilityReports: {
        title: t('layout.sustainability-reports'),
        route: config.routes.share.sustainabilityReports,
        feature: 'share',
      },
    },
  },
})
