import { User } from '@cozero/models'
import { authApiGatewayClient, centralApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'
import { TAG_USER } from '../auth'

import {
  CHANGE_USER_PASSWORD,
  REQUEST_RESET_PASSWORD,
  SUBMIT_RESET_PASSWORD,
  UPDATE_CURRENT_USER,
} from './constants'

const userProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [UPDATE_CURRENT_USER]: builder.mutation<
      User,
      {
        user: Partial<User>
        image?: string
      }
    >({
      query: ({ user, image }) => ({
        url: centralApiGatewayClient.users.UPDATE_ME,
        method: 'PUT',
        data: { ...user, image },
      }),
      invalidatesTags: (res) => [{ type: TAG_USER, id: res?.id }],
    }),
    [CHANGE_USER_PASSWORD]: builder.mutation<
      boolean,
      { currentPassword: string; newPassword: string }
    >({
      query: (data) => ({
        url: centralApiGatewayClient.users.CHANGE_PASSWORD,
        method: 'POST',
        data,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    [REQUEST_RESET_PASSWORD]: builder.mutation<void, string>({
      query: (email) => ({
        url: authApiGatewayClient.auth.REQUEST_PASSWORD,
        method: 'POST',
        data: { email },
      }),
    }),
    [SUBMIT_RESET_PASSWORD]: builder.mutation<boolean, { token: string; password: string }>({
      query: (data) => ({
        url: authApiGatewayClient.auth.PASSWORD_RESET,
        method: 'POST',
        data,
      }),
      extraOptions: { maxRetries: 0 },
      transformErrorResponse: (res) => {
        return {
          ...res,
          message: res?.data?.message?.[0],
        }
      },
    }),
  }),
})

export const {
  useUpdateCurrentUserMutation,
  useChangePasswordMutation,
  useRequestResetPasswordMutation,
  useSubmitResetPasswordMutation,
} = userProfileApiSlice
export default userProfileApiSlice
