import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Spin, Typography, message } from 'antd/es'

import { ApiIntegration } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Alert from '@/atoms/Alert'
import DatePicker from '@/atoms/DatePicker'

import { useAdminContext } from '@/contexts/admin'
import { useAppSelector } from '@/redux'
import { selectUserOrganization } from '@/redux/auth'

import classes from './classes.module.less'

interface Props {
  handleCancel: () => void
  visible?: boolean
  selectedIntegration?: ApiIntegration
}

export function ManualImportModal({
  handleCancel,
  selectedIntegration,
  visible = false,
}: Props): JSX.Element {
  const { t } = useTranslation('common')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const organization = useAppSelector(selectUserOrganization)
  const { manualImport } = useAdminContext()
  const [form] = Form.useForm()

  async function onSave(): Promise<void> {
    setError('')
    setLoading(true)
    const values = form.getFieldsValue()
    try {
      if (!values.startDate || !values.endDate || !selectedIntegration?.configurations?.[0]) {
        setError(t('organization.missing-fields'))
        return
      }
      await manualImport(
        selectedIntegration.configurations[0].id,
        values.startDate?.toISOString(),
        values.endDate?.toISOString(),
      )
      message.success(t('settings.integrations.manual-import-success'))
      handleCancel()
    } catch (e) {
      message.error(t('settings.integrations.manual-import-failed'))
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      centered
      visible={visible}
      title={t('settings.integrations.actions.import')}
      onCancel={handleCancel}
      className={classes.modal}
      width={400}
      okButtonProps={{ disabled: loading }}
      hideFooter={false}
      okText={t('settings.integrations.actions.start-import')}
      onOk={onSave}
    >
      <Spin spinning={loading} className={classes.modalContainer}>
        <Typography.Paragraph style={{ color: 'grey' }}>
          {t('settings.integrations.manual-import-info')}
        </Typography.Paragraph>
        {error && <Alert message={error} type="error" />}
        <Form layout="vertical" initialValues={organization} autoComplete="off" form={form}>
          <Form.Item
            label={t('settings.integrations.start-date')}
            name="startDate"
            rules={[{ required: true }]}
          >
            <DatePicker className={classes.datePickerInput} format="YYYY-MM-DD" picker="date" />
          </Form.Item>
          <Form.Item
            label={t('settings.integrations.end-date')}
            name="endDate"
            rules={[{ required: true }]}
          >
            <DatePicker className={classes.datePickerInput} format="YYYY-MM-DD" picker="date" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}
